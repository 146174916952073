import CircularProgress from '@mui/material/CircularProgress';
import { unwrapResult } from '@reduxjs/toolkit';
import { StatusCheck, Sync } from 'apollo-react-icons';
import FileUpload from 'apollo-react/components/FileUpload';
import Grid from 'apollo-react/components/Grid';
import Tooltip from 'apollo-react/components/Tooltip';
import CustomModal from 'Components/Modal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import {
  DeleteReferenceData,
  GetQCodeReferenceData
} from 'Redux/Service/ReferenceDataCardsService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { setReferenceData } from 'service/reference-data.service';
import { MessageConstants } from 'Utils/MessageConstants';
import useGlobalMessage from 'Utils/useGlobalMessage';
import Card from './Card';
import './Trials.css';
import { DATASET_NAME_MT } from 'Utils/Constants';


const MEDDRA_THESAURUS = 'meddra-thesaurus';
const MedDraThesaurus = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [medDraPreview, setMedDraPreview] = useState(true);
  const [medDraQueryData, setMedDraQueryData] = useState([]);
  const [medDraErrorMessage, setMedDraErrorMessage] = useState('');

  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    buttonPrimaryLabel: '',
    buttonSecondaryLabel: '',
    onConfirm: () => null,
    onCancel: () => null
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: mappingRuleVersionID } = useParams();
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const { getMessage } = useGlobalMessage();

   const importMedDraData = async () => {
     setIsLoading(true);
     let payload = {
       isImport: true,
       protocolName: protocol?.protocolNumber,
       datasetName: DATASET_NAME_MT,
       mappingRuleVersionID: mappingRuleVersionID
     };
     const importedMedDraData = await dispatch(GetQCodeReferenceData(payload)).then(unwrapResult);
     if (importedMedDraData?.data?.success) {
       let importedData = JSON.parse(
         importedMedDraData.data.qcodeQueryResultData?.queryJsonResult || '[]'
       );
       if (importedData?.length > 0) {
         setMedDraQueryData(importedData);
         setReferenceData(MEDDRA_THESAURUS, importedMedDraData.data.qcodeQueryResultData);
         navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/meddra-thesaurus`);
         dispatch(showBanner({ variant: 'success', message: importedMedDraData.data.message }));
       } else {
         dispatch(
           showBanner({
             variant: 'warning',
             message: getMessage(MessageConstants.REFERENCE_DATA_TIMEPOINTS_IMPORT_ERROR_NO_DATA)
           })
         );
       }
     } else {
       dispatch(showBanner({ variant: 'error', message: importedMedDraData?.data?.message }));
     }
     setIsLoading(false);
   };

   const handleImportData = (isRefresh) => {
     if (isRefresh) {
       setConfirmAlert({
         enabled: true,
         title: getMessage(MessageConstants.REFERENCE_DATA_MEDDRA_IMPORT_WARNING_TITLE),
         message:getMessage(MessageConstants.REFERENCE_DATA_MEDDRA_IMPORT_WARNING_DESC),
         variant: 'warning',
         buttonPrimaryLabel: getMessage(MessageConstants.WARNING_LABEL_OK),
         buttonSecondaryLabel: getMessage(MessageConstants.WARNING_LABLE_CANCEL),
         onConfirm: () => {
           importMedDraData();
           setConfirmAlert({
             enabled: false
           });
         },
         onCancel: () => {
           setConfirmAlert({
             enabled: false
           });
         }
       });
     } else {
       importMedDraData();
     }
   };

  const deleteMedraData = async () => {
    let payload = {
      domainCode: DATASET_NAME_MT,
      mappingRuleVersionID: mappingRuleVersionID
    };
    const deleteResp = await dispatch(DeleteReferenceData(payload)).then(unwrapResult);
    if (deleteResp.data.success) {
      dispatch(showBanner({ variant: 'success', message: deleteResp.data.message }));
      setMedDraPreview(true);
      setMedDraQueryData([]);
      props.configuration.setCardVisibility(MEDDRA_THESAURUS, false);
    } else {
      dispatch(showBanner({ variant: 'error', message: deleteResp.data.message }));
    }
  };

  const confirmOnDelete = () => {
    medDraQueryData.length > 0
      ? setConfirmAlert({
          enabled: true,
          title: getMessage(MessageConstants.REFERENCE_DATA_DELETE_CARD_WARNING_TITLE),
          message: getMessage(MessageConstants.REFERENCE_DATA_DELETE_CARD_WARNING_DESC),
          variant: 'error',
          buttonPrimaryLabel: getMessage(MessageConstants.WARNING_LABEL_OK),
          buttonSecondaryLabel: getMessage(MessageConstants.WARNING_LABLE_CANCEL),
          onConfirm: () => {
            deleteMedraData();

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        })
      : deleteMedraData();
  };

  const handleViewData = () => {
    if (medDraQueryData.length > 0) {
      navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/meddra-thesaurus`);
    }
  };
  useEffect(() => {
    (async () => {
      let payload = {
        isImport: false,
        protocolName: protocol?.protocolNumber,
        datasetName: DATASET_NAME_MT,
        mappingRuleVersionID: mappingRuleVersionID
      };
      const medDraGetAPIData = await dispatch(GetQCodeReferenceData(payload)).then(unwrapResult);
      props.configuration.setIsCompleted(MEDDRA_THESAURUS, true);
      props.configuration.setModuleValidation(MEDDRA_THESAURUS, true);
      if (medDraGetAPIData?.data?.success) {
        let importedData = JSON.parse(
          medDraGetAPIData.data.qcodeQueryResultData?.queryJsonResult || '[]'
        );
        if (importedData?.length > 0) {
          setMedDraQueryData(importedData);
          setMedDraPreview(false);
          props.configuration.setModuleValidation(MEDDRA_THESAURUS, true);
          props.configuration.setCardVisibility(MEDDRA_THESAURUS, true);
        } else {
          props.required && props.configuration.setModuleValidation(MEDDRA_THESAURUS, false);
          setMedDraQueryData([]);
        }
      } else {
        setMedDraErrorMessage(medDraGetAPIData.data.message);
        setMedDraQueryData([]);
      }
    })();
  }, []);

  return (
    <Grid item xs={4} style={props.visible ? { display: 'block' } : { display: 'none' }}>
      <h4 style={{ marginTop: '16px', padding: '0px' }}>
        {props.title}
        <span style={{ color: 'red' }}>{props.required ? '*' : ''}</span>
      </h4>
      <Card
        deleteCardInParent={confirmOnDelete}
        required={props.required}
        cardTitle={props.title}
        refMsg={medDraErrorMessage}
        setErrorFlag={false}
        isEditDisable={medDraPreview}
        previewDataLabel={getMessage(MessageConstants.REFERENCE_DATA_VIEW_DATA_TABLE_LABEL)}
        handleEditPreview={() => handleViewData()}
        isDownloadRequired={false}
        handleDownload={() => {}}
        isImportRequired={true}
        isImportRunning={isLoading}
        handleImportData={handleImportData}>
        <div style={{ margin: '0px 10px' }}>
          {isLoading && (
            <CircularProgress
              size={40}
              style={{
                zIndex: '3000',
                position: 'absolute',
                marginLeft: 'calc(50% - 45px)',
                marginTop: '15%'
              }}
            />
          )}
          <div className="fileUpload">
            <FileUpload
              disabled={true}
              value={[]}
              onUpload={() => {}}
              label={getMessage(MessageConstants.REFERENCE_DATA_FILE_UPLOAD_LABEL)}
              maxItems={5}
              dropAreaHeight={100}
              fullWidth
            />
            {medDraQueryData.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                <p
                  style={{
                    height: '24px',
                    color: 'black',
                    fontFamily: 'Proxima Nova',
                    fontSize: '14px',
                    fontWeight: 400,
                    letterSpacing: 0,
                    lineHeight: '24px'
                  }}>
                  {getMessage(MessageConstants.REFERENCE_DATA_MEDDRA_IMPORT_LABEL)}
                </p>
                <div
                  style={{
                    marginTop: '0.7rem',
                    marginRight: '0'
                  }}>
                  <Tooltip
                    variant="dark"
                    title={getMessage(MessageConstants.REFERENCE_DATA_MEDDRA_IMPORT_SYNC_TOOLTIP)}
                    placement="top">
                    <Sync
                      style={{
                        color: '#015FF1',
                        cursor: isLoading ? 'default' : 'pointer',
                        opacity: isLoading ? 0.3 : 1
                      }}
                      onClick={!isLoading ? () => handleImportData(true) : null}
                      data-testid="refresh-btn"
                    />
                  </Tooltip>
                  <StatusCheck
                    style={{
                      color: '#00C221',
                      width: '20px',
                      height: '20px',
                      marginLeft: '1rem'
                    }}
                    fontSize="small"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={confirmAlert.buttonPrimaryLabel}
        buttonSecondardyLabel={confirmAlert.buttonSecondaryLabel}
        handlePrimaryAction={() => confirmAlert?.onConfirm()}
        handleClose={() => confirmAlert?.onCancel()}
      />
    </Grid>
  );
};

export default MedDraThesaurus;