/*eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { showLoader, closeLoader } from '../Slice/LoaderSlice';
import axios from 'axios';
const API_URL = process.env.REACT_APP_API_URL;

export const getDataProductWithMappingSpec = createAsyncThunk(
  'Publish/getDataProductWithMappingSpec',
  async (payload, { dispatch, rejectWithValue }) => {
    // dispatch(showLoader());
    try {
      const response = await axios.get(
        `${API_URL}/api/Publish/GetDataProductsWithMappingSpecVersions?ProtocolNumber=${payload.protocolNumber}&MappingSpecFilterKey=${payload.mappingSpecFilterKey}`
      );
      // dispatch(closeLoader());
      return response;
    } catch (error) {
      // dispatch(closeLoader());
      rejectWithValue([], error);
    }
  }
);

export const saveLoaderQueue = createAsyncThunk(
  'Publish/saveLoaderQueue',
  async (payload, { dispatch, rejectWithValue }) => {
    dispatch(showLoader());
    try {
      const response = await axios.post(`${API_URL}/api/Publish/saveLoaderQueue`, payload);
      dispatch(closeLoader());
      return response;
    } catch (error) {
      dispatch(closeLoader());
      rejectWithValue(error);
    }
  }
);
