/* eslint-disable */
import _ from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import XLSX from 'xlsx';
import useDisplayName from './useDisplayName';

export function getBufferString(fileData) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(fileData);
    reader.onload = function () {
      const fileBytes = reader.result;
      resolve(fileBytes);
    };
  });
}

/**
 * Function to read byte array and return text file
 * @param {File} file
 * @returns {Promise}
 */
export function readAsText(byteString) {
  let textString = window.atob(byteString);
  const file = new File([textString], 'refreshHistory.log', {
    type: 'text/plain;charset=UTF-8'
  });

  return {
    logText: textString,
    size: file.size > 1024 ? `${(file.size / 1024).toFixed(1)} KB` : `${file.size} Bytes`,
    file
  };
}

export const fileByteToFileObject = (fileBytes, fileName) => {
  return new Promise((resolve, reject) => {
    fetch(fileBytes)
      .then((response) => response.blob())
      .then((blob) => {
        const file = new File([blob], fileName, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });
        resolve(file);
      });
  });
};

export const useDebounce = (fnToDebounce, durationInMs = 200) => {
  if (isNaN(durationInMs)) {
    throw new TypeError('durationInMs for debounce should be a number');
  }

  if (fnToDebounce == null) {
    throw new TypeError('fnToDebounce cannot be null');
  }

  if (typeof fnToDebounce !== 'function') {
    throw new TypeError('fnToDebounce should be a function');
  }

  return useCallback(debounce(fnToDebounce, durationInMs), [fnToDebounce, durationInMs]);
};

export const debounce = (func, delay) => {
  let inDebounce;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(() => func.apply(context, args), delay);
  };
};

export const throttle = (func, limit) => {
  let inThrottle;
  return function () {
    const args = arguments;
    const context = this;
    if (!inThrottle) {
      func.apply(context, args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), limit);
    }
  };
};

export const convertExcelToJson = (fileName, options) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    let copyOfData = [];
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true
      });
      const sheetData = wb.Workbook.Sheets.find((el) => el.Hidden !== 1);
      const ws = wb.Sheets[sheetData.name];
      const data = XLSX.utils.sheet_to_json(ws, options);
      copyOfData = JSON.parse(JSON.stringify(data));
      resolve(copyOfData);
    };
    if (rABS) {
      reader.readAsBinaryString(fileName);
    } else {
      reader.readAsArrayBuffer(fileName);
    }
  });
};

export const extractExcelColsHavingFormula = (file) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    let cols = [];
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true,
        cellFormula: true
      });
      const sheetData = wb.Workbook.Sheets.find((el) => el.Hidden !== 1);
      const ws = wb.Sheets[sheetData.name];
      const _sheet = JSON.parse(JSON.stringify(ws));
      delete _sheet['!ref'];
      delete _sheet['!margins'];
      cols = [
        ...new Set(
          Object.entries(_sheet)
            .map((cell) => {
              if (cell[1]?.f?.[0]) {
                return _sheet[`${cell[0]?.[0]}1`].v;
              }
            })
            .filter(Boolean)
        )
      ];
      resolve(cols);
    };
    if (rABS) {
      reader.readAsBinaryString(file);
    } else {
      reader.readAsArrayBuffer(file);
    }
  });
};

export const getHeaders = (fileName) => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    const rABS = !!reader.readAsBinaryString;
    let copyOfData = [];
    reader.onload = (e) => {
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        bookVBA: true
      });
      const sheetData = wb.Workbook.Sheets.find((el) => el.Hidden !== 1);
      const ws = wb.Sheets[sheetData.name];
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];
      copyOfData = JSON.parse(JSON.stringify(data));
      resolve(copyOfData);
    };
    if (rABS) {
      reader.readAsBinaryString(fileName);
    } else {
      reader.readAsArrayBuffer(fileName);
    }
  });
};

export const isNull = (type, value) => {
  if (type === 'NO' && !value) {
    return false;
  }
  return true;
};

export const jsonToFileData = (jsonData, filename) => {
  const json = JSON.stringify(jsonData);
  const blob = new Blob([json], { type: 'text/json' });
  const file = new File([blob], filename, {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  });
  return file;
};

export const fieldLength = (length, value) => {
  return length && value ? value.length <= length : true;
};

export const checkDataType = (dataType, value) => {
  if (!value) {
    return true;
  } else if (dataType === 'character varying' && typeof value === 'string') {
    return true;
  } else if (dataType === 'double precision' && typeof value === 'number') {
    return true;
  } else if (dataType === 'integer' && typeof value === 'string') {
    return true;
  }
  return false;
};

export const jsonToExcel = (jsonObj, filename, sheetName) => {
  const ws = XLSX.utils.json_to_sheet(jsonObj);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, sheetName === undefined ? 'SDTM' : sheetName);
  XLSX.writeFile(wb, filename);
};

const mapped_obj = {
  Visits: 'edcOdmVisits',
  CRFMetadata: 'edcOdmForms',
  CodeList: 'codeLists',
  Measurement: 'measurements',
  cdr: 'cdrDataSetColumns',
  q2: 'qlabVisits',
  qecg: 'ecgVisits'
};

export const jsonToExcelStudyLibrary = (jsonResponse, filename, sheetNames) => {
  let wb = XLSX.utils.book_new();
  if (!wb.Props) wb.Props = {};
  wb.Props.Title = 'Insert Title Here';
  jsonResponse.forEach((item, i) => {
    let ws = XLSX.utils.json_to_sheet(item[mapped_obj[sheetNames[i]]]);
    XLSX.utils.book_append_sheet(wb, ws, sheetNames[i]);
  });
  XLSX.writeFile(wb, filename);
};

export const uuidv4 = () => {
  if ('randomUUID' in (window.crypto || {})) return window.crypto.randomUUID();

  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (c ^ (window?.crypto?.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4))))?.toString(16)
  );
};

/*
***
createTwoWayObject gets a JSON input with key value pairs and returns an object where it makes all the values as key and keys as values and put it in prototype

Therefore, for instance, if the input object is,
{
  "Kesava Krishnan Madavan": {
    "key": "name",
    "type": "STRING",
    "canBeEmpty": false
  },
  "Date of Birth": {
    "key": "dateOfBirth",
    "type": "DATE",
    "canBeEmpty": false
  }
}

In the returned value we could access both ways like below,
1. output.name // => Kesava Krishnan Madavan
2. output.dateOfBirth // => Date of Birth
3. output["Kesava Krishnan Madavan"] // => name
4. output["Date of Birth"] // => dateOfBirth

In addition, any property provided with key can be accessed as below,
1. output.name_props.type  // => STRING
2. output.name_props.canBeEmpty  // => false
3. output.dateOfBirth_props.type  // => DATE
4. output.dateOfBirth_props.canBeEmpty  // => false
These additional props can be used to sort, validate or filter wherever required 

If there is no prop for any particular key, the input object can be defined simply as follows,
{
  "Kesava Krishnan Madavan": "name",
  "Gender": {
    key: "gender",
    type: "STRING"
  }
}
If the value is object, props will be created and if it's string, props won't be created.

If keys of original object alone needs to be iterated, we could use hasOwnProperty as below,
for(let key in output){
  if(output.hasOwnProperty(key)){
    console.log(key)
  }
}
*/
export const createTwoWayObject = (input) => {
  let manipulatedOutput = {
    ..._.transform(input, function (displayNames, value, key) {
      if (typeof value === 'string') {
        displayNames[key] = value;
      } else {
        displayNames[key] = value.key;
      }
    })
  };
  manipulatedOutput.__proto__ = {};
  for (let displayName in input) {
    let valueObject = input[displayName];
    if (typeof valueObject === 'string') {
      manipulatedOutput.__proto__[valueObject] = displayName;
    } else {
      let apiKey = valueObject.key;
      delete valueObject.key;
      manipulatedOutput.__proto__[apiKey] = displayName;
      manipulatedOutput.__proto__[apiKey + '_props'] = {
        ...valueObject
      };
    }
  }
  return manipulatedOutput;
};

export const jsonToExcelPublishDownload = (jsonResponse, filename, sheetNames) => {
  let wb = XLSX.utils.book_new();
  jsonResponse.forEach((item, i) => {
    if (item) {
      let ws;
      if (i === 0) {
        ws = XLSX.utils.json_to_sheet(item, { skipHeader: 1 });
      } else {
        ws = XLSX.utils.json_to_sheet(item);
      }
      XLSX.utils.book_append_sheet(wb, ws, sheetNames[i]);
    }
  });
  XLSX.writeFile(wb, filename);
};

const randomNumGenerator = () => {
  var array = new Uint8Array(1);
  var randomValue = window.crypto.getRandomValues(array);
  return randomValue.length > 0 && randomValue[0];
};

export const useStateWithCB = (...args) => {
  const [value, setState] = useState(...args);
  const [cbtrigger, setCbTrigger] = useState(randomNumGenerator());
  const callback = useRef();

  useEffect(() => {
    callback.current && callback.current(value);
    callback.current = undefined;
  }, [cbtrigger]);

  return [
    value,
    (arg, cb) => {
      callback.current = cb;
      setState(arg);
      setCbTrigger(randomNumGenerator());
    }
  ];
};

export const compareDates = (accessor, sortOrder) => {
  return function (rowA, rowB) {
    let result;
    let valueA = rowA[accessor];
    let valueB = rowB[accessor];

    if (!valueA && valueB) {
      result = -1;
    } else if (!valueB && valueA) {
      result = 1;
    } else if (!valueA && !valueB) {
      result = 0;
    }

    if (result === undefined) {
      let dateA = moment(valueA);
      let dateB = moment(valueB);

      if (!dateA.isValid() && dateB.isValid()) {
        result = -1;
      } else if (!dateB.isValid() && dateA.isValid()) {
        result = 1;
      } else if (!dateA.isValid() && !dateB.isValid()) {
        result = 0;
      } else if (dateA.isBefore(dateB)) {
        result = -1;
      } else if (dateB.isBefore(dateA)) {
        result = 1;
      } else {
        result = 0;
      }
    }
    return sortOrder === 'asc' || result === 0 ? result : -result;
  };
};

export const dateFormatByType = (date, type) => {
  if (date && moment(date).isValid()) {
    return moment
      .utc(date)
      .format(type === 'Table' ? 'DD-MMM-YYYY hh:mm A' : 'DD MMMM YYYY hh:mm A');
  } else {
    return '-';
  }
};

export const dateFilter = (accessor) => {
  return (row, filters) => {
    const date = moment(row[accessor]).format('DD-MMM-YYYY');
    const fromDate = moment(filters[accessor]).format('DD-MMM-YYYY');
    if (fromDate === 'Invalid date') {
      return true;
    } else {
      return date === fromDate;
    }
  };
};

export const getHtmlString = (str, formatedFormItemData = [], defaultForm, type = 1) => {
  let modifiedString = str;
  let fetchedFormItems = [];
  if (!str) {
    return modifiedString;
  }
  if (type === 1) {
    for (let formItem of formatedFormItemData) {
      if (str.includes(formItem.formatedString)) {
        fetchedFormItems.push(formItem);
        modifiedString = modifiedString.replaceAll(
          formItem.formatedString,
          `<span style="color: blue;">[${formItem.formName}]</span>.<span style="color: green;">[${formItem.itemName}]</span>`
        );
      }
      if (formItem.formName === defaultForm) {
        let escapedStr = modifiedString.replaceAll(/\s/g, '__space__');
        const pattern1 = `__space__[${formItem.itemName}]`;
        const pattern2 = `+[${formItem.itemName}]`;
        const pattern3 = `[${formItem.itemName}]`;
        if (escapedStr.includes(pattern1)) {
          modifiedString = escapedStr.replaceAll(
            pattern1,
            `__space__<span style="color: green;">[${formItem.itemName}]</span>`
          );
        }
        if (escapedStr.includes(pattern2)) {
          modifiedString = escapedStr.replaceAll(
            pattern2,
            `+<span style="color: green;">[${formItem.itemName}]</span>`
          );
        }
        if (escapedStr.startsWith(pattern3)) {
          modifiedString = escapedStr.replace(
            pattern3,
            `<span style="color: green;">[${formItem.itemName}]</span>`
          );
        }
        modifiedString = modifiedString.replaceAll('__space__', ' ');
      }
    }
  } else if (type === 2) {
    const matchedData = modifiedString.match(/\{form\.oid\}.\[[^\]]+\]/gim);
    if (matchedData && matchedData.length > 0) {
      for (let i = 0; i < matchedData.length; i++) {
        const exp = matchedData[i];
        const index = exp.indexOf('}.');
        const form = exp.substring(0, index + 1);
        const item = exp.substring(index + 1, exp.length);
        const updatedStr = `<span style="color: blue;">${form}</span><span style="color: green;">${item}</span>`;
        modifiedString = modifiedString.replace(exp, updatedStr);
      }
    }
  } else if (type === 3) {
    const matchedData = modifiedString.match(/\[[^\]]+\].\[[^\]]+\]/gim);
    if (matchedData && matchedData.length > 0) {
      for (let i = 0; i < matchedData.length; i++) {
        const exp = matchedData[i];
        const index = exp.indexOf('].');
        const form = exp.substring(0, index + 1);
        const item = exp.substring(index + 1, exp.length);
        const updatedStr = `<span style="color: #015FF1;">${form}</span><span style="color: #9E54B0;">${item}</span>`;
        modifiedString = modifiedString.replace(exp, updatedStr);
      }
    }
  }
  return modifiedString;
};

export const getFormsAndItems = (str, formatedFormItemData = [], defaultForm) => {
  let fetchedFormItems = [];
  for (let formItem of formatedFormItemData) {
    if (str.includes(formItem.formatedString)) {
      fetchedFormItems.push(formItem);
    } else if (formItem.formName === defaultForm) {
      let escapedStr = str.replaceAll(/\s/g, '__space__');
      const pattern1 = `__space__[${formItem.itemName}]`;
      const pattern2 = `+[${formItem.itemName}]`;
      const pattern3 = `[${formItem.itemName}]`;
      if (
        escapedStr.includes(pattern1) ||
        escapedStr.includes(pattern2) ||
        escapedStr.startsWith(pattern3)
      ) {
        fetchedFormItems.push(formItem);
      }
    }
  }

  return fetchedFormItems;
};

export const getAllFormsAndItems = (str) => {
  const formItems = str.match(/\[[^\]]+\].\[[^\]]+\]/gim);
  const fetchedFormItems = [...new Set(formItems)].map((formItem) => {
    const [form, item] = formItem.substring(1, formItem.length - 1).split('].[');
    return {
      formName: form,
      itemName: item,
      formatedString: formItem,
      libraryType: 'CDR Tabular'
    };
  });
  return fetchedFormItems;
};

export const generateErrorString = (str, start, end) => {
  start = !start || start === -1 ? 0 : start;
  end = !end || end === -1 ? str.length : end;
  let beforeErrorString = str.substring(0, start);
  let errorString = str.substring(start, end);
  let afterErrorString = str.substring(end);
  beforeErrorString = beforeErrorString.replaceAll('<', '&lt');
  errorString = errorString.replaceAll('<', '&lt');
  afterErrorString = afterErrorString.replaceAll('<', '&lt');
  return `${beforeErrorString}<span style='color:red'>${errorString}</span>${afterErrorString}`;
};

/**
 * Function to get source name based on Library Type
 * @param {string} library Library type
 * @returns {string} Source name
 */
export const getSourceName = (source) => {
  let sourceName = '';
  if (source.startsWith('CDR Tabular')) {
    sourceName = 'TABULAR';
  } else if (source.startsWith('Q2LAB')) {
    sourceName = 'QLIMS';
  } else if (source.startsWith('CDISC ODM')) {
    sourceName = 'ODM';
  } else if (source.startsWith('QECG')) {
    sourceName = 'QECG';
  } else {
    sourceName = source;
  }
  return sourceName;
};

export const SOURCE_NAME_MAPPING = {
  TABULAR: 'CDR Tabular',
  QLIMS: 'Q2LAB',
  ODM: 'CDISC ODM',
  QECG: 'QECG'
};

export const getHighlightedText = (highlightText, textToFormat) => {
  return textToFormat;
  // if (highlightText) {
  //   let regex = new RegExp(`(${highlightText.replace(/([.?*+^$[\]\\(){}|-])/g, '\\$1')})`, 'gi');
  //   let parts = textToFormat?.split(regex);
  //   return parts.map((part) => {
  //     if (regex.test(part)) return <mark>{part}</mark>;
  //     else return part;
  //   });
  // } else return textToFormat;
};

export const useHighlightedText = (highlightText, textToFormat) => {
  const formatedText = useMemo(
    () => getHighlightedText(highlightText, textToFormat),
    [textToFormat, highlightText]
  );

  return formatedText;
};

export const useLeafPathName = () => {
  const location = useLocation();
  return (
    useMemo(() => {
      let leafPathName = '';

      if (location.pathname.lastIndexOf('/') === location.pathname.length - 1) {
        leafPathName = location.pathname.substring(0, location.pathname.lastIndexOf('/'));
        leafPathName = leafPathName.substring(leafPathName.lastIndexOf('/') + 1);
      } else {
        leafPathName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
      }
      return leafPathName;
    }, [location?.pathname]) || ''
  );
};

export default {
  getBufferString,
  fileByteToFileObject,
  useDebounce,
  debounce,
  throttle,
  convertExcelToJson,
  isNull,
  jsonToExcel,
  jsonToExcelStudyLibrary,
  uuidv4,
  createTwoWayObject,
  getHeaders,
  jsonToExcelPublishDownload,
  useStateWithCB,
  readAsText,
  generateErrorString,
  getSourceName,
  useLeafPathName,
  getHighlightedText,
  useHighlightedText,
  useDisplayName
};
