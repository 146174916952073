import { useMemo } from 'react';
import { useSelector } from 'react-redux';

/**
 * Custom hook:
 * If Key exists in 'globalMessages', it returns the mapped message; otherwise, it returns the Key.
 * 
 * @returns {{getMessage: (key) => message, [key]: message}}} - Returns a Proxy object to get the global message using key directly.
 - Usage: `const { getMessage } = useGlobalMessage(); const message = getMessage('Key')`;
 - OR
 - `const { Key } = useGlobalMessage();
 */
export const useGlobalMessage = () => {
  const { globalMessages = {} } = useSelector((state) => state.AppData);
  return useMemo(
    () =>
      new Proxy(globalMessages, {
        get(target, name, receiver) {
          if (name == 'getMessage') {
            return (originalValue) => globalMessages[originalValue] || originalValue;
          }
          return Reflect.get(target, name, receiver) || name;
        }
      }),
    [globalMessages]
  );
};

export default useGlobalMessage;
