import React, { useEffect, useState } from 'react';
import DOMPurify from 'dompurify';
import Blade from 'apollo-react/components/Blade';
import { GetAppSwitcherHTML } from 'Redux/Service/AppService';
import { App } from 'apollo-react-icons';
import './AppSwitcher.css';
import { useDispatch } from 'react-redux';
import { showBanner } from 'Redux/Slice/BannerSlice';

const AppSwitcher = () => {
  const [openPanel, setOpenPanel] = useState(false);
  const [htmlContent, setHtmlContent] = useState('');
  const dispatch = useDispatch();

  const onOpen = () => {
    setOpenPanel(true);
  };

  const closePanel = () => {
    setOpenPanel(false);
  };

  useEffect(() => {
    closePanel();
  }, []);

  /**Used to set the sanitized html and prevent xss attack clicks */
  const fetchAppSwitcherHtml = async () => {
    const appSwitcherHtmlResp = await GetAppSwitcherHTML();
    if (appSwitcherHtmlResp?.error) {
      dispatch(showBanner({ variant: 'error', message: appSwitcherHtmlResp.message }));
      setHtmlContent('Failed to load App Switcher!');
      return;
    }
    const allowedDomains = 'iqvia.com';
    DOMPurify.addHook('afterSanitizeAttributes', function (node) {
      if ('onclick' in node) {
        const urlClick = node?.getAttribute('onclick');
        if (urlClick) {
          let splitArray = urlClick?.split('=');
          if (!splitArray[1]?.includes(allowedDomains)) {
            node.removeAttribute('onclick');
          }
        }
      }
    });

    const staniziedOptions = {
      ADD_ATTR: ['onclick'],
      FORCE_BODY: true
    };
    const cleanAppSwitcherHtml = DOMPurify.sanitize(appSwitcherHtmlResp, staniziedOptions);
    setHtmlContent(cleanAppSwitcherHtml);
  };

  useEffect(() => {
    fetchAppSwitcherHtml();
  }, []);

  /** Used to set the app switcher close button with react component code */
  const domConentUpdate = (element) => {
    const documentElement = element;
    if (documentElement) {
      const appSwitcherButton = documentElement.querySelector('.switcher-icon');
      appSwitcherButton?.addEventListener('click', closePanel.bind(this));
    }
  };

  return (
    <>
      <App
        variant="primary"
        onClick={onOpen}
        data-testid="app-icon"
        style={{ marginTop: '15px', marginRight: '15px', cursor: 'pointer' }}
      />
      <Blade
        className="navigation-panel"
        width={331}
        hasBackdrop
        BackdropProps={{
          onClick: closePanel,
          style: { background: 'transparent' }
        }}
        open={openPanel}
        onClose={closePanel}
        title=""
        alwaysExpanded>
        <div
          ref={(el) => {
            domConentUpdate(el);
          }}
          dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
      </Blade>
    </>
  );
};
export default AppSwitcher;
