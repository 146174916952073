import axios from 'axios';
import Cookies from 'js-cookie';

const getDomainName = () => {
  const urlParts = window.location.hostname.split('.');
  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');
};

export function deleteAllCookies() {
  const domain = getDomainName() || '';
  const cookies = Cookies.get();
  for (let cookieName in cookies) {
    Cookies.set(cookieName, cookies[cookieName], {
      expires: new Date().toUTCString(),
      domain
    });
  }
  return true;
}

export const Logout = async () => {
  const CORE_SERVER_API_URL = Cookies.get('user.server_url');

  try {
    const resp = await axios.get(`${CORE_SERVER_API_URL}/logout`);
    if (resp.data) {
      const deleted = deleteAllCookies();
      return deleted;
    }
    return false;
  } catch (error) {
    console.log('Error while performing logout::', error);
  }
};
