import FolderOpen from 'apollo-react-icons/FolderOpen';
import Switch from 'apollo-react/components/Switch';
import Divider from 'apollo-react/components/Divider';
import Typography from 'apollo-react/components/Typography';
import Select from 'apollo-react/components/Select';
import MenuItem from 'apollo-react/components/MenuItem';
import Grid from 'apollo-react/components/Grid';
import useGlobalMessage from 'Utils/useGlobalMessage';
import { makeStyles } from '@mui/styles';
import { KeyConstants } from 'Utils/KeyConstants';
import useGlobalKeys from 'Utils/useGlobalKeys';
import { MessageConstants } from 'Utils/MessageConstants';

const useStyles = makeStyles({
  fullWidth: {
    width: '100%',
    color: '#333333'
  },
  divider: { color: '#E9E9E9', marginTop: '1rem', marginBottom: '1rem' }
});

function OtherSetting({
  selectedPath,
  automatedFolderSetupUrl = '',
  otherSettingsFD,
  onChangeOtherSettingHandler,
  isMetadataFile,
  handleToggleChange
}) {
  const classes = useStyles();
  const { getMessage } = useGlobalMessage();
  const getGlobalKeys = useGlobalKeys();

  return (
    <Grid container>
      <Grid container item xs={12}>
        <Grid xs={3}>
          <Select
            data-testid="exportPackageFormat"
            name="exportPackageFormat"
            label={getMessage(MessageConstants.EXPORT_PACKAGE_FORMAT)}
            fullWidth
            value={otherSettingsFD.exportPackageFormat}
            onChange={onChangeOtherSettingHandler}
            canDeselect={false}
            SelectProps={{
              renderValue: (selected) =>
                getMessage(KeyConstants.EXPORT_PACKAGE_FORMAT.VALUES[selected]?.LABEL)
            }}>
            {getGlobalKeys(KeyConstants.EXPORT_PACKAGE_FORMAT.TOPIC_NAME)?.map(
              (exportPackageFormat) => (
                <MenuItem value={exportPackageFormat} key={exportPackageFormat}>
                  {getMessage(
                    KeyConstants.EXPORT_PACKAGE_FORMAT.VALUES[exportPackageFormat]?.LABEL
                  )}
                </MenuItem>
              )
            )}
          </Select>
        </Grid>
      </Grid>

      <div className={classes.fullWidth}>
        <Divider className={classes.divider} />
      </div>

      <Grid container item xs={12}>
        <Grid xs={3}>
          <Select
            data-testid="exportFileFormat"
            name="exportFileFormat"
            label={getMessage(MessageConstants.EXPORT_FILE_FORMAT)}
            value={otherSettingsFD.exportFileFormat}
            onChange={onChangeOtherSettingHandler}
            fullWidth
            canDeselect={false}
            SelectProps={{
              renderValue: (selected) =>
                getMessage(KeyConstants.EXPORT_File_FORMAT.VALUES[selected]?.LABEL)
            }}>
            {getGlobalKeys(KeyConstants.EXPORT_File_FORMAT.TOPIC_NAME)?.map((exportfileFormat) => (
              <MenuItem value={exportfileFormat} key={exportfileFormat}>
                {getMessage(KeyConstants.EXPORT_File_FORMAT.VALUES[exportfileFormat]?.LABEL)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <div className={classes.fullWidth}>
        <Divider className={classes.divider} />
      </div>

      <div className={classes.fullWidth}>
        <Typography variant="title2">{getMessage(MessageConstants.AUTOMATED_FOLDER_SETUP)}</Typography>
        <Grid style={{ display: 'flex', alignItems: 'center' }} xs={12}>
          <FolderOpen />
          <span style={{ paddingLeft: '10px' }}>{`${selectedPath.join(
            '/'
          )}/${automatedFolderSetupUrl}`}</span>
        </Grid>
      </div>

      <Grid container item xs={12} spacing={2}>
        <Grid xs={4} item>
          <Select
            name="folderName"
            data-testid="folderName"
            label={getMessage(MessageConstants.FOLDER_NAME)}
            fullWidth
            value={otherSettingsFD.folderName}
            onChange={onChangeOtherSettingHandler}
            canDeselect={false}
            SelectProps={{
              renderValue: (selected) =>
                getMessage(KeyConstants.EXPORT_PATH_FOLDER_NAME.VALUES[selected]?.LABEL)
            }}>
            {getGlobalKeys(KeyConstants.EXPORT_PATH_FOLDER_NAME.TOPIC_NAME)?.map((folderName) => (
              <MenuItem value={folderName} key={folderName}>
                {getMessage(KeyConstants.EXPORT_PATH_FOLDER_NAME.VALUES[folderName]?.LABEL)}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid xs={4} item>
          <Select
            name="subFolder"
            data-testid="subFolder"
            label={getMessage(MessageConstants.SUB_FOLDER)}
            fullWidth
            value={otherSettingsFD.subFolder}
            onChange={onChangeOtherSettingHandler}
            canDeselect={false}
            SelectProps={{
              renderValue: (selected) =>
                getMessage(KeyConstants.EXPORT_PATH_SUB_FOLDER.VALUES[selected]?.LABEL)
            }}>
            {getGlobalKeys(KeyConstants.EXPORT_PATH_SUB_FOLDER.TOPIC_NAME)?.map((subFolder) => (
              <MenuItem value={subFolder} key={subFolder}>
                {getMessage(KeyConstants.EXPORT_PATH_SUB_FOLDER.VALUES[subFolder]?.LABEL)}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>

      <div className={classes.fullWidth}>
        <Divider className={classes.divider} />
      </div>

      <Grid
        container
        item
        xs={12}
        style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}>
        <span>{getMessage(MessageConstants.CREATE_RECORD_COUNT_FILE)}</span>
      </Grid>
      <Grid container item xs={12} style={{ paddingBottom: '20px' }}>
        <Switch
          data-testid="recordCountFile"
          size="small"
          name="recordCountFile"
          checked={isMetadataFile}
          onChange={handleToggleChange}
        />
      </Grid>
    </Grid>
  );
}

export default OtherSetting;
