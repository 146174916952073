import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

const API_URL = process.env.REACT_APP_API_URL;

/**
 * Custom async thunk: GetCDMNameMappings. Fetches CDM naming conversions lookup data from the API.
 * - Usage: `dispatch(GetCDMNameMappings())`
 */
export const GetCDMNameMappings = createAsyncThunk(
  'api/Protocol/CDMNamingConversionsLookUp',
  async () => {
    return await axios.get(`${API_URL}/api/Protocol/CDMNamingConversionsLookUp`);
  }
);

export const GetGlobalMessages = createAsyncThunk('api/Utility/GetGlobalMessages', async () => {
  const response = await axios.get(`${API_URL}/api/Utility/GetGlobalMessages`);
  return response;
});

export const GetGlobalKeys = createAsyncThunk('api/Utility/GetGlobalKeys', async () => {
  const response = await axios.get(`${API_URL}/api/Utility/GetGlobalKeys`);
  return response;
});

/**
 * Service to fetch server rendered App Switcher from CORE.
 */
export const GetAppSwitcherHTML = async () => {
  const CORE_SERVER_API_URL = Cookies.get('user.server_url');
  const payload = {
    fileName: 'app-switcher',
    userId: Cookies.get('user.id'),
    productName: 'Admin'
  };
  try {
    const resp = await axios.post(`${CORE_SERVER_API_URL}/v1/api/webpage/server`, payload);
    if (resp.data) return resp.data;
    else throw resp;
  } catch (error) {
    return {
      error: true,
      message: 'App Switcher :: ' + (error?.message || error || 'Failed to get App Switcher HTML')
    };
  }
};

/**
 * Service to fetch server rendered User Profile from CORE.
 */
export const GetUserProfileHTML = async () => {
  const CORE_SERVER_API_URL = Cookies.get('user.server_url');
  const payload = {
    userId: Cookies.get('user.id'),
    productName: 'Admin'
  };
  try {
    const resp = await axios.post(`${CORE_SERVER_API_URL}/v1/api/webpage/server/profile`, payload);
    if (resp.data) return resp.data;
    else throw resp;
  } catch (error) {
    return {
      error: true,
      message: 'User Profile :: ' + (error?.message || error || 'Failed to get User Profile HTML')
    };
  }
};
