/*eslint-disable */
import React, { useState, useEffect } from 'react';
import Grid from 'apollo-react/components/Grid';
import Typography from 'apollo-react/components/Typography';
import Box from '@mui/material/Box';
import Button from 'apollo-react/components/Button';
import { useSelector, useDispatch } from 'react-redux';
import PlusIcon from 'apollo-react-icons/Plus';
import QueryEditor from './QueryEditor';
import { uuidv4 } from 'Utils';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Loader from 'apollo-react/components/Loader';
import { useStyles } from './style';
import {
  GetPostSQLQueries,
  getDomainAndVariables,
  ReOrderPostSQLQueries,
} from 'Redux/Service/PostSQLEditorService';
import { dateFormatByType } from 'Utils';
import { setNextEnabled } from 'Redux/Slice/RuleEditorSlice';
import { Notification } from 'Components/Common/Notification';
import { MessageConstants } from 'Utils/MessageConstants';
import useGlobalKeys from 'Utils/useGlobalKeys';
import { KeyConstants } from 'Utils/KeyConstants';

const PostSQLEditor = () => {
  const getGlobalKeys = useGlobalKeys();
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const [rowData, setRowData] = useState([]);
  const [addFlag, setAddFlag] = useState(true);
  const [accordionExpandedQueries, setAccordionExpandedQueries] = useState([]);
  const [dragDisableFlag, setDragDisableFlag] = useState(false);
  const dispatch = useDispatch();
  const { id: mappingRuleVersionID } = useParams();
  const [isLoading, setLoading] = useState(false);
  const [allExistingDomainCode, setAllExistingDomainCode] = useState([]);
  const [mappedDomainCode, setMappedDomainCode] = useState([]);
  const [isIconEnable, setIsIconEnable] = useState(true);
  const [showMappedDomainCode, setShowMappedDomainCode] = useState([]);
  const classes = useStyles();
  const defaultSqlTemplet =
    (getGlobalKeys(KeyConstants.QCODE_SQL_TEMPLATE.TOPIC_NAME)?.length > 0 &&
      getGlobalKeys(KeyConstants.QCODE_SQL_TEMPLATE.TOPIC_NAME)[0]) ||
    '';
  const [showSqlQueryOption, setShowSqlQueryOption] = useState(defaultSqlTemplet);
  const [domainQcodeMapping, setDomainQcodeMapping] = useState([]);

  const getDomainAndVariablesData = async () => {
    setLoading(true);
    const response = await dispatch(getDomainAndVariables(mappingRuleVersionID)).then(unwrapResult);
    if (response && response.data && response.data.success) {
      const { domainAndVariables, domainQcodeMapping } = response.data;
      if (domainAndVariables?.length) {
        setAllExistingDomainCode(domainAndVariables.map((domain) => domain.domainCode));
        setMappedDomainCode(
          domainAndVariables.filter(
            (domain) =>
              domain.domainType === 'User Defined' || domain.domainType === 'System Defined'
          )
        );
      }

      if (domainQcodeMapping?.length > 0) {
        setDomainQcodeMapping(domainQcodeMapping);
      }
    } else {
      dispatch(showBanner({ variant: 'error', message: response.data.message }));
    }
  };

  const getPostSQLQueries = async () => {
    setLoading(true);
    const response = await dispatch(GetPostSQLQueries(mappingRuleVersionID)).then(unwrapResult);
    let postSQLQueriesData = [];
    if (response && response.data && response.data.success) {
      response.data?.postSQLQueries?.forEach((query) => {
        postSQLQueriesData.push({
          queryID: query.queryID,
          queryName: query.queryName,
          queryType: query.queryType,
          mappingRuleVersionID: query.mappingRuleVersionID,
          domainCode: query.domainCode,
          sqlQuery: query.userQuery || query.sqlQuery,
          executionOrder: query.executionOrder,
          isExistingDomain: query.isExistingDomain,
          isQueryActive: query.isQueryActive,
          iqAuditType: query.iqAuditType,
          iqAuditDate: dateFormatByType(query.iqAuditDate, 'Table'),
          iqCreatedBy: query.iqCreatedBy,
          iqActive_Flag: query.iqActive_Flag,
          description: query.description,
          isSaved: true,
          qcodeDatasetName: query.qcodeDatasetName
        });
      });
      setRowData(postSQLQueriesData.sort((a, b) => a.executionOrder - b.executionOrder));
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
    }
    setLoading(false);
  };

  useEffect(() => {
    getPostSQLQueries();
    getDomainAndVariablesData();
  }, []);

  const handleEditRow = (value, key, rowID) => {
    setRowData((_rowData) => {
      let modifiedRowsData = [..._rowData];
      modifiedRowsData = modifiedRowsData.map((_row) => {
        if (_row.queryID === rowID) {
          if (key === 'domainCode') {
            setShowSqlQueryOption(defaultSqlTemplet);
            const isExistingDomain = mappedDomainCode?.some(
              (domain) => domain.domainCode === value
            );
            return { ..._row, [key]: value, isExistingDomain, sqlTemplate: defaultSqlTemplet };
          }
          else return { ..._row, [key]: value };
        }
        return _row;
      });
      return modifiedRowsData;
    });
  };

  const handleAddNewQuery = () => {
    setAddFlag(true);
    setIsIconEnable(true);
    setRowData((_rowData) => {
      const queryID = uuidv4();
      const newRow = {
        queryID: '',
        key: queryID,
        queryName: '',
        domainCode: '',
        executionOrder: rowData.length + 1,
        isExistingDomain: false,
        isQueryActive: true,
        isSaved: false,
        isEditEnable: true,
        description: '',
        qcodeDatasetName: defaultSqlTemplet,
      };
      return [..._rowData, newRow];
    });
  };


  useEffect(() => {
    if (!rowData?.length) handleAddNewQuery();
    const dragFlagCheck = isIconEnable || rowData?.length < 2;
    setDragDisableFlag(dragFlagCheck);
    setAddFlag(isIconEnable);
    dispatch(
      setNextEnabled(
        rowData
          .filter((row) => row.queryID || row.queryName || row.domainCode || row.sqlQuery)
          .every((row) => !row.isEditEnable && row.queryID && !row.hasError)
      )
    );
  }, [rowData, isIconEnable]);

  useEffect(() => {
    let mappedDomains = [...mappedDomainCode];
    let customDomains = [];
    rowData.forEach((row) => {
      if (!row.isExistingDomain && row.domainCode !== '') {
        customDomains.push({
          domainCode: row.domainCode,
          isCustomDomain: true
        });
      }
    });
    setShowMappedDomainCode(mappedDomains.concat(customDomains));
  }, [rowData, mappedDomainCode]);

  useEffect(async () => {
    const unSavedQuery = rowData.find((query) => query.isSaved === false);
    if (!unSavedQuery) return;
    const { domainCode } = unSavedQuery;
    if (domainCode?.length === 0) return;
    const findQCodeName = domainQcodeMapping.find((item) => item.domainCode.includes(domainCode));
    setShowSqlQueryOption(findQCodeName.datasetName ? findQCodeName.datasetName : defaultSqlTemplet);
  }, [rowData]);

  const handleMoveVariableRow = async (moveFrom, moveTo) => {
    setLoading(true);
    let sortedRowData = [];
    let updatedSquenceOrder = {};
    sortedRowData = [...rowData];
    let [variableRowToMove] = sortedRowData.splice(moveFrom, 1);
    sortedRowData.splice(moveTo, 0, variableRowToMove);
    sortedRowData.forEach((item, index) => {
      updatedSquenceOrder = { ...updatedSquenceOrder, [item.queryID]: index + 1 };
    });
    const payload = {
      querySquenceOrder: updatedSquenceOrder
    };
    const response = await dispatch(ReOrderPostSQLQueries(payload)).then(unwrapResult);
    if (response && response.data && response.data.success) {
      getPostSQLQueries();
    } else {
      dispatch(showBanner({ variant: 'error', message: response?.data?.message }));
    }
    setLoading(false);
  };

  return (
    <>
      {isLoading ? <Loader isInner overlayClassName={classes.loader}></Loader> : null}
      <Box pt={0} pl={3} pr={3}>
        <Typography variant="h3">Additional Domain Rules (Optional)</Typography>
        <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
          Enter your post-transformation SQL queries here and use drag-and-drop to define their
          execution order
        </Typography>
        <Box mt={2}>
          <Grid container spacing={0} style={{ marginTop: '1rem' }}>
            <Grid item xs={3}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Product Mnemonic
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {rowDataForRuleEditor && rowDataForRuleEditor.productNnemonic}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Description
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {rowDataForRuleEditor && rowDataForRuleEditor.description}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Notification
              variant={'info'}
              message={
                'The Additional Domain Rules will be processed after the Standard Domain Rules defined in the previous step'
              }
            />
          </Grid>
        </Box>
        <Grid container xs={12} spacing={0} style={{ padding: '1rem 0rem 0rem 0rem' }}>
          {rowData.map((row, index) => (
            <QueryEditor
              {...row}
              key={row.queryID}
              index={index}
              setAddFlag={setAddFlag}
              dragDisableFlag={dragDisableFlag}
              setDragDisableFlag={setDragDisableFlag}
              rowData={rowData}
              setRowData={setRowData}
              accordionExpandedQueries={accordionExpandedQueries}
              setAccordionExpandedQueries={setAccordionExpandedQueries}
              addFlag={addFlag}
              onMoveVariableRow={handleMoveVariableRow}
              editRow={handleEditRow}
              allExistingDomainCode={allExistingDomainCode}
              getPostSQLQueries={getPostSQLQueries}
              showMappedDomainCode={showMappedDomainCode}
              setShowMappedDomainCode={setShowMappedDomainCode}
              isIconEnable={isIconEnable}
              setIsIconEnable={setIsIconEnable}
              setLoading={setLoading}
              showSqlQueryOption={showSqlQueryOption}
              defaultSqlTemplet={defaultSqlTemplet}
            />
          ))}
        </Grid>
        <Box
          draggable={false}
          flexDirection={'column'}
          style={{
            marginLeft: '48%'
          }}>
          <Box
            style={{
              marginLeft: '-35px'
            }}>
            <Button
              variant="secondary"
              size={'small'}
              style={{
                padding: '10',
                height: '40px',
                radius: '8px'
              }}
              disabled={addFlag}
              icon={<PlusIcon />}
              onClick={handleAddNewQuery}>
              {'Add SQL query'}
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default PostSQLEditor;
