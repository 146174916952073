import axios from 'axios';
import {
  getHeadersFromCookies,
  getHeadersForAIAAS,
  getHeadersForCore
} from '../service/header.service';
import Cookies from 'js-cookie';

export const goToCore = () => {
  window.location.href = process.env.REACT_APP_CORE_URL;
};

axios.interceptors.request.use(
  (request) => {
    const CORE_SERVER_API_URL = Cookies.get('user.server_url');

    let customHeaders;
    if (
      request.url?.startsWith(process.env.REACT_APP_AIAAS_API_URL) &&
      Boolean(process.env.REACT_APP_AIAAS_API_URL)
    ) {
      customHeaders = getHeadersForAIAAS();
    } else if (request?.url?.startsWith(CORE_SERVER_API_URL)) {
      customHeaders = getHeadersForCore();
    } else {
      customHeaders = getHeadersFromCookies();
    }
    request.headers = { ...request.headers, ...customHeaders };
    return request;
  },
  (error) => {
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      goToCore();
    }
    return error;
  }
);
