/* eslint-disable no-unused-vars */
import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Download from 'apollo-react-icons/Download';
import Filter from 'apollo-react-icons/Filter';
import PlusIcon from 'apollo-react-icons/Plus';
import StatusExclamation from 'apollo-react-icons/StatusExclamation';
import Trash from 'apollo-react-icons/Trash';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import Switch from 'apollo-react/components/Switch';
import Table, {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import _ from 'lodash';
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveTimePoints } from 'Redux/Service/ReferenceDataCardsService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { jsonToExcel, uuidv4 } from 'Utils';
import { timePointValidator } from 'Validators/TimePoints.Validator';
import { TableNewRow, useNewRow } from './TableNewRow';
import { getReferenceData, setReferenceData } from 'service/reference-data.service';
import { unwrapResult } from '@reduxjs/toolkit';
import { GetProductTimePoints } from 'Redux/Service/ReferenceDataCardsService';
import { getTrialVisit } from 'Redux/Service/ReferenceDataCardsService';
import { trialVisitValidator } from 'Validators/TrialVisits.Validator';
import CustomModal from 'Components/Modal/Modal';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useGetTitle from '../../../hooks/useGetTitle';
import useSelectedDatasourcesData from '../../../hooks/useSelectedDatasourcesData';
import Loader from 'Components/Loader/Loader';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Cookies from 'js-cookie';
import { MessageConstants } from 'Utils/MessageConstants';
import useGlobalMessage from 'Utils/useGlobalMessage';
const useStyles = makeStyles({
  toolbar: {
    height: 'auto'
  }
});

const mapping_obj = {
  tpeltm: '',
  tptmnum: '',
  tptmpnt: '',
  tptmref: '',
  tpupdes: '',
  tvclrid: '',
  tvgrpid: '',
  visit: '',
  visitnum: '',
  edc: '',
  qecg: '',
  qlab: '',
  tabular: ''
};

const fieldStyles = {
  style: {
    marginTop: 3,
    marginLeft: -8
  }
};

const TextFieldFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      fullWidth
      margin="none"
      size="small"
    />
  );
};

export const IntegerFilter = ({ accessor, filters, updateFilterValue }) => {
  return (
    <TextField
      value={filters[accessor]}
      name={accessor}
      onChange={updateFilterValue}
      type="number"
      style={{ width: 74 }}
      margin="none"
      size="small"
    />
  );
};

const TimePointsPreview = () => {
  const params = useParams();
  const location = useLocation();
  const mappingId = params.id;
  const { cardTitle } = useGetTitle('time-points');
  const {
    data: selectedDataSourcesData,
    loading,
    error: apiError
  } = useSelectedDatasourcesData(mappingId);
  const userId = Cookies.get('user.id');
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isPreview, setIsPreview] = useState(false);
  const [timePointsData, setTimePointsData] = useState([]);
  const [timePointValidationResult, setTimePointValidationResult] = useState({});
  const [trialVisitData, setTrialVisitData] = useState([]);
  const [trialVisitValidationResult, setTrialVisitValidationResult] = useState({});
  const [isPreviewDataUploaded, setIsPreviewDataUploaded] = useState();
  const [error, setError] = useState();
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const [editedRows, setEditedRows] = useState([]);
  const [newRow, setNewRow, editNewRow] = useNewRow({});
  const [isAdding, setIsAdding] = useState(false);
  const [enableSave, setEnableSave] = useState(false);
  const [isEditDisable, setIsEditDisable] = useState(true);
  const [errorFlag, setErrorFlag] = useState(false);
  const [timePointsMessage, setTimePointsMessage] = useState('');
  const [resetPage, setPageReset] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [errorRowId, setErrorRowId] = useState({});
  const [errorCount, setErrorCount] = useState({});
  const classes = useStyles();
  const [dataSourceData, setDataSourceData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { getMessage } = useGlobalMessage();
  const TIME_POINT = 'time-point';
  const isNull = (value) => {
    if (value === null) {
      return true;
    }
    return false;
  };

  const editMode = editedRows.length > 0;

  useEffect(() => {
    if (!loading) {
      if (
        selectedDataSourcesData &&
        selectedDataSourcesData.data &&
        selectedDataSourcesData.data.success
      ) {
        setDataSourceData(
          selectedDataSourcesData.data.ruleStudyLibrary.filter((row) => row.isMappingRuleConfigured)
        );
      } else {
        apiError && dispatch(showBanner({ variant: 'error', message: apiError }));
      }
    }
  }, [selectedDataSourcesData, loading]);

  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingId
      };
      if (getReferenceData(TIME_POINT) && getReferenceData(TIME_POINT).length > 0) {
        const newData = getReferenceData(TIME_POINT);
        setTimePointsData(newData);
        setIsLoading(false);
        setIsPreviewDataUploaded(true);
        setEnableSave(true);
      } else {
        const timePointsData = await dispatch(GetProductTimePoints(payload)).then(unwrapResult);
        if (timePointsData && timePointsData.data && timePointsData.data.success) {
          if (timePointsData.data.timePoints && timePointsData.data.timePoints.length > 0) {
            setError('');
            const newData = timePointsData.data.timePoints.map((item) => {
              return {
                visit: item.visit,
                visitnum: item.vistnum,
                tvgrpid: item.tvgrpid,
                tvclrid: item.tvclrid,
                tpupdes: item.tpupdes,
                tptmref: item.tptmref,
                tptmpnt: item.tptmpnt,
                tptmnum: isNull(item.tptmnum) ? '' : item.tptmnum,
                tpeltm: item.tpeltm,
                edc: item.edc,
                tabular: item.tabular,
                qecg: item.ecg,
                qlab: item.qlims,
                id: uuidv4(),
                isImported: item.isImported
              };
            });
            setIsLoading(false);
            setTimePointsData(newData);
            setIsPreviewDataUploaded(false);
          } else {
            setIsLoading(false);
            setTimePointsData([]);
            setIsEditDisable(true);
          }
        } else {
          setIsLoading(false);
          setTimePointsMessage(timePointsData.data.message);
          setTimePointsData([]);
        }
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingId
      };
      setIsLoading(true);
      const trialVisitGetAPIData = await dispatch(getTrialVisit(payload)).then(unwrapResult);
      if (trialVisitGetAPIData && trialVisitGetAPIData.data && trialVisitGetAPIData.data.success) {
        if (
          trialVisitGetAPIData.data.trialVisits &&
          trialVisitGetAPIData.data.trialVisits.length > 0
        ) {
          setError('');
          const newData = trialVisitGetAPIData.data.trialVisits.map((item) => {
            return {
              visit: item.visit,
              visitnum: item.visitnum,
              visitdy: isNull(item.visitdy) ? '' : item.visitdy,
              armcd: item.armcd,
              arm: item.arm,
              epoch: item.epoch,
              tvpsttol: isNull(item.tvpsttol) ? '' : item.tvpsttol,
              tvpentol: isNull(item.tvpentol) ? '' : item.tvpentol,
              tvmsttol: isNull(item.tvmsttol) ? '' : item.tvmsttol,
              tvmentol: isNull(item.tvmentol) ? '' : item.tvmentol,
              tvrfpnt: item.tvrfpnt,
              tvgrpid: item.tvgrpid,
              tvclrid: item.tvclrid,
              tvblflg: item.tvblflg,
              tvenrl: item.tvenrl,
              tvstrl: item.tvstrl,
              tvupflg: item.tvupflg,
              id: uuidv4()
            };
          });
          let validationResult = trialVisitValidator(newData);
          setTrialVisitValidationResult(validationResult);
          setTrialVisitData(newData);
        } else {
          setTrialVisitData([]);
        }
      } else {
        setTrialVisitData([]);
      }
      setIsLoading(false);
    })();
  }, []);

  const getValidTitle = (data) => {
    return data.mandatory
      ? data.mandatory
      : data.unique ||
          data.blankFilled ||
          data.nameAndNumberMap ||
          data.visitDescBlank ||
          data.edc ||
          data.qecg ||
          data.qlab ||
          data.tabular ||
          data.qecgBlankError ||
          data.qlabBlankError ||
          data.edcBlankError;
  };

  useEffect(() => {
    (async () => {
      // validate Timespoint
      if (
        timePointsData.length &&
        trialVisitData.length &&
        dataSourceData?.length &&
        Object.keys(trialVisitValidationResult)?.length
      ) {
        const validationResult = await timePointValidator(
          timePointsData,
          trialVisitValidationResult,
          dataSourceData,
          trialVisitData
        );
        setTimePointValidationResult(validationResult);
        setErrorRowId(validationResult.getErrorRowIndex());
        setErrorCount(validationResult.getErrorCount());
      }
    })();
  }, [timePointsData, trialVisitValidationResult, trialVisitData, dataSourceData]);

  useEffect(() => {
    setRows(timePointsData);
  }, [timePointsData]);

  useEffect(() => {
    setPageReset(true);
    setImmediate(() => {
      setPageReset(false);
    });
  }, [errorFlag, editMode]);

  useEffect(() => {
    if (errorFlag && !editMode) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = timePointsData.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setRows(filteredData);
    } else if (!errorFlag && !editMode) {
      setRows(timePointsData);
    } else if (errorFlag && editMode) {
      let filteredData = [];
      if (errorRowId && errorRowId.length > 0) {
        errorRowId.forEach((id) => {
          const item = editedRows.find((data) => {
            return data.id === id;
          });
          !!item && filteredData.push(item);
        });
      }
      setEditedRows(filteredData);
    } else if (!errorFlag && editMode) {
      if (editedRows.length > 0 && timePointsData.length > 0) {
        const idToIndexMap = {};
        const arr = [...timePointsData];
        arr.forEach((data, i) => {
          idToIndexMap[data.id] = i;
        });
        editedRows.forEach((data) => {
          if (data.id in idToIndexMap) {
            arr[idToIndexMap[data.id]] = data;
          } else {
            arr.push(data);
          }
        });
        setEditedRows(arr);
      }
    }
  }, [errorFlag, editMode]);

  const getSavePayload = (data, auditType) => {
    const timePoints = data.map((item) => {
      const {
        visit,
        visitnum,
        tvgrpid,
        tvclrid,
        tpupdes,
        tptmref,
        tptmpnt,
        tptmnum,
        tpeltm,
        edc,
        qecg,
        tabular,
        qlab,
        isImported
        // data_flow_value,
        // data_flow_id
      } = item;
      return {
        // mappingTimePointId: 'TP',
        protocolNumber: protocol.protocolNumber,
        mappingRuleVersionId: mappingId,
        domainNm: '',
        visit: visit,
        vistnum: visitnum,
        tvgrpid: tvgrpid,
        tvclrid: tvclrid,
        tptmpnt: tptmpnt,
        tptmnum: parseInt(tptmnum),
        tptmref: tptmref,
        tpupdes: tpupdes,
        tpeltm: tpeltm,
        // dataFlowId: data_flow_id,
        // dataFlowValue: data_flow_value,
        edc: edc,
        tabular: tabular,
        qlims: qlab,
        ecg: qecg,
        iqCreateDate: new Date().toISOString(),
        iqUpdateDate: new Date().toISOString(),
        iqCreateBy: userId,
        iqUpdateBy: userId,
        iqAuditType: auditType,
        iqAuditDate: new Date().toISOString(),
        iqActiveFlag: true,
        isImported: isImported || location?.state?.isImport || false
      };
    });
    const payload = { timePoints };
    return payload;
  };

  const onSave = async () => {
    let payload;
    let validationResult;
    let newData = [...timePointsData];
    setOpenConfirmModal(false);
    if (editMode && !errorFlag) {
      validationResult = await timePointValidator(
        editedRows,
        trialVisitValidationResult,
        dataSourceData,
        trialVisitData
      );
      setTimePointValidationResult(validationResult);
      payload = getSavePayload(editedRows, 'UPDATE');
    }
    // else if (isAdding) {
    //   validationResult = await timePointValidator(
    //     newData,
    //     trialVisitValidationResult,
    //     dataSourceData,
    //     trialVisitData
    //   );
    //   // setTimePointsData(timePointsDataNew);
    //   setTimePointValidationResult(validationResult);
    //   payload = getSavePayload(newData, 'INSERT');
    // }
    else if (editMode && errorFlag) {
      const idToIndexMap = {};
      if (editedRows.length > 0 && timePointsData.length > 0) {
        const arr = [...timePointsData];
        arr.forEach((data, i) => {
          idToIndexMap[data.id] = i;
        });
        editedRows.forEach((data) => {
          if (data.id in idToIndexMap) {
            newData[idToIndexMap[data.id]] = data;
          } else {
            newData.push(data);
          }
        });
      }
      if (newData.length > 0) {
        validationResult = await timePointValidator(
          newData,
          trialVisitValidationResult,
          dataSourceData,
          trialVisitData
        );
        setTimePointValidationResult(validationResult);
        payload = getSavePayload(newData, 'INSERT');
      }
    } else {
      validationResult = await timePointValidator(
        timePointsData,
        trialVisitValidationResult,
        dataSourceData,
        trialVisitData
      );
      setTimePointValidationResult(validationResult);
      payload = getSavePayload(timePointsData, 'INSERT');
    }
    if (validationResult?.getErrorCount() > 0) {
      if (editMode) {
        setRows(editedRows);
        setTimePointsData(editedRows);
        if (errorFlag) {
          setTimePointsData(newData);
          setRows(newData);
        }
      } else {
        setRows([...rows]);
      }

      dispatch(showBanner({ variant: 'error', message: 'Data still has some error' }));
    } else {
      const saveResponse = await dispatch(saveTimePoints(payload));
      if (
        saveResponse &&
        saveResponse.payload &&
        saveResponse.payload.data &&
        saveResponse.payload.data.success
      ) {
        setReferenceData(TIME_POINT, []);
        if (editMode && !errorFlag) {
          setRows(editedRows);
          setTimePointsData(editedRows);
        }
        // else if (isAdding) {
        //   setRows(timePointsDataNew);
        // }
        else if (editMode && errorFlag) {
          setRows(newData);
          setTimePointsData(newData);
        } else {
          setRows([...rows]);
          setTimePointsData(rows);
        }
        setIsPreviewDataUploaded(false);
        dispatch(showBanner({ variant: 'success', message: saveResponse.payload.data.message }));
      } else {
        if (editMode) {
          setRows(editedRows);
        } else {
          setRows(timePointsData);
        }
        dispatch(showBanner({ variant: 'error', message: 'something went wrong' }));
      }
      setEnableSave(false);
      setEditedRows([]);
    }
  };

  const getconfirmationBeforeSave = () => {
    setConfirmAlert({
      enabled: true,
      title: 'Do you want to Save the Data?',
      message:
        'Please check the information before submitting as the edits/deletes cannot be retrieved once the data is saved',
      variant: 'warning',
      onConfirm: () => {
        onSave();
        setConfirmAlert({
          enabled: false
        });
      },
      onCancel: () => {
        setOpenConfirmModal(false);
        setConfirmAlert({
          enabled: false
        });
      }
    });
  };

  useEffect(() => {
    if (openConfirmModal) {
      getconfirmationBeforeSave();
    }
  }, [openConfirmModal]);

  const getErrorData = (getData, row, key) => {
    return (
      <Tooltip title={getValidTitle(getData)}>
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            backgroundColor: '#f9cccc',
            color: '#595959',
            padding: '15px 11px'
          }}>
          {row[key] === '' ? 'No Data' : row[key]}
        </Typography>
      </Tooltip>
    );
  };

  const EditableCell = useCallback(
    ({ row, column: { accessor: key } }) => {
      const getData =
        timePointValidationResult &&
        Object.keys(timePointValidationResult).length &&
        timePointValidationResult.isColumnValid(row['id'], key);
      return row.editMode ? (
        <TextField
          size="small"
          fullWidth
          value={row[key]}
          onChange={(e) => row.editRow(row.id, key, e.target.value)}
          {...fieldStyles}
          {...(Object.keys(getData).length > 0
            ? {
                helperText: getValidTitle(getData),
                error: true
              }
            : {})}
        />
      ) : Object.keys(getData).length > 0 ? (
        getErrorData(getData, row, key)
      ) : (
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            color: '#595959',
            padding: '15px 11px'
          }}>
          {row[key]}
        </Typography>
      );
    },
    [timePointValidationResult]
  );

  const CustomButtonHeader = useCallback((props) => {
    const {
      editMode,
      onCancel,
      onEditAll,
      toggleFilters,
      timePointsData,
      setIsPreview,
      setIsAdding,
      isAdding,
      setConfirmAlert,
      isPreviewDataUploaded
    } = props;

    const onHandleDownload = () => {
      let newFileArr = [];
      timePointsData.map((obj) => {
        delete obj['id'];
        delete obj['isImported'];
        let upperObj = _.transform(obj, function (result, val, key) {
          result[key.toUpperCase()] = val;
        });
        newFileArr.push(upperObj);
      });
      jsonToExcel(newFileArr, 'timepoints.xlsx');
    };

    const returnToReferenceData = () => {
      if (isPreviewDataUploaded) {
        setConfirmAlert({
          enabled: true,
          title: getMessage(MessageConstants.REFERENCE_DATA_PREVIEW_RETURN_WARNING_TITLE),
          message: location?.state?.isImport
            ? getMessage(MessageConstants.REFERENCE_DATA_PREVIEW_RETURN_IMPORT_WARNING_DESC)
            : getMessage(MessageConstants.REFERENCE_DATA_PREVIEW_RETURN_UPLOAD_WARNING_DESC),
          variant: 'warning',
          onConfirm: () => {
            setReferenceData(TIME_POINT, []);
            navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
            setIsPreview(false);

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      } else if (errorCount) {
        setConfirmAlert({
          enabled: true,
          title: getMessage(MessageConstants.REFERENCE_DATA_PREVIEW_RETURN_WARNING_TITLE),
          message: getMessage(MessageConstants.REFERENCE_DATA_PREVIEW_RETURN_EDIT_WARNING_DESC),
          variant: 'warning',
          onConfirm: () => {
            navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
            setIsPreview(false);

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        });
      } else {
        navigate(`/product-designer/rule-editor/${mappingId}/reference-data`);
        setIsPreview(false);
      }
    };

    const handleErrorData = (e) => {
      setErrorFlag(e.target.checked);
    };

    return (
      <>
        <div style={{ width: '100%' }}>
          <div>
            <Button
              icon={<ChevronLeft />}
              size="small"
              onClick={returnToReferenceData}
              style={{
                marginRight: 10,
                marginBottom: '10px',
                marginTop: '10px'
              }}>
              Return to reference data upload
            </Button>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Grid item xs={6}>
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 600,
                      fontSize: '20px',
                      float: 'left',
                      marginRight: '20px'
                    }}>
                    {cardTitle && cardTitle.length > 0 && cardTitle[0].displayName}
                  </Typography>
                  {errorCount > 0 && (
                    <div
                      style={{
                        backgroundColor: '#E20000',
                        float: 'left',
                        width: '83px',
                        paddingBottom: '2px',
                        paddingLeft: '2px',
                        border: '1px solid white',
                        borderRadius: '4px'
                      }}>
                      <Typography
                        variant="body2"
                        style={{ fontWeight: 300, fontSize: '14px', marginTop: '1px' }}>
                        <span
                          style={{
                            color: 'white'
                          }}>
                          <StatusExclamation
                            style={{
                              color: 'white',
                              float: 'left',
                              height: '16px',
                              marginTop: '2px'
                            }}
                          />
                          {`${errorCount} errors`}
                        </span>
                      </Typography>
                    </div>
                  )}
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Grid item xs={9} style={{ marginRight: '4px' }}>
                    {/* <TextField placeholder="Placeholder" fullWidth style={{ margin: 0 }} /> */}
                  </Grid>

                  <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button variant="secondary" size="small" onClick={toggleFilters}>
                      <Filter style={{ height: '18px', width: '18px', marginRight: '5px' }} />{' '}
                      Filter
                    </Button>
                    <Button size="small" onClick={onHandleDownload} style={{ color: 'black' }}>
                      <Download />
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <hr style={{ margin: '22px 0px' }} />
          <div style={{ display: 'flex', justifyContent: 'space-between', flexDirection: 'row' }}>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Typography
                style={{ fontSize: '13px', margin: '4px 6px 0px 0px' }}
                variant="subtitle"
                gutterBottom>
                {errorFlag ? 'Show only rows with errors' : 'Show All'}
              </Typography>
              <Switch size={'small'} checked={errorFlag} onChange={handleErrorData} />
            </span>
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                disabled={isAdding || editMode}
                size="small"
                variant="secondary"
                icon={PlusIcon}
                onClick={() => setIsAdding((adding) => !adding)}
                style={{ marginRight: 8 }}>
                {'Add row'}
              </Button>
              {!editMode && (
                <Button size="small" variant="primary" onClick={onEditAll}>
                  {'Edit all'}
                </Button>
              )}
              {editMode && (
                <Button size="small" onClick={onCancel}>
                  {'Cancel'}
                </Button>
              )}
              {(enableSave || editMode || isPreviewDataUploaded) && (
                <Button
                  size="small"
                  style={{ marginLeft: 8 }}
                  variant="primary"
                  onClick={() => setOpenConfirmModal(true)}>
                  {'Save'}
                </Button>
              )}
            </span>
          </div>
        </div>
      </>
    );
  });

  const ActionCell = useCallback(({ row: { id, editMode, onDelete } }) => {
    let isDisabled = timePointsData.length < 2;
    return (
      editMode || (
        <Tooltip title={!isDisabled ? 'Delete' : 'Table cannot be empty'} disableFocusListener>
          <Button
            size="small"
            style={isDisabled ? { cursor: 'not-allowed' } : {}}
            onClick={!isDisabled ? () => onDelete(id) : null}>
            <Trash
              style={
                isDisabled
                  ? {
                      color: 'grey'
                    }
                  : {
                      color: 'black'
                    }
              }
            />
          </Button>
        </Tooltip>
      )
    );
  });

  const columns = useMemo(
    () => [
      {
        header: 'VISIT',
        accessor: 'visit',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('visit'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'VISITNUM',
        accessor: 'visitnum',
        filterComponent: IntegerFilter,
        filterFunction: numberSearchFilter('visitnum'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVGRPID',
        accessor: 'tvgrpid',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tvgrpid'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TVCLRID',
        accessor: 'tvclrid',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tvclrid'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TPTMPNT',
        accessor: 'tptmpnt',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tptmpnt'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TPTMNUM',
        accessor: 'tptmnum',
        filterComponent: IntegerFilter,
        filterFunction: numberSearchFilter('tptmnum'),
        sortFunction: compareNumbers,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TPTMREF',
        accessor: 'tptmref',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tptmref'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TPUPDES',
        accessor: 'tpupdes',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tpupdes'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TPELTM',
        accessor: 'tpeltm',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tpeltm'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'EDC',
        accessor: 'edc',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('edc'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'QLAB',
        accessor: 'qlab',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('qlab'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'ECG',
        accessor: 'qecg',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('qecg'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        header: 'TABULAR',
        accessor: 'tabular',
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter('tabular'),
        sortFunction: compareStrings,
        customCell: EditableCell,
        fixedWidth: false
      },
      {
        accessor: 'action',
        customCell: ActionCell,
        align: 'right'
      }
    ],
    [EditableCell, ActionCell, timePointValidationResult]
  );

  const onEditAll = () => {
    setEditedRows(rows);
  };

  const onCancel = () => {
    setEditedRows([]);
  };

  const onDelete = async (id) => {
    const updatedRows = timePointsData.filter((row) => row.id !== id);
    const validationResult = await timePointValidator(
      updatedRows,
      trialVisitValidationResult,
      dataSourceData,
      trialVisitData
    );
    setTimePointValidationResult(validationResult);
    setTimePointsData(updatedRows);
    setEnableSave(true);
  };

  const editRow = (id, key, value) => {
    setEditedRows((timePointsData) =>
      timePointsData.map((row) => (row.id === id ? { ...row, [key]: value } : row))
    );
  };

  const onAddNewRowSave = async () => {
    const copyTimePointsData = [...timePointsData];
    if (newRow && Object.keys(newRow).length > 0) {
      const newRowData = { ...newRow, id: uuidv4() };
      const obj = { ...mapping_obj, ...newRowData };
      copyTimePointsData.unshift(obj);
      const validationResult = await timePointValidator(
        copyTimePointsData,
        trialVisitValidationResult,
        dataSourceData,
        trialVisitData
      );
      setTimePointValidationResult(validationResult);
      setTimePointsData(copyTimePointsData);
      setRows(copyTimePointsData);
      if (validationResult?.getErrorCount() === 0) {
        setOpenConfirmModal(true);
      }
    }
  };

  return (
    <>
      {isLoading && loading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <>
          <Card style={{ marginTop: '1rem' }}>
            <Table
              columns={columns}
              classes={classes}
              rows={(editMode ? editedRows : rows).map((row) => ({
                ...row,
                onDelete,
                editRow,
                editMode,
                key: row.id
              }))}
              initialSortedColumn="visit"
              initialSortOrder="asc"
              page={resetPage ? 0 : undefined}
              rowsPerPageOptions={[10, 20, 50, 100, 'All']}
              hasScroll
              maxHeight={650}
              rowProps={{ hover: false }}
              tablePaginationProps={{
                //   labelDisplayedRows: ({ from, to, count }) =>
                //     `${count === 1 ? 'Employee' : 'Employees'} ${from}-${to} of ${count}`,
                truncate: true
              }}
              CustomHeader={CustomButtonHeader}
              headerProps={{
                onEditAll,
                onSave,
                onCancel,
                editMode,
                timePointsData,
                // handleStepValue,
                // handleShowPreview,
                setIsPreview,
                setIsAdding,
                isAdding,
                setConfirmAlert,
                isPreviewDataUploaded
              }}
              CustomSubHeader={() => (
                <TableNewRow
                  isAdding={isAdding}
                  setIsAdding={setIsAdding}
                  onSave={onAddNewRowSave}
                  newRow={newRow}
                  editNewRow={editNewRow}
                  setNewRow={setNewRow}
                  columns={columns}
                />
              )}
            />
          </Card>
          <CustomModal
            display={confirmAlert.enabled}
            title={confirmAlert.title}
            message={confirmAlert.message}
            body={confirmAlert.body}
            variant={confirmAlert.variant}
            buttonPrimaryLabel={'Ok'}
            handlePrimaryAction={() => confirmAlert?.onConfirm && confirmAlert.onConfirm()}
            buttonSecondardyLabel={'Cancel'}
            handleClose={() => confirmAlert?.onCancel && confirmAlert.onCancel()}
          />
        </>
      )}
    </>
  );
};

export default TimePointsPreview;
