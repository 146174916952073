import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GetProductDataSources = createAsyncThunk(
  'ReferenceData/saveTimePoints',
  async (payload) => {
    return await axios.get(
      `${API_URL}/api/RuleEditor/GetProductDataSourcesByMappingRuleID?MappingRuleVersionID=${payload}`
    );
  }
);

export const saveTimePoints = createAsyncThunk('ReferenceData/saveTimePoints', async (payload) => {
  return await axios.post(`${API_URL}/api/RuleEditor/SaveProductTimePoints`, payload);
});
export const saveCtpSvt = createAsyncThunk('ReferenceData/saveCtpSvt', async (payload) => {
  return await axios.post(`${API_URL}/api/RuleEditor/SaveCTPReferenceData`, payload);
});

export const getTrialVisit = createAsyncThunk('ReferenceData/getTrialVisit', async (payload) => {
  return await axios.post(`${API_URL}/api/RuleEditor/GetTrialVisitsData`, payload);
});

export const getTrialElement = createAsyncThunk(
  'ReferenceData/getTrialElement',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetTrialElementsData`, payload);
  }
);

export const GetCtmsVisitsData = createAsyncThunk(
  'ReferenceData/GetCtmsVisitsData',
  async (payload) => {
    return await axios.post(`${API_URL}/api/Protocol/GetCtmsVisitsData`, payload);
  }
);
export const GetProductTimePoints = createAsyncThunk(
  'ReferenceData/GetProductTimePoints',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetProductTimePoints`, payload);
  }
);

export const GetProductCTPData = createAsyncThunk(
  'ReferenceData/GetProductCTPReferenceData',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetProductCTPReferenceData`, payload);
  }
);

export const GetTimePointsData = createAsyncThunk(
  'Protocol/GetTimePointsData',
  ({ mappingRuleVersionID }) => {
    return axios.get(
      `${API_URL}/api/Protocol/GetTimePointsData?mappingRuleVersionID=${mappingRuleVersionID}`
    );
  }
);

export const getTrialIE = createAsyncThunk('ReferenceData/getTrialIE', async (payload) => {
  return await axios.post(`${API_URL}/api/RuleEditor/GetTrialInclusionExclusionData`, payload);
});
export const saveTrialVisits = createAsyncThunk(
  'ReferenceData/saveTrialVisits',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SaveTrialVisitData`, payload);
  }
);

export const SaveTrialElementsData = createAsyncThunk(
  'ReferenceData/SaveTrialElementsData',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SaveTrialElementsData`, payload);
  }
);

export const SaveTrialIEData = createAsyncThunk(
  'ReferenceData/SaveTrialIEData',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SaveTrialInclusionExclusionData`, payload);
  }
);

export const GetTrialArmsData = createAsyncThunk(
  'ReferenceData/GetTrialArmsData',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetTrialArmsData`, payload);
  }
);

export const SaveTrialArmsData = createAsyncThunk(
  'ReferenceData/SaveTrialArmsData',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SaveTrialArmsData`, payload);
  }
);

export const getCodeLists = createAsyncThunk(
  'ReferenceData/GetProductCodeList',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetProductCodeList`, payload);
  }
);

export const syncProductCodelist = createAsyncThunk(
  'ReferenceData/SyncProductCodeList',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SyncProductCodeList`, payload);
  }
);

export const UpdateMappingCodeList = createAsyncThunk(
  'ReferenceData/UpdateMappingCodeList',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/UpdateMappingCodeList`, payload);
  }
);

export const DeleteMappingCodeList = createAsyncThunk(
  'ReferenceData/DeleteMappingCodeList',
  async (mappingCodeListIds) => {
    return await axios.post(`${API_URL}/api/RuleEditor/DeleteMappingCodeList`, {
      mappingCodeListIds
    });
  }
);

export const saveCodeLists = createAsyncThunk('ReferenceData/saveCodeLists', async (payload) => {
  return await axios.post(`${API_URL}/api/RuleEditor/SaveProductCodeList`, payload);
});

export const GetSupplementalQualifiersData = createAsyncThunk(
  'ReferenceData/GetSupplementalQualifiersData',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/GetSupplementalQualifiersData`, payload);
  }
);

export const SaveSupplementalQualifiersData = createAsyncThunk(
  'ReferenceData/SaveSupplementalQualifiersData',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/SaveSupplementalQualifiersData`, payload);
  }
);

export const GetSDTMDomainPrefix = createAsyncThunk(
  'ReferenceData/GetSDTMDomainPrefix',
  ({ targetDataModelID, targetDataModelVersion }) => {
    return axios.get(
      `${API_URL}/api/Library/GetSDTMDomainPrefix?libraryId=${targetDataModelID}&libraryVersion=${targetDataModelVersion}`
    );
  }
);
export const DeleteReferenceData = createAsyncThunk(
  'ReferenceData/DeleteReferenceData',
  async (payload) => {
    return await axios.post(`${API_URL}/api/RuleEditor/DeleteReferenceData`, payload);
  }
);

export const GetFormRepeatFlag = createAsyncThunk(
  'RuleEditor/GetFormRepeatFlag',
  ({ mappingRuleVersionID }) => {
    return axios.post(
      `${API_URL}/api/RuleEditor/GetFormRepeatFlag?mappingRuleVersionID=${mappingRuleVersionID}`
    );
  }
);

export const GetFormSheetsData = createAsyncThunk(
  'ReferenceData/GetFormSheetsData',
  async (payload) => {
    return axios.post(`${API_URL}/api/RuleEditor/GetFormSheetsData`, payload);
  }
);

export const SaveFormSheetsData = createAsyncThunk(
  'ReferenceData/SaveFormSheetsData',
  async (payload) => {
    return axios.post(`${API_URL}/api/RuleEditor/SaveFormSheetsData`, payload);
  }
);

export const GetQCodeReferenceData = createAsyncThunk(
  'ReferenceData/GetQCodeReferenceData',
  async (payload) => {
    return axios.post(`${API_URL}/api/RuleEditor/GetQCodeReferenceData`, payload);
  }
);
