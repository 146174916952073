import React from 'react';

const SuppQualIdentifier = () => {
  return (
    <span
      style={{
        background: '#FFF',
        border: '1px solid #888',
        borderRadius: '30px',
        padding: '0px 8px',
        marginLeft: '5px',
        fontSize: '14px'
      }}>
      SQ
    </span>
  );
};

export default SuppQualIdentifier;
