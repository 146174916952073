import React, { useState, useEffect } from 'react';
import Grid from 'apollo-react/components/Grid';
import Modal from 'apollo-react/components/Modal';
import TextField from 'apollo-react/components/TextField';
import { makeStyles } from '@mui/styles';
import {
  Box,
  Divider,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography
} from 'apollo-react';
import Info from 'apollo-react-icons/Info';
import { useParams } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { useDispatch, useSelector } from 'react-redux';
import Cookies from 'js-cookie';
import { SaveVLCRules } from 'Redux/Service/VLCRulesService';
import {
  formatVLCErrorMessage,
  validateVLCActionMessage,
  validateVLCRule
} from './VLCRules.validator';
import { Editor, useMonaco } from '@monaco-editor/react';
import { ExpressionErrorDetails } from './VLCRules.errors';
import { neutral7, neutral8 } from 'apollo-react/colors';
import useGlobalMessage from 'Utils/useGlobalMessage';

const useStyles = makeStyles({
  modal: {
    width: '70vw',
    maxWidth: '70vw',
    '&>div:nth-child(1)': {
      borderBottom: '1px solid #E9E9E9',
      boxShadow: '0px 0px 15px #0002'
    },
    '&>div:nth-child(3)': {
      margin: '0 0 16px 0',
      padding: '16px 24px 0 24px',
      borderTop: '1px solid #E9E9E9'
    }
  },
  tooltip: {
    whiteSpace: 'pre'
  }
});

let DUPLICATE_RULE_NAME_ERROR = 'VLC Rule name already exist.';
let RULE_NAME_LENGTH_EXCEEDS = 'Maximum 50 characters are allowed.';

export const VLCRuleEditor = ({
  displayVlcRuleEditor,
  setDisplayVlcRuleEditor,
  existingRuleName,
  editVLCRule,
  setEditVLCRule,
  fetchVLCRules,
  domainAndVariablesData,
  dataSetName,
  vlcRules
}) => {
  const classes = useStyles();
  const { id: mappingRuleVersionID } = useParams();
  const [columnName, setColumnName] = useState([]);
  const [ruleName, setRuleName] = useState('');
  const [selectedDataSet, setSelectedDataSet] = useState('');
  const [selectedColumnName, setSelectedColumnName] = useState([]);
  const [vlcActionCondition, setVlcActionCondition] = useState('');
  const [vlcActionMessage, setVlcActionMessage] = useState('');
  const [description, setDescription] = useState('');
  const [vlcExpression, setVLCExpression] = useState('');
  const [errorText, setErrorText] = useState('');
  const [actionMsgErrorText, setActionMsgErrorText] = useState('');
  const [isRuleNameExists, setIsRuleNameExists] = useState(false);
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const dispatch = useDispatch();
  const monaco = useMonaco();
  const { VLC_EXPRESSION_SUPPORTED_SYNTAX } = useGlobalMessage();

  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const userId = Cookies.get('user.id');

  useEffect(() => {
    if (monaco && domainAndVariablesData?.length && selectedDataSet) {
      const _handleCreateSuggestion = (variable, isReserved) => ({
        filterText: variable,
        label: variable,
        detail: `${variable}${isReserved ? ' (R)' : ''}`,
        documentation: `Column: ${variable}${isReserved ? '\n(Reserved Variable)' : ''}`,
        kind: monaco.languages.CompletionItemKind.Property,
        insertText: variable
      });
      console.log('_handleCreateSuggestion', domainAndVariablesData);
      return monaco.languages?.registerCompletionItemProvider('pgsql', {
        provideCompletionItems: () => {
          const suggestions = [];
          domainAndVariablesData.forEach((domain) => {
            if (domain.domainCode === selectedDataSet) {
              domain.variableName?.forEach((variable) => {
                suggestions.push(_handleCreateSuggestion(variable));
              });
              domain.reservedVariables?.forEach((variable) => {
                suggestions.push(_handleCreateSuggestion(variable, true));
              });
            }
          });
          console.log('suggestions', suggestions);
          return { suggestions };
        }
      }).dispose;
    }
  }, [monaco, domainAndVariablesData, selectedDataSet]);

  useEffect(() => {
    if (editVLCRule?.vlcRuleID) {
      setRuleName(editVLCRule.vlcRuleName);
      setVlcActionMessage(editVLCRule.vlcActionMessage);
      setVlcActionCondition(editVLCRule.vlcActionCondition);
      setDescription(editVLCRule.description);
      setSelectedDataSet(editVLCRule.dataSet);
      setSelectedColumnName(editVLCRule.columns);
      setVLCExpression(editVLCRule.expression);
    }
  }, [editVLCRule]);

  useEffect(() => {
    const selectedData = domainAndVariablesData?.filter(
      (domain) => domain.domainCode === selectedDataSet
    );
    setColumnName(selectedData[0]?.reservedVariables.concat(selectedData[0]?.variableName).sort());
  }, [selectedDataSet]);

  const resetInitialValues = () => {
    setEditVLCRule({});
    setDisplayVlcRuleEditor(false);
    setColumnName([]);
    setSelectedColumnName([]);
    setRuleName('');
    setVLCExpression('');
    setVlcActionCondition('');
    setVlcActionMessage('');
    setDescription('');
    setSelectedDataSet('');
    setRuleName('');
    setIsRuleNameExists(false);
  };

  const handleClose = () => {
    resetInitialValues();
  };

  const handleSelectDataSet = (e) => {
    setSelectedDataSet(e.target.value);
    setSelectedColumnName([]);
  };

  const validateExpAndMsg = () => {
    let isValid = true;
    setErrorText('');
    setActionMsgErrorText('');
    try {
      vlcExpression && validateVLCRule(vlcExpression, columnName);
    } catch (e) {
      setErrorText(formatVLCErrorMessage(e));
      isValid &&= false;
    }
    try {
      validateVLCActionMessage(vlcActionMessage, columnName);
    } catch (e) {
      setActionMsgErrorText(e);
      isValid &&= false;
    }
    return isValid;
  };

  const handleSave = async () => {
    setIsSaveDisabled(true);
    const _isRuleNameExists = editVLCRule?.vlcRuleID
      ? existingRuleName
          .filter((vlcRule) => vlcRule !== editVLCRule?.vlcRuleName)
          .includes(ruleName)
      : existingRuleName.includes(ruleName);
    const isExpAndMsgValid = validateExpAndMsg();
    setIsRuleNameExists(_isRuleNameExists);

    if (!_isRuleNameExists && isExpAndMsgValid) {
      setIsRuleNameExists(false);

      const payload = {
        vlcRules: {
          vlcRuleID: editVLCRule?.vlcRuleID ? editVLCRule.vlcRuleID : '',
          vlcRuleName: ruleName,
          mappingRuleVersionID: mappingRuleVersionID,
          protocolNumber: protocol?.protocolNumber,
          dataSet: selectedDataSet,
          columns: selectedColumnName,
          expression: vlcExpression,
          isActive: true,
          vlcActionCondition: vlcActionCondition,
          vlcActionMessage: vlcActionMessage,
          description: description,
          iqCreatedBy: userId,
          iqAuditType: editVLCRule?.vlcRuleID ? 'UPDATE' : 'INSERT',
          iqAuditDate: new Date().toISOString(),
          iqActiveFlag: true
        }
      };
      const saveResponse = await dispatch(SaveVLCRules(payload)).then(unwrapResult);
      if (saveResponse && saveResponse?.data.success) {
        resetInitialValues();
        setIsSaveDisabled(false);
        dispatch(
          showBanner({ variant: 'success', message: `VLC Rule ${ruleName} has been saved.` })
        );
        await fetchVLCRules();
      } else {
        setIsSaveDisabled(false);
        dispatch(showBanner({ variant: 'error', message: saveResponse?.data.message }));
      }
    } else setIsSaveDisabled(false);
  };

  const buttonProps = [
    {
      label: 'Cancel',
      onClick: () => handleClose()
    },
    {
      label: editVLCRule?.isActive === false ? 'Save and Activate' : 'Save',
      disabled:
        isSaveDisabled ||
        !(
          ruleName &&
          selectedDataSet &&
          selectedColumnName.length > 0 &&
          vlcExpression &&
          vlcActionCondition &&
          vlcActionMessage
        ),
      onClick: () => handleSave()
    }
  ];

  const handleExpressionChange = (expression) => {
    setErrorText('');
    setVLCExpression(expression);
  };

  const handleActionMessageChange = (e) => {
    setActionMsgErrorText('');
    setVlcActionMessage(e.target.value);
  };

  useEffect(() => {
    validateExpAndMsg();
  }, [columnName]);

  return (
    <Modal
      open={displayVlcRuleEditor}
      title={editVLCRule?.vlcRuleID ? 'Edit VLC Rule' : 'Add VLC Rule'}
      subtitle={editVLCRule?.vlcRuleID ? `Last Updated: ${editVLCRule?.iqAuditDate}` : ''}
      data-testid="add-VLC-modal"
      buttonProps={buttonProps}
      className={classes.modal}
      onClose={handleClose}>
      <Grid container columnSpacing={1}>
        <Grid item xs={4}>
          <TextField
            data-testid="vlcRuleName-input"
            label={
              <span>
                VLC Rule Name
                <Tooltip
                  title={'Only editable when this Rule is not published to any pipelines.'}
                  placement="right">
                  <Info
                    sx={{
                      height: '14px',
                      width: '14px',
                      marginBottom: '-3px',
                      marginLeft: '5px',
                      color: '#4D4D4D'
                    }}
                  />
                </Tooltip>
              </span>
            }
            fullWidth
            onChange={(e) => setRuleName(e.target.value)}
            value={ruleName}
            InputProps={{
              inputProps: {
                maxLength: 50
              }
            }}
            disabled={Object.values(vlcRules || {}).some(
              (el) => el.vlcRuleID === editVLCRule?.vlcRuleID && el.isPublished
            )}
            helperText={
              isRuleNameExists
                ? DUPLICATE_RULE_NAME_ERROR
                : ruleName?.length === 50 && RULE_NAME_LENGTH_EXCEEDS
            }
            error={isRuleNameExists || ruleName?.length === 50}
          />
        </Grid>
        <Grid item xs={4}>
          <Select
            data-testid="dataset-input"
            label={'Dataset'}
            fullWidth
            onChange={(e) => handleSelectDataSet(e)}
            value={selectedDataSet}
            canDeselect={false}>
            {dataSetName?.map((name) => (
              <MenuItem value={name} key={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <Select
            label={'Columns to Raise Issue Against'}
            data-testid="columns-input"
            multiple
            fullWidth
            onChange={(e) => setSelectedColumnName(e.target.value)}
            value={selectedColumnName}>
            {columnName?.map((name) => (
              <MenuItem value={name} key={name}>
                {name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sx={{ height: 'auto' }}>
          <Typography
            component={'label'}
            htmlFor="expression"
            sx={{ fontSize: '14px', color: neutral8 }}>
            Expression
          </Typography>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={VLC_EXPRESSION_SUPPORTED_SYNTAX.replaceAll('\\n', '\n')}
            placement="right">
            <Info
              sx={{
                height: '14px',
                width: '14px',
                marginBottom: '-3px',
                marginLeft: '5px',
                color: '#4D4D4D'
              }}
            />
          </Tooltip>
          <Box
            sx={{
              borderRadius: '4px',
              border: '1px solid #d9d9d9',
              width: '100%',
              // pointerEvents: readOnly ? 'none' : undefined,
              '& section ': { pl: '4px', pb: '10px' },
              resize: 'vertical',
              height: '100px',
              minHeight: '100px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto'
            }}>
            <Editor
              name="expression"
              value={vlcExpression}
              defaultLanguage="pgsql"
              onChange={handleExpressionChange}
              options={{ minimap: { enabled: false } }}
            />
          </Box>
          {!errorText && (
            <Typography
              fullWidth
              sx={{ float: 'right', color: neutral7, fontSize: '10px', lineHeight: '16px' }}>
              Ctrl + Space to view the columns of the dataset
            </Typography>
          )}
          <ExpressionErrorDetails message={errorText} />
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontSize: '14px', marginTop: '1rem' }}>
            VLC Action Condition
            <Tooltip
              title={'VLC Action triggers when expression is evaluated on the below selection.'}
              placement="right">
              <Info
                sx={{
                  height: '14px',
                  width: '14px',
                  marginBottom: '-3px',
                  marginLeft: '5px',
                  color: '#4D4D4D'
                }}
              />
            </Tooltip>
          </Typography>
          <RadioGroup
            aria-label="VLC Action Condition"
            data-testid="vlcActionCondition-input"
            name="VLC Action Condition"
            onChange={(e) => setVlcActionCondition(e.target.value)}
            value={vlcActionCondition}
            sx={{ display: 'flex', flexDirection: 'row' }}>
            <Radio key={`action-True`} label={'True'} value={'TRUE'} />
            <Radio key={`action-False`} label={'False'} value={'FALSE'} />
          </RadioGroup>
        </Grid>
        <Grid item xs={12}>
          <TextField
            data-testid="vlcActionMessage-input"
            label={
              <span>
                VLC Action Message
                <Tooltip
                  title={
                    'Column name should always be entered under <> in-order to extract the values of the column, example: <SUBJID>'
                  }
                  placement="right">
                  <Info
                    sx={{
                      height: '14px',
                      width: '14px',
                      marginBottom: '-3px',
                      marginLeft: '5px',
                      color: '#4D4D4D'
                    }}
                  />
                </Tooltip>
              </span>
            }
            onChange={handleActionMessageChange}
            value={vlcActionMessage}
            fullWidth
            sx={{ mb: 0 }}
          />
          <ExpressionErrorDetails message={actionMsgErrorText} />
        </Grid>
        <Grid item xs={12} mt={3}>
          <Divider />
        </Grid>
        <Grid item xs={12}>
          <TextField
            data-testid="description-input"
            onChange={(e) => setDescription(e.target.value)}
            label={'Description (Optional)'}
            value={description}
            fullWidth
          />
        </Grid>
      </Grid>
    </Modal>
  );
};
