export const PASSWORD_PLACEHOLDER = '.......';

export const handleSourceData = (sourceData, domainCode, MDSFlag) => {
  const data = sourceData.studyDataSources.map((item) => {
    return sourceData.masterDataSources.filter((el) => el.dataSourceID === item.datasourceID);
  });

  const getDataByElement = (source, type) => {
    return sourceData.masterDataSources
      .filter((item) => item.sourceName === source)
      .map((item) => {
        return item[type];
      });
  };

  const formatedData = data.flat()?.map((source) => {
    const commondata = {
      sourceName: source.sourceName,
      dataSourceID: source.dataSourceID,
      databaseName: source.databaseName,
      sourceID: source.sourceID,
      userID: source.userID,
      password: PASSWORD_PLACEHOLDER,
      passwordValue: source.password,
      databaseOptions: getDataByElement(source.sourceName, 'databaseName'),
      dataSourceIdoptions: getDataByElement(source.sourceName, 'dataSourceID'),
      sourceIdoptions: getDataByElement(source.sourceName, 'sourceID'),
      userIDOptions: getDataByElement(source.sourceName, 'userID'),
      passwordOptions: getDataByElement(source.sourceName, 'password'),
      connectionMessage: source.connectionMessage,
      connectionStatus: source.connectionStatus
    };

    if (source.sourceName !== 'CDR') {
      return { ...commondata, connection: '', selected: true };
    } else {
      let libraries = [...new Set(getDataByElement(source.sourceName, 'subSourceName'))]
        .toString()
        .split(',');
      return {
        ...commondata,
        showLibraries: true,
        libraries: [...new Set(libraries)].map((subSource) => {
          return {
            parentSourceName: source.sourceName,
            isSubLibrary: true,
            sourceName: subSource,
            connection: '',
            selected: true
          };
        })
      };
    }
  });

  const subSources =
    formatedData.filter((item) => item.sourceName === 'CDR')[0]?.libraries !== undefined
      ? formatedData.filter((item) => item.sourceName === 'CDR')[0].libraries.map((el) => el)
      : [];

  const parentIndex = subSources?.length
    ? formatedData.findIndex((_source) => _source.sourceName === subSources[0].parentSourceName)
    : -1;

  formatedData.splice(parentIndex + 1, 0, ...subSources);
  let newFormattedData = formatedData.filter(
    (item) =>
      item.sourceName === 'CDR' || item.sourceName === domainCode || sourceData.isDataSourceDummy
  );

  return {
    mappingRuleVersionID: sourceData.mappingRuleVersionID,
    masterDataSources: MDSFlag ? newFormattedData : formatedData,
    studyDataSources: sourceData.studyDataSources,
    isDataSourceDummy: sourceData.isDataSourceDummy
  };
};

export const handleErrorData = (data) => {
  if (data?.errorLists && data.errorLists?.length) {
    const errorList = data.errorLists.map((item) => {
      return {
        errorCode: item.errorCode,
        category: item.category,
        message: item.message
      };
    });
    return errorList;
  } else {
    return [];
  }
};

export const rowCheckboxSelect = (keyName, keyValue, value, thisRow, rowsToUdate, row) => {
  const updateRow = {
    ...rowsToUdate,
    userID:
      value !== undefined
        ? row.userIDOptions[thisRow.databaseOptions.findIndex(value)]
        : row.userID,
    passwordValue:
      value !== undefined
        ? row.passwordOptions[thisRow.databaseOptions.findIndex(value)]
        : row.passwordValue,
    dataSourceID:
      value !== undefined
        ? row.dataSourceIdoptions[thisRow.databaseOptions.findIndex(value)]
        : row.dataSourceID,
    sourceID:
      value !== undefined
        ? row.sourceIdoptions[thisRow.databaseOptions.findIndex(value)]
        : row.sourceID,
    connection:
      keyName === 'databaseName' ? '' : keyName === 'selected' && keyValue ? row.connection : ''
  };
  return {
    ...row,
    ...updateRow
  };
};
