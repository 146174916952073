/*eslint-disable */
import React, { useCallback, useState, useEffect, useMemo, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import Box from '@mui/material/Box';
import Button from 'apollo-react/components/Button';
import Typography from 'apollo-react/components/Typography';
import InProgress from 'Images/status-inprogress.svg';
import Queued from 'Images/status-queued.svg';
import StatusCheck from 'apollo-react-icons/StatusCheck';
import * as colors from 'apollo-react/colors';

import Accordion from 'apollo-react/components/Accordion';
import AccordionDetails from 'apollo-react/components/AccordionDetails';
import AccordionSummary from 'apollo-react/components/AccordionSummary';
import Expression from './Expression';

import { uuidv4 } from 'Utils';
import { useDispatch } from 'react-redux';
import { showBanner } from 'Redux/Slice/BannerSlice';
import validate from 'Pages/Validator/validate';
import { MasteringRulesContext } from '../../MasteringRulesProvider';
import useDisplayName from 'Utils/useDisplayName';

const useStyles = makeStyles({
  libraryType: {
    width: '100%',
    marginBottom: '1rem !important',
    boxShadow: '0px 4px 16px rgba(0,0,0,0.04)',
    border: '1px solid #E9E9E9',
    borderRadius: '4px'
  },
  libraryTypeHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '48px'
  },
  libraryTypeTitle: {
    fontSize: '14px',
    fontWeight: '500'
  },
  expressionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderTop: '1px solid #F6F7FB',
    padding: '12px 32px',
    '& *': {
      //temp fix to fit the library title and default form in the accordian header
      fontSize: '14px !important'
    }
  },
  optional: {
    '&::after': {
      content: "'Optional'",
      border: '1px #E6E7EB solid',
      color: '#222',
      backgroundColor: '#F6F7FB',
      borderRadius: '4px',
      fontSize: '1rem',
      fontWeight: 'normal',
      padding: '0 0.3rem',
      margin: '0 0.4rem'
    }
  },
  defaultFormTitle: {
    whiteSpace: 'pre-line',
    fontSize: '14px',
    fontWeight: '500',
    color: '#898989'
  },
  defaultForm: {
    fontSize: '14px',
    margin: '0 0.5rem',
    fontWeight: '500'
  }
});

const VisitLibrary = (props) => {
  const dispatch = useDispatch();
  const getDisplayName = useDisplayName();

  const {
    library,
    sourceFormItems,
    setExpressions,
    expressions = [],
    rowSeq,
    methodsConstants,
    saveExpressions,
    deleteMasteringRuleExpression,
    isMandatory,
    isCDRMandatory,
    libraryNames,
    codeListDataMapping,
    defaultForm,
    formatedFormItemData,
    masteringData,
    refDataService,
    rowName,
    sourceName
  } = props;
  const classes = useStyles();
  const [status, setStatus] = useState(0);
  const [localExpressions, setLocalExpressions] = useState(expressions || []);
  const { setConfirmAlert, setChangesNotSaved, parentNodeName, selectNodeText } =
    useContext(MasteringRulesContext);

  useEffect(() => {
    let completed = 0;
    localExpressions.forEach((exp) => {
      if (exp.isValid && !exp.inProgress) {
        completed = 1;
      }
    });
    setStatus(localExpressions.length !== 0 ? (completed === 1 ? 1 : 0.5) : 0);
  }, [localExpressions]);

  useEffect(() => {
    let newArray = [];
    expressions.map((el) => {
      if (el.rowName === rowName) {
        newArray.push({
          ...el,
          id: el.id,
          expression: el.expression,
          isValid: true,
          inProgress: false
          // ,rowSeq: el.rowSeq
        });
      }
    });
    console.log('newArray', expressions, newArray);
    setLocalExpressions(newArray);
  }, [JSON.stringify(expressions)]);

  const checkNoMapAvailable = (data, result) => {
    const NO_MAP_EXPRESSION = data.expression === '"$NOMAP"';

    const IS_NO_MAP_SELECTED = masteringData[parentNodeName]
      .find((el) => el.configName === selectNodeText)
      ?.variableMappingType?.split(',');

    if (NO_MAP_EXPRESSION && !IS_NO_MAP_SELECTED.includes('All')) {
      const NO_MAP_RESULT = {
        isValid: false,
        message:
          'DO NOT MAP option is not allowed for system variables. A valid source item or $SYSMAP should be used.'
      };
      data.isValid = NO_MAP_RESULT.isValid;
      data.result = NO_MAP_RESULT;
    } else {
      data.isValid = result.isValid;
      data.result = result;
    }
  };

  const handleExpressionUpdate = (type, exp, saveToRemote = false) => {
    switch (type) {
      case 'save-exp': {
        const modifiedExps = [...localExpressions];
        modifiedExps.forEach((expression) => {
          if (expression.id === exp.id) {
            expression.expression = exp.expression;
            expression.result = {};
            expression.defaultForm = exp.sourceForm || expression.defaultForm;
          }
        });
        setLocalExpressions(modifiedExps);
        break;
      }
      case 'edit': {
        const modifiedExps = [...localExpressions];
        modifiedExps.forEach((expression) => {
          if (expression.id === exp.id) {
            expression.isValid = false;
            expression.inProgress = true;
          }
        });
        setLocalExpressions(modifiedExps);
        break;
      }
      case 'delete': {
        const modifiedExps = [...localExpressions];
        const expIndex = modifiedExps.findIndex((expression) => expression.id === exp.id);
        modifiedExps.splice(expIndex, 1);
        if (saveToRemote) {
          deleteMasteringRuleExpression([exp.id], (variant, message) => {
            setLocalExpressions(modifiedExps);
            dispatch(showBanner({ variant, message }));
          });
        } else setLocalExpressions(modifiedExps);
        break;
      }
      case 'cancel': {
        setConfirmAlert({
          enabled: true,
          type: 'cancel',
          onConfirm: () => {
            setLocalExpressions(JSON.parse(JSON.stringify(expressions)));
            setChangesNotSaved(false);
            setConfirmAlert({ enabled: false, type: '' });
          },
          onCancel: () => {
            setConfirmAlert({ enabled: false, type: '' });
          }
        });
        break;
      }
      case 'save-all': {
        const modifiedExps = [...localExpressions];
        let isError = false;
        modifiedExps.forEach((expression) => {
          const result = validate(
            expression.expression,
            defaultForm,
            codeListDataMapping,
            sourceFormItems,
            refDataService
          );
          checkNoMapAvailable(expression, result);
          if (!expression.isValid) {
            isError = true;
            expression.inProgress = true;
          } else {
            expression.inProgress = false;
          }
        });
        setLocalExpressions(modifiedExps);
        if (!isError) {
          setExpressions(modifiedExps, saveExpressions);
        }
        break;
      }
    }
  };

  const handleAddExpression = () => {
    setLocalExpressions([
      ...localExpressions,
      { id: uuidv4(), expression: '', isValid: false, inProgress: true, rowSeq, sourceName }
    ]);
  };

  const renderStatus = useCallback(() => {
    if (status === 1)
      return (
        <>
          <StatusCheck
            style={{
              color: colors['green'],
              height: '18px',
              marginTop: '3px'
            }}></StatusCheck>

          <span>Completed</span>
        </>
      );
    else
      return (
        <>
          <span>
            <img
              src={`${status === 0 ? Queued : InProgress}`}
              style={{ height: '15px', marginRight: '5px', marginTop: '4px' }}
            />
          </span>
          <span>{status === 0 ? 'Queued' : 'In Progress'}</span>
        </>
      );
  }, [status]);

  let [isOptional, optionalClass] = useMemo(() => {
    if (!isMandatory)
      if (!isCDRMandatory || (isCDRMandatory && !library.startsWith('CDR Tabular')))
        return [true, classes.optional];

    return [false, ''];
  }, [isMandatory, isCDRMandatory]);

  const handleCancel = () => {
    handleExpressionUpdate('cancel');
  };

  const handleSave = () => {
    handleExpressionUpdate('save-all');
  };

  const saveCancelEnabled = localExpressions.some((_exp) => _exp.inProgress);

  const libraryName = useMemo(() => {
    if (library.startsWith('CDISC ODM')) {
      return libraryNames['CDISC ODM'];
    } else return libraryNames[library];
  }, [libraryNames, library]);

  return (
    <Accordion defaultExpanded className={classes.libraryType}>
      <AccordionSummary>
        <Box className={classes.libraryTypeHeader}>
          <Typography className={`${classes.libraryTypeTitle}`}>
            <span className={`${classes.defaultFormTitle} ${optionalClass}`}>{'Library'}</span>
            <div>
              {rowName} ({libraryName})
            </div>
          </Typography>
          <Typography className={classes.defaultForm}>
            <span className={classes.defaultFormTitle}>
              {parentNodeName === 'Visit Setting' ? 'Source Form' : 'Default form'}
            </span>
            <div>{defaultForm}</div>
          </Typography>
          <Box style={{ display: 'flex', justifyContent: 'center' }}>{renderStatus()}</Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails className={classes.expressionContainer}>
        {localExpressions.map((exp, index) => {
          return (
            <Expression
              disableDelete={localExpressions?.length === 1 && !isOptional}
              key={'expression-' + exp.id}
              updateExpression={handleExpressionUpdate}
              index={index}
              sourceFormItems={sourceFormItems}
              methodsConstants={methodsConstants}
              library={library}
              {...exp}
              defaultForm={exp.defaultForm || defaultForm}
              formatedFormItemData={formatedFormItemData}
            />
          );
        })}
        <Button
          style={{ margin: localExpressions?.length > 0 ? '1rem' : '4rem' }}
          variant="primary"
          onClick={() => handleAddExpression()}
          data-testid="add-expression">
          + Add Expression
        </Button>
        {localExpressions?.length ? (
          <Box
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              justifyContent: 'flex-end',
              marginTop: '1rem'
            }}>
            <Button
              variant="text"
              size="small"
              onClick={handleCancel}
              data-testid="cancel"
              style={{ marginRight: '1rem' }}
              disabled={!saveCancelEnabled}>
              Cancel
            </Button>
            <Button
              variant="primary"
              size="small"
              onClick={handleSave}
              data-testid="save_progress"
              disabled={!saveCancelEnabled}>
              Save
            </Button>
          </Box>
        ) : null}
      </AccordionDetails>
    </Accordion>
  );
};
export default VisitLibrary;
