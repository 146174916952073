import CircularProgress from '@mui/material/CircularProgress';
import { unwrapResult } from '@reduxjs/toolkit';
import { StatusCheck, Sync } from 'apollo-react-icons';
import FileUpload from 'apollo-react/components/FileUpload';
import Grid from 'apollo-react/components/Grid';
import Tooltip from 'apollo-react/components/Tooltip';
import CustomModal from 'Components/Modal';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { DeleteReferenceData, GetQCodeReferenceData } from 'Redux/Service/ReferenceDataCardsService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { MessageConstants } from 'Utils/MessageConstants';
import useGlobalMessage from 'Utils/useGlobalMessage';
import Card from './Card';
import './Trials.css';
import { setReferenceData } from 'service/reference-data.service';
import { DATASET_NAME_DT } from 'Utils/Constants';

const DRUG_THESAURUS = 'drug-thesaurus';
const DrugThesaurus = (props) => {
  const [drugThesaurusPreview, setDrugThesaurusPreview] = useState(true);
  const [dtQueryData, setDTQueryData] = useState([]);
  const [drugThesaurusErrorMessage, setDrugThesaurusErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { protocol } = useSelector((state) => state.StudyLibraryData);

  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    variant: '',
    title: '',
    message: '',
    buttonPrimaryLabel: '',
    buttonSecondaryLabel: '',
    onConfirm: () => null,
    onCancel: () => null
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: mappingRuleVersionID } = useParams();
  const { getMessage } = useGlobalMessage();

  const importDrugThesaurusData = async () => {
    setIsLoading(true);
    let payload = {
      mappingRuleVersionID: mappingRuleVersionID,
      datasetName: DATASET_NAME_DT,
      protocolName: protocol?.protocolNumber,
      isImport: true,
    };
    try {
      const importedDrugThesaurus = await dispatch(GetQCodeReferenceData(payload)).then(unwrapResult);
      if (importedDrugThesaurus?.data?.success) {
          let importedQueryData = JSON.parse(
            importedDrugThesaurus.data.qcodeQueryResultData?.queryJsonResult || '[]'
          );
        if (importedQueryData?.length > 0) {
          setDTQueryData(importedQueryData);
          setReferenceData(DRUG_THESAURUS, importedDrugThesaurus.data.qcodeQueryResultData);
          navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/${DRUG_THESAURUS}`);
          dispatch(showBanner({ variant: 'success', message: importedDrugThesaurus.data.message }));
        } else {
          dispatch(
            showBanner({
              variant: 'warning',
              message: getMessage(MessageConstants.REFERENCE_DATA_TIMEPOINTS_IMPORT_ERROR_NO_DATA)
            })
          );
        }
        setIsLoading(false);
      } else {
        dispatch(showBanner({ variant: 'error', message: importedDrugThesaurus?.data?.message }));
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleImportData = (isRefresh) => {
    if (isRefresh) {
      setConfirmAlert({
        enabled: true,
        title: getMessage(MessageConstants.REFERENCE_DATA_DT_IMPORT_WARNING_TITLE),
        message: getMessage(MessageConstants.REFERENCE_DATA_DT_IMPORT_WARNING_DESC),
        variant: 'warning',
        buttonPrimaryLabel: getMessage(MessageConstants.WARNING_LABEL_OK),
        buttonSecondaryLabel: getMessage(MessageConstants.WARNING_LABLE_CANCEL),
        onConfirm: () => {
          importDrugThesaurusData();
          setConfirmAlert({
            enabled: false
          });
        },
        onCancel: () => {
          setConfirmAlert({
            enabled: false
          });
        }
      });
    } else {
      importDrugThesaurusData();
    }
  };

  const deleteDTData = async () => {
    let payload = {
      domainCode: DATASET_NAME_DT,
      mappingRuleVersionID: mappingRuleVersionID
    };
    const deleteResp = await dispatch(DeleteReferenceData(payload)).then(unwrapResult);
    if (deleteResp.data.success) {
      dispatch(showBanner({ variant: 'success', message: deleteResp.data.message }));
      setDrugThesaurusPreview(true);
      setDTQueryData([]);
      props.configuration.setCardVisibility(DRUG_THESAURUS, false);
    } else {
      dispatch(showBanner({ variant: 'error', message: deleteResp.data.message }));
    }
  };

  const confirmOnDelete = () => {
    dtQueryData.length > 0
      ? setConfirmAlert({
          enabled: true,
          title: getMessage(MessageConstants.REFERENCE_DATA_DELETE_CARD_WARNING_TITLE),
          message: getMessage(MessageConstants.REFERENCE_DATA_DELETE_CARD_WARNING_DESC),
          variant: 'error',
          buttonPrimaryLabel: getMessage(MessageConstants.WARNING_LABEL_OK),
          buttonSecondaryLabel: getMessage(MessageConstants.WARNING_LABLE_CANCEL),
          onConfirm: () => {
            deleteDTData();

            setConfirmAlert({
              enabled: false
            });
          },
          onCancel: () => {
            setConfirmAlert({
              enabled: false
            });
          }
        })
      : deleteDTData();
  };

  const handleViewData = () => {
    if (dtQueryData.length > 0) {
      navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/drug-thesaurus`);
    }
  };
  useEffect(() => {
    (async () => {
      let payload = {
        mappingRuleVersionID: mappingRuleVersionID,
        isImport: false,
        datasetName: DATASET_NAME_DT,
        protocolName: protocol?.protocolNumber,
      };
      const drugGetAPIData = await dispatch(GetQCodeReferenceData(payload)).then(unwrapResult);
      props.configuration.setIsCompleted(DRUG_THESAURUS, true);
      props.configuration.setModuleValidation(DRUG_THESAURUS, true);
      if (drugGetAPIData?.data?.success) {
        let importedQueryData = JSON.parse(
          drugGetAPIData.data.qcodeQueryResultData?.queryJsonResult || '[]'
        );
        if (importedQueryData.length > 0) {
          setDTQueryData(importedQueryData);
          setDrugThesaurusPreview(false);
          props.configuration.setModuleValidation(DRUG_THESAURUS, true);
          props.configuration.setCardVisibility(DRUG_THESAURUS, true);
        } else {
          props.required && props.configuration.setModuleValidation(DRUG_THESAURUS, false);
          setDTQueryData([]);
        }
      } else {
        setDrugThesaurusErrorMessage(drugGetAPIData.data.message);
        setDTQueryData([]);
      }
    })();
  }, []);

  return (
    <Grid item xs={4} style={props.visible ? { display: 'block' } : { display: 'none' }}>
      <h4 style={{ marginTop: '16px', padding: '0px' }}>
        {props.title}
        <span style={{ color: 'red' }}>{props.required ? '*' : ''}</span>
      </h4>
      <Card
        deleteCardInParent={confirmOnDelete}
        required={props.required}
        cardTitle={props.title}
        refMsg={drugThesaurusErrorMessage}
        setErrorFlag={false}
        isEditDisable={drugThesaurusPreview}
        previewDataLabel={getMessage(MessageConstants.REFERENCE_DATA_VIEW_DATA_TABLE_LABEL)}
        handleEditPreview={() => handleViewData()}
        isDownloadRequired={false}
        handleDownload={() => {}}
        isImportRequired={true}
        isImportRunning={isLoading}
        handleImportData={handleImportData}>
        <div style={{ margin: '0px 10px' }}>
          {isLoading && (
            <CircularProgress
              size={40}
              style={{
                zIndex: '3000',
                position: 'absolute',
                marginLeft: 'calc(50% - 45px)',
                marginTop: '15%'
              }}
            />
          )}
          <div className="fileUpload">
            <FileUpload
              disabled={true}
              value={[]}
              onUpload={() => {}}
              label={getMessage(MessageConstants.REFERENCE_DATA_FILE_UPLOAD_LABEL)}
              maxItems={5}
              dropAreaHeight={100}
              fullWidth
            />
            {dtQueryData.length > 0 && (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between'
                }}>
                {
                  <p
                    style={{
                      height: '24px',
                      color: 'black',
                      fontFamily: 'Proxima Nova',
                      fontSize: '14px',
                      fontWeight: 400,
                      letterSpacing: 0,
                      lineHeight: '24px'
                    }}>
                    {getMessage(MessageConstants.REFERENCE_DATA_DT_IMPORT_LABEL)}
                  </p>
                }
                <div
                  style={{
                    marginTop: '0.7rem',
                    marginRight: '0'
                  }}>
                  {
                    <Tooltip
                      variant="dark"
                      title={getMessage(MessageConstants.REFERENCE_DATA_DT_IMPORT_SYNC_TOOLTIP)}
                      placement="top">
                      <Sync
                        style={{
                          color: '#015FF1',
                          cursor: isLoading ? 'default' : 'pointer',
                          opacity: isLoading ? 0.3 : 1
                        }}
                        onClick={!isLoading ? () => handleImportData(true) : null}
                        data-testid="refresh-btn"
                      />
                    </Tooltip>
                  }
                  {
                    <StatusCheck
                      style={{
                        color: '#00C221',
                        width: '20px',
                        height: '20px',
                        marginLeft: '1rem'
                      }}
                      fontSize="small"
                    />
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
      <CustomModal
        display={confirmAlert.enabled}
        title={confirmAlert.title}
        message={confirmAlert.message}
        body={confirmAlert.body}
        variant={confirmAlert.variant}
        buttonPrimaryLabel={confirmAlert.buttonPrimaryLabel}
        buttonSecondardyLabel={confirmAlert.buttonSecondaryLabel}
        handlePrimaryAction={() => confirmAlert?.onConfirm()}
        handleClose={() => confirmAlert?.onCancel()}
      />
    </Grid>
  );
};

export default DrugThesaurus;
