import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Filter from 'apollo-react-icons/Filter';
import Info from 'apollo-react-icons/Info';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import IconButton from 'apollo-react/components/IconButton';
import Switch from 'apollo-react/components/Switch';
import Table from 'apollo-react/components/Table';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import React, { useState, useMemo } from 'react';
import columns, { columnsWithSorting } from './columns';

const useStyles = makeStyles({
  tableWrapper: {
    maxHeight: '750px',
    minHeight: '450px',
    overflow: 'auto',
    '&>table>tfoot': {
      bottom: 0,
      zIndex: 1,
      position: 'sticky',
      backgroundColor: '#f6f7fbFF'
    }
  }
});

const CustomButtonHeader = (props) => {
  const { isDashboard, isSwitchOn, setIsSwitchOn, onViewAll } = props;

  return (
    <Grid container style={{ padding: 0 }}>
      <Grid item xs={6}>
        {isDashboard ? (
          <Typography variant="title1" style={{ fontSize: '16px' }}>
            Data Product Pipeline Summary
            <Tooltip
              variant="dark"
              title="Displays Data Products for Published Mapping spec with the Job details and Connected Data Sources"
              placement="top"
              style={{ marginRight: 48, paddingBottom: '0.5rem' }}>
              <IconButton color="neutral7">
                <Info style={{ width: '16px', maxHeight: '16px' }} />
              </IconButton>
            </Tooltip>
          </Typography>
        ) : (
          <Typography
            variant="title1"
            gutterBottom
            style={{ marginTop: '0.5rem', fontSize: '16px' }}>
            All Data Product Pipelines
          </Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.2rem' }}>
          {!isDashboard ? (
            <div style={{ display: 'inline-flex' }}>
              <Box>
                <Typography
                  style={{
                    marginRight: '10px',
                    paddingTop: '7px',
                    fontFamily: '"Proxima Nova"',
                    fontSize: '14px',
                    color: '#444444',
                    position: 'relative',
                    top: '4px'
                  }}
                  variant="subtitle"
                  gutterBottom>
                  {'Show only items with Latency Warnings'}
                </Typography>
                <Switch
                  size="small"
                  onChange={() => setIsSwitchOn(!isSwitchOn)}
                  checked={isSwitchOn}
                />
              </Box>
              <Button
                variant="secondary"
                icon={Filter}
                onClick={props.toggleFilters}
                style={{ padding: '0 8px', maxHeight: '32px' }}>
                Filter
              </Button>
            </div>
          ) : (
            <Button
              variant="secondary"
              onClick={onViewAll}
              style={{ padding: '8px 10px', maxHeight: '32px' }}>
              View All
            </Button>
          )}
        </div>
      </Grid>
    </Grid>
  );
};

const DataProductSummaryTable = (props) => {
  const [isSwitchOn, setIsSwitchOn] = useState(false);
  const {
    history,
    isDashboard,
    rows,
    columnsData,
    setColumnsData,
    onViewAll,
    paginationFunctions,
    paginationData
  } = props;
  const { setRowsCount, setSortOrder, setSortedColumn, setPageNumber, setPaginationFilter } =
    paginationFunctions;
  const {
    sortOrder,
    pageNumber,
    sortedColumn,
    rowsCount,
    // paginationFilter,
    totalCount,
    hidePagination
  } = paginationData;
  const classes = useStyles();

  const filterDataByLatency = (rows) => {
    return rows.filter((item) => item.isSLABreached);
  };

  const updateFilter = (data) => {
    const updatedData = Object.keys(data || {}).map((key) => {
      return {
        columnName: key,
        text: data[key]
      };
    });
    setPaginationFilter(updatedData);
  };

  const displayColumns = useMemo(() => {
    return isDashboard ? columnsData.slice(1) : columnsWithSorting.slice(1);
  }, [isDashboard]); 

  return (
    <React.Fragment>
      <Card style={{ padding: 0 }}>
        <Table
          classes={classes}
          size={totalCount}
          columns={displayColumns}
          rows={isSwitchOn ? filterDataByLatency(rows) : rows}
          rowId="protocolNumber"
          CustomHeader={(prop) => (
            <CustomButtonHeader
              {...prop}
              isDashboard={isDashboard}
              history={history}
              setIsSwitchOn={setIsSwitchOn}
              isSwitchOn={isSwitchOn}
              onViewAll={onViewAll}
            />
          )}
          sortedColumn={!isDashboard ? sortedColumn : ''}
          sortOrder={!isDashboard ? sortOrder : ''}
          hidePagination={hidePagination}
          defaultRowsPerPage={10}
          rowsPerPageOptions={isDashboard ? ['All'] : [10, 20, 50, 100, 'All']}
          hasScroll
          maxHeight={650}
          tablePaginationProps={{
            labelDisplayedRows: DisplayedRowsLabel,
            truncate: true
          }}
          columnSettings={{
            enabled: true,
            onChange: (columns) => {
              setColumnsData(columns);
            },
            fixedWidth: false,
            frozenColumnsEnabled: true,
            defaultColumns: columns
          }}
          rowsPerPage={rowsCount}
          page={pageNumber}
          onChange={(value) => {
            setRowsCount(value.rowsPerPage);
            setSortedColumn(value.sortedColumn);
            setSortOrder(value.sortOrder);
            updateFilter(value.filters);
            setPageNumber(value.page);
          }}
        />
      </Card>
    </React.Fragment>
  );
};

export default DataProductSummaryTable;
