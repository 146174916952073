import { Arrow2Down, Arrow2Up, ArrowRight, Close, Folder, FolderOpen } from 'apollo-react-icons';
import Box from 'apollo-react/components/Box';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import Grid from 'apollo-react/components/Grid';
import TextField from 'apollo-react/components/TextField';
import Tooltip from 'apollo-react/components/Tooltip';
import { Notification } from 'Components/Common/Notification';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import React from 'react';
import Typography from 'apollo-react/components/Typography';
import Divider from 'apollo-react/components/Divider';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Check from 'apollo-react-icons/Check';
import { dateFormatByType } from 'Utils';

function FolderPath({
  classes,
  handleRowSelect,
  isCreatingFolder,
  handleCreateFolder,
  selectedPath,
  isRowSelected,
  currentFolder,
  handleBack,
  toggleSortOrder,
  sortConfig,
  newFolderName,
  handleNewFolderNameChange,
  handleKeyPress,
  isValid,
  duplicateFolderNameMessageError,
  specialCharMessageError,
  folderNameLengthExceedMessageError,
  emptyFolderNameError,
  handleNewFolderClose,
  isLoadingLazy,
  handleRowClick,
  selectedFolderLocation
}) {
  return (
    <>
      <Box>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: '10px 0px'
          }}>
          <Grid style={{ display: 'flex', alignItems: 'center' }} xs={6}>
            <FolderOpen />
            <span style={{ paddingLeft: '10px' }}>{selectedPath?.join('/')}</span>
          </Grid>
          <Grid>
            <Button variant="secondary" disabled={isCreatingFolder} onClick={handleCreateFolder}>
              Create Folder
            </Button>
          </Grid>
        </Grid>
        {selectedPath.length > 1 &&
        !(selectedPath.length === 2 && isRowSelected && currentFolder.length > 0) ? (
          <Grid
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '10px'
            }}>
            <Button icon={<ChevronLeft />} variant="secondary" onClick={() => handleBack()}>
              Back
            </Button>
          </Grid>
        ) : null}
        <Notification
          variant={'info'}
          message={
            'Folder will be created in the destination path once the data product is published.'
          }
        />
      </Box>
      <Card
        style={{
          height:
            selectedPath.length > 1 &&
            !(selectedPath.length === 2 && isRowSelected && currentFolder.length > 0)
              ? 'calc(30vh-50px)'
              : '30vh',
          position: 'relative',
          overflow: 'auto',
          paddingBottom: '0px',
          boxShadow: 'none'
        }}>
        <Grid container className={classes.columnsHeader}>
          <Grid item xs={12}>
            <Box style={{ width: '100%', height: '44px' }} display={'flex'}>
              <Grid container style={{ alignItems: 'center' }}>
                <Grid xs={1}></Grid>
                <Grid xs={4} className={classes.columnName} onClick={() => toggleSortOrder('name')}>
                  Name
                  {sortConfig.key === 'name' && (
                    <div>
                      {sortConfig.direction === 'ascending' ? (
                        <Arrow2Down fontSize={'small'} />
                      ) : (
                        <Arrow2Up fontSize={'small'} />
                      )}
                    </div>
                  )}
                </Grid>
                <Grid
                  xs={3}
                  className={classes.columnName}
                  onClick={() => toggleSortOrder('LastUpdated')}>
                  Last Updated
                  {sortConfig.key === 'LastUpdated' && (
                    <div>
                      {sortConfig.direction === 'ascending' ? (
                        <Arrow2Down fontSize={'small'} />
                      ) : (
                        <Arrow2Up fontSize={'small'} />
                      )}
                    </div>
                  )}
                </Grid>
                <Grid xs={4}></Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            position: 'sticky',
            top: '44px',
            zIndex: 1,
            backgroundColor: '#fff'
          }}>
          <Grid xs={1} style={{ display: 'flex', justifyContent: 'center' }}></Grid>
          <Grid xs={4} sx={{ display: 'flex', alignItems: 'center', pr: '10px' }}>
            {isCreatingFolder && (
              <TextField
                fullWidth
                value={newFolderName}
                onChange={handleNewFolderNameChange}
                onKeyPress={(e) => e.key === 'Enter' && handleKeyPress()}
                style={{ marginTop: '3px' }}
                helperText={
                  isValid.isFolderNameExist
                    ? duplicateFolderNameMessageError
                    : isValid.isSpecialCharExist
                    ? specialCharMessageError
                    : isValid.isFolderNameLengthExceeds
                    ? folderNameLengthExceedMessageError
                    : isValid.isEmptyFolderName && emptyFolderNameError
                }
                error={Object.values(isValid).some((el) => el === true)}
              />
            )}
          </Grid>
          <Grid xs={3} style={{ display: 'flex', alignItems: 'center' }}>
            {isCreatingFolder && (
              <Box style={{ display: 'flex', alignItems: 'center' }}>
                <Tooltip title="Create">
                  <Button
                    variant="secondary"
                    icon={Check}
                    sx={{
                      mr: '10px',
                      '& svg': {
                        mx: 0
                      },
                      minWidth: '40px',
                      p: 0,
                      color: 'green'
                    }}
                    status="success"
                    onClick={handleKeyPress}></Button>
                </Tooltip>
                <Tooltip title="Cancel">
                  <Button
                    variant="secondary"
                    icon={Close}
                    sx={{
                      '& svg': {
                        mx: 0
                      },
                      minWidth: '40px',
                      p: 0,
                      color: 'red'
                    }}
                    status="danger"
                    onClick={handleNewFolderClose}></Button>
                </Tooltip>
              </Box>
            )}
          </Grid>
        </Grid>
        {isLoadingLazy && !currentFolder?.length ? (
          <Stack spacing={1} width={'100%'} p={1}>
            <Skeleton variant="rounded" width={'100%'} height={35} />
            <Skeleton variant="rounded" width={'100%'} height={35} />
            <Skeleton variant="rounded" width={'100%'} height={35} />
          </Stack>
        ) : (
          <></>
        )}
        {currentFolder?.map((folder, index) => {
          return (
            <Grid
              key={index}
              container
              onClick={() => handleRowSelect(folder)}
              onDoubleClick={(e) => handleRowClick(e, folder)}
              className={classes.row}>
              <Grid item xs={12}>
                <Box
                  style={{
                    width: '100%',
                    height: '40px',
                    backgroundColor: selectedFolderLocation.name === folder.name && '#ECF3FF'
                  }}
                  display={'flex'}>
                  <Grid
                    container
                    style={{
                      alignItems: 'center',
                      maxHeight: '300px',
                      position: 'relative',
                      overflow: 'auto'
                    }}>
                    <Grid xs={1} style={{ display: 'flex', justifyContent: 'center' }}>
                      <Folder style={{ color: '#B3D1FE' }} />
                    </Grid>
                    <Grid xs={4}>
                      <Typography noWrap style={{ fontSize: '14px', maxWidth: '95%' }}>
                        {folder.name}
                      </Typography>
                    </Grid>
                    <Grid xs={3} style={{ fontSize: '14px' }}>
                      {dateFormatByType(folder.lastModifiedUTC, 'Table')}
                    </Grid>
                    <Grid xs={3}></Grid>
                    <Grid
                      xs={1}
                      onClick={(event) => handleRowClick(event, folder)}
                      style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        paddingRight: '1rem'
                      }}>
                      <ArrowRight />
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
              </Grid>
            </Grid>
          );
        })}
      </Card>

      {/* <Grid
        container
        item
        xs={12}
        style={{ display: 'flex', alignItems: 'center', paddingTop: '10px' }}>
        <span>Create Batch ID specific sub folder for every export</span>
        <Tooltip
          title="Automatically create subfolder based on unique “Batch ID”."
          placement="right">
          <InfoIcon />
        </Tooltip>
      </Grid>
      <Grid container item xs={12} style={{ paddingBottom: '20px' }}>
        <Switch size="small" checked={isBatchIdRequired} onChange={handleToggleChange} />
      </Grid> */}
    </>
  );
}

export default FolderPath;
