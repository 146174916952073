import { unwrapResult } from '@reduxjs/toolkit';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Loader from 'Components/Loader/Loader';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SavePublishMappingSpecData } from 'Redux/Service/PublishMappingSpecService';
import { saveLoaderQueue } from 'Redux/Service/PublishDataProductService';
import {
  getEcrfTrialName,
  handleSelectedSources,
  handleTrialItems,
  handleTrialItemsForDisplay
} from '../ReviewAndPublish/utilities';
import PublishFailure from './components/PublishFailure';
import PublishSuccess from './components/PublishSuccess';
import Cookies from 'js-cookie';
import { showBanner } from 'Redux/Slice/BannerSlice';
import useDisplayName from 'Utils/useDisplayName';

const PublishStatus = (props) => {
  console.log(props, 'publish');
  const {
    selectSourceDetails,
    dataExportDetails,
    mappingSpecDetails,
    selectedEnvironment,
    selectedScehduler,
    selectedSourceData,
    exportTypeMetaData,
    scheduleFrequencies,
  } = useSelector((state) => state.PublishMappingSpecData);
  const [isSuccess, setSuccessMsg] = useState();
  const [errorMessage, setErrorMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const { schedulerTypeID } = selectedScehduler;
  const dispatch = useDispatch();
  const getDisplayName = useDisplayName();
  const dataSourceIDObj = {};

  handleSelectedSources(selectSourceDetails)?.map((item) => {
    dataSourceIDObj[item.sourceName] = item.dataSourceID;
  });
  const sourceConn = handleSelectedSources(selectSourceDetails)?.map((item) =>
    getDisplayName(item.sourceName)
  );
  const trialLists = handleTrialItems(selectedSourceData);
  const selectedDataSets = selectSourceDetails?.masterDataSources
    ?.filter((item) => item.parentSourceName === 'CDR' && item.selected && item.connection)
    ?.map((el) => el.sourceName);

  const export_Loc_ID = dataExportDetails
    ?.filter((item) => item.isActive === true && item.selected)
    ?.map((el) => el.exportLocationID);

  const exportLocations = dataExportDetails
    ?.filter((item) => item.isActive === true && item.selected)
    ?.map((row) => {
      const domainInformation = [];

      Object.values(row.exportPreferences?.domainAndColumns || {})?.forEach((domain) => {
        if (domain.checked) {
          const variableInformation = [];
          domain.exportVariables
            ?.filter((column) => column.checked)
            .forEach((column, index) => {
              variableInformation.push({
                columnOrder: index + 1,
                isSystemColumn: column.isSystemColumn,
                originalColumnName: column.originalColumnName,
                updatedColumnName: column.updatedColumnName,
                isSuppQual: column.isSuppQual
              });
            });
          domainInformation.push({
            domainCode: domain.domainCode,
            domainType: domain.domainType,
            dataFileName: domain.dataFileName,
            variableInformation
          });
        }
      });

      const exportTypeID =
        exportTypeMetaData?.find(
          (exportType) => exportType.exportTypeName === row.exportPreferences?.exportType
        )?.exportTypeId || '';
      const scheduleTypeID =
        scheduleFrequencies?.find(
          (exportType) => exportType.scheduleFrequency === row.exportPreferences?.scheduleType
        )?.scheduleTypeID || '';

      const suppQualPreferenceKey = row?.exportPreferences
        ? row?.exportPreferences.suppQualPreferenceKey
        : '';
      return {
        exportLocationId: row.exportLocationID,
        exportLocationBaseFolder:
          (row.exportLocationBaseFolder?.length && row.exportLocationBaseFolder?.join('/')) || '',
        exportLocationNewFolder:
          (row.exportLocationNewFolder?.length && row.exportLocationNewFolder?.join('/')) || '',
        exportTypeID,
        scheduleTypeID,
        suppQualPreferenceKey,
        isBatchID: row.isBacthId || false,
        isSystemDefined: !row?.exportPreferences,
        domainInformation,
        isMetadataFile: row.isMetadataFile,
        packageFormat: row.packageFormat,
        fileFormat: row.fileFormat ,
        defaultProductFolder:  row.defaultProductFolder,
        subFolder: row.subFolder, 
      };
    });

  const exportLocation = dataExportDetails
    ?.filter((item) => item.isActive === true && item.selected)
    ?.map((row) => row.destination);

  const now = new Date().toISOString();

  const payload = {
    mappingRuleVersionID: mappingSpecDetails.mappingRuleVersionID,
    productID: mappingSpecDetails.productID,
    mappedEnvironmentID: selectedEnvironment.environmentID,
    comment: selectedEnvironment?.comments !== undefined ? selectedEnvironment.comments : '',
    dataSourceID: dataSourceIDObj,
    export_Loc_ID: export_Loc_ID,
    exportLocations: exportLocations,
    createdDate: now,
    createdBy: Cookies.get('user.id'),
    protocolID: mappingSpecDetails.protocolName,
    selectedDataSets: selectedDataSets,
    trialNames: trialLists,
    schedulerTypeID: schedulerTypeID,
    isDummyDataSource: selectSourceDetails?.isDummyDataSource,
    ecrfTrialName: getEcrfTrialName(selectedSourceData)
  };

  const refreshPayload = {
    loaderQueue: {
      iqCreateDate: now,
      iqUpdateDate: now,
      iqCreatedBy: Cookies.get('user.id'),
      iqUpdatedBy: Cookies.get('user.id'),
      iqAuditType: 'UPDATE',
      iqAuditDate: now,
      iqActiveFlag: true,
      protocolName: mappingSpecDetails.protocolName,
      productName: mappingSpecDetails.productMnemonic,
      environment: selectedEnvironment.environmentName?.toUpperCase(),
      mappingRuleVersionID: mappingSpecDetails.mappingRuleVersionID,
      isReload: true,
      loaderStatus: 'queued',
      userName: Cookies.get('user.id')
    }
  };

  const publishInfo = {
    sourceConn: sourceConn,
    exportLocation: exportLocation,
    productMnemonic: mappingSpecDetails.productMnemonic,
    mappingSpecVersionName: mappingSpecDetails.mappingSpecVersionName,
    targetDataModelName: mappingSpecDetails.targetDataModelName,
    environment: selectedEnvironment.environmentName,
    trialLists: handleTrialItemsForDisplay(selectedSourceData)
  };

  useEffect(() => {
    (async () => {
      const response = await dispatch(SavePublishMappingSpecData(payload)).then(unwrapResult);
      if (response.data.success) {
        const loaderQueueResponse = await dispatch(saveLoaderQueue(refreshPayload)).then(
          unwrapResult
        );
        if (!loaderQueueResponse?.data?.success) {
          dispatch(showBanner({ variant: 'error', message: loaderQueueResponse?.data?.message }));
        }
        setSuccessMsg(true);
      } else {
        setErrorMessage(response.data.message);
        setSuccessMsg(false);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <React.Fragment>
      {loading ? (
        <DataVizCard data-testid="viz-card">
          <Loader />
        </DataVizCard>
      ) : (
        <div>
          {isSuccess && <PublishSuccess publishInfo={publishInfo} pathName={props.route.path} />}
          {errorMessage !== '' && (
            <PublishFailure
              errorMessage={errorMessage}
              publishInfo={publishInfo}
              pathName={props.route.path}
            />
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default PublishStatus;
