/*eslint-disable*/
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Typography from 'apollo-react/components/Typography';
import Button from 'apollo-react/components/Button';
import { makeStyles } from '@mui/styles';
import Grid from 'apollo-react/components/Grid';
import DataVizCard from 'apollo-react/components/DataVizCard';
import { useSelector, useDispatch } from 'react-redux';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { unwrapResult } from '@reduxjs/toolkit';
import {
  getDataProductWithMappingSpec,
  saveLoaderQueue,
} from 'Redux/Service/PublishDataProductService';
import { getCheckboxFlag, getProtocol } from 'Redux/Slice/StudyLibrarySlice';
import Loader from '../../Components/Loader/index';
import Cog from 'apollo-react-icons/Cog';
import { Link, useNavigate } from 'react-router-dom';
import {
  compareNumbers,
  compareStrings,
  createStringSearchFilter,
  numberSearchFilter
} from 'apollo-react/components/Table';
import { DateFilter } from 'Components/Common/DateFilter';
import TextField from 'apollo-react/components/TextField';
import Table from 'apollo-react/components/Table/Table';
import IconMenuButton from 'apollo-react/components/IconMenuButton';
import EllipsisVerticalIcon from 'apollo-react-icons/EllipsisVertical';
import Tooltip from 'apollo-react/components/Tooltip';
import { breadCrumbData } from 'Redux/Slice/BreadcrumbSlice';
import Refresh from 'apollo-react-icons/Refresh';
import CustomModal from '../../Components/Modal/Modal';
import { getStudyLibraryGrid, getProtocolAuth } from 'Redux/Service/StudyLibraryGridService';
import { GetProductDataSources } from 'Redux/Service/RuleEditorService';
import { DownloadMappingSpecData } from 'Redux/Service/PublishMappingSpecService';
import { downloadFile } from '../ProductDesigner/Components/PublishMappingSpec/publishUtils';
import { openDataPipelinePanel } from 'Redux/Slice/PublishMappingSpecSlice';
import ChevronDown from 'apollo-react-icons/ChevronDown';
import ChevronRight from 'apollo-react-icons/ChevronRight';
import IconButton from 'apollo-react/components/IconButton';
import Filter from 'apollo-react-icons/Filter';
import Rocket from 'apollo-react-icons/Rocket';
import { neutral7, neutral8 } from 'apollo-react/colors';
import MappingSpecDetails from 'Pages/ProductDesigner/Components/PublishMappingSpec/MappingSpecDetails';
import { setMappingSpecDetails } from 'Redux/Slice/PublishMappingSpecSlice';
import { useMappingSpecActivity } from 'Pages/ProductDesigner/Components/PublishMappingSpec/Components/UseMappingSpecActivity';
import { dateFormatByType, compareDates, dateFilter } from 'Utils';
import RefreshMappingSpec from './Components/RefreshMappingSpec';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import BookLibrary from 'Images/book-library.svg';
import { hasNoAccess, hasDashboardPipelineAccess } from 'service/AppPermissions';
import { setProtocolPermissions } from 'service/AppPermissions';
import Cookies from 'js-cookie';
import { closeLoader, showLoader } from 'Redux/Slice/LoaderSlice';
import TableWrapper from 'Components/Common/TableWrapper';
import { TextFieldFilter, IntegerFilter } from 'Pages/Dashboard/Components/Filters';
import useDisplayName from 'Utils/useDisplayName';
import Select from 'apollo-react/components/Select';
import useGlobalMessage from 'Utils/useGlobalMessage';
import MenuItem from 'apollo-react/components/MenuItem';
import { KeyConstants } from 'Utils/KeyConstants';
import useGlobalKeys from 'Utils/useGlobalKeys';

const styles = {
  page: {
    padding: 24
  },
  tableHorizontalOverflow: {
    '& td': {
      minWidth: 100
    }
  },
  modalPreview: {
    '&': {
      width: '1257px',
      maxWidth: 'none'
    }
  },
  modalAudit: {
    '&': {
      width: '1257px',
      maxWidth: 'none'
    }
  },
  select: {
    margin: '0px 10px 0px 0px',
    '& div': {
      marginTop: '0px'
    }
  }
};

const ActionCellForMappingSpec = ({ handleView, handleDownload, row }) => {
  const menuItems = [
    {
      text: 'View',
      onClick: handleView,
      disabled: hasNoAccess(row.protocolName, 'viewActivityLog')
    },
    {
      text: 'Download',
      onClick: handleDownload,
      disabled: hasNoAccess(row.protocolName, 'mappingSpecDownload')
    }
  ];

  return (
    <Tooltip title="Actions" disableFocusListener>
      <IconMenuButton id="actions" menuItems={menuItems} size="small">
        <EllipsisVerticalIcon />
      </IconMenuButton>
    </Tooltip>
  );
};

const ActionCellForMappingSpecMemo = React.memo(ActionCellForMappingSpec);

const mappingSpecVersionStyles = {
  headerCell: {
    color: neutral8,
    alignItems: 'center',
    display: 'flex',
    '& > p': { fontSize: '14px', fontWeight: 600 }
  },
  cell: {
    color: neutral7,
    alignItems: 'center',
    display: 'flex',
    '& > p': { fontSize: '14px', fontWeight: 400 }
  }
};

const handleDateSort = (mappingSpecVersions) => {
  let sortedMappingSpecVersions = mappingSpecVersions;
  sortedMappingSpecVersions?.sort((a, b) => {
    const dateA = new Date(a.createdDate);
    const dateB = new Date(b.createdDate);
    return dateB - dateA;
  });
  return sortedMappingSpecVersions;
};

const MappingSpecVersions = (props) => {
  const classes = makeStyles(mappingSpecVersionStyles)();
  const { mappingSpecVersions, viewMappingSpec, downloadMappingSpec, ...row } = props;
  const navigate = useNavigate();

  const navigateToPublishPage = (parentRow, rowObj) => {
    props.createMappingSpecData(parentRow, rowObj);
    navigate('/manage-data-pipeline/manage-data-pipeline-version');
  };

  return (
    <Grid container style={{ backgroundColor: '#f8f9fb' }}>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'row',
          margin: '10px 2%'
        }}>
        <Grid item xs={3} className={classes.headerCell}>
          <Typography>{'Mapping Spec Version'}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.headerCell}>
          <Typography>{'Created By'}</Typography>
        </Grid>
        <Grid item xs={2} className={classes.headerCell}>
          <Typography>{'Title'}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.headerCell}>
          <Typography>{'Date Created'}</Typography>
        </Grid>
        <Grid item xs={3} className={classes.headerCell}>
          <Typography>{'Date Published'}</Typography>
        </Grid>
        <Grid item xs={3}></Grid>
      </Grid>

      {mappingSpecVersions.map((version) => {
        return (
          <Grid
            key={version.mappingSpecVersionName}
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              margin: '5px 1% 0px 2%'
            }}>
            <Grid item xs={3} className={classes.cell}>
              <Typography>{version.mappingSpecVersionName}</Typography>
            </Grid>
            <Grid item xs={2} className={classes.cell}>
              <Typography>{version.createdBy}</Typography>
            </Grid>
            <Grid item xs={2} className={classes.cell}>
              <Typography>{version.title}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.cell}>
              <Typography>{dateFormatByType(version.createdDate, 'Table')}</Typography>
            </Grid>
            <Grid item xs={3} className={classes.cell}>
              {version.publishedDate?.length > 0 ? <Typography>{dateFormatByType(version.publishedDate, 'Table')}</Typography> : <Typography>{'N/A'}</Typography>}
            </Grid>
            <Grid
              item
              xs={3}
              className={classes.cell}
              style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                style={{ color: '#0768FD', marginRight: '10px' }}
                onClick={() => navigateToPublishPage(row, version)}
                disabled={hasNoAccess(row.protocolName, 'mappingSpecPublish')}>
                <Rocket style={{ marginRight: '10px' }} />
                Publish
              </Button>
              <ActionCellForMappingSpecMemo
                handleView={() => viewMappingSpec(row, version)}
                handleDownload={() => downloadMappingSpec(row, version)}
                row={row}
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  );
};

const ExpandCell = ({ row: { id, handleToggleRow, expanded, mappingSpecVersions } }) => {
  const [tooltipTitle, Icon] = useMemo(
    () => (expanded ? ['Collapse', ChevronDown] : ['Expand', ChevronRight]),
    [expanded]
  );
  return (
    <div style={{ width: 15 }}>
      {mappingSpecVersions?.length ? (
        <Tooltip title={tooltipTitle} disableFocusListener>
          <IconButton id="expand" size="small" onClick={() => handleToggleRow(id)}>
            <Icon />
          </IconButton>
        </Tooltip>
      ) : null}
    </div>
  );
};

const ActionCell = ({ row }) => {
  const { disabledActions, showRefreshOverlay, downloadMappingSpecApi } = row;
  const dispatch = useDispatch();

  const onDownload = async (rowData) => {
    const {
      protocolName,
      productID,
      productVersion,
      productMnemonic,
      targetDataModelVersion,
      targetDataModelName,
      targetDataModelType
    } = rowData;
    let payload = {
      protocolName: protocolName,
      productID: productID,
      productVersion: productVersion
    };
    const getTableData = await dispatch(GetProductDataSources(payload)).then(unwrapResult);
    if (getTableData && getTableData.data && getTableData.data.success) {
      const mappingRuleVersionId = getTableData.data?.mappingRuleVersionID || '';

      downloadMappingSpecApi(
        mappingRuleVersionId,
        productMnemonic,
        targetDataModelVersion,
        targetDataModelName,
        targetDataModelType,
        protocolName,
        'DRAFT',
        `${protocolName}_${productMnemonic}_DRAFT`
      );
    } else {
      dispatch(showBanner({ variant: 'error', message: getTableData.data.message }));
    }
  };

  const menuItems = useMemo(() => {
    return row?.mappingSpecVersions?.length
      ? [
          {
            text: 'Download Draft',
            disabled: disabledActions.includes('download-draft'),
            onClick: () => onDownload(row)
          },
          {
            text: 'Refresh Data',
            disabled: hasNoAccess(row.protocolName, 'mappingSpecPublish'),
            onClick: () => showRefreshOverlay(row, false)
          },
          {
            text: 'Reload Data',
            disabled: hasNoAccess(row.protocolName, 'mappingSpecPublish'),
            onClick: () => showRefreshOverlay(row, true)
          }
        ]
      : [
          {
            text: 'Download Draft',
            disabled: disabledActions.includes('download-draft'),
            onClick: () => onDownload(row)
          }
        ];
  }, [row]);

  return (
    <>
      <Tooltip title="Actions" disableFocusListener>
        <IconMenuButton id="actions" menuItems={menuItems} size="small">
          <EllipsisVerticalIcon />
        </IconMenuButton>
      </Tooltip>
    </>
  );
};

const VersionCell = ({ row, column: { accessor } }) => {
  const versionRight = row[accessor];
  return (
    <Typography
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        marginRight: '24px',
        color: '#595959',
        fontSize: '14px'
      }}>
      {versionRight}
    </Typography>
  );
};

const CustomButtonHeader = (props) => {
  const {
    selectedMappingSpecFilter,
    setSelectedMappingSpecFilter,
    getGlobalKeys,
    KeyConstants,
    getMessage,
    toggleFilters,
    useStyles
  } = props;
  const classes = useStyles();
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <Typography
        style={{ fontSize: '14px', margin: '0px 10px 0px 0px' }}
        variant="subtitle"
        gutterBottom>
        {'Mapping Specs'}
      </Typography>
      <Select
        value={selectedMappingSpecFilter}
        className={classes.select}
        size={'small'}
        onChange={(e) => setSelectedMappingSpecFilter(e.target.value)}>
        {getGlobalKeys(KeyConstants.MAPPING_SPEC_FILTER.TOPIC_NAME)?.map((mappingSpecFilter) => (
          <MenuItem value={mappingSpecFilter} key={mappingSpecFilter}>
            {getMessage(KeyConstants.MAPPING_SPEC_FILTER.VALUES[mappingSpecFilter]?.LABEL)}
          </MenuItem>
        ))}
      </Select>
      <Button
        size="small"
        variant="secondary"
        icon={Filter}
        style={{ margin: '0px' }}
        onClick={toggleFilters}>
        Filter
      </Button>
    </div>
  );
};

const columns = [
  {
    accessor: 'expand',
    customCell: ExpandCell
  },
  {
    header: 'Product Mnemonic',
    accessor: 'productMnemonic',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('productMnemonic'),
    fixedWidth: false
  },
  {
    header: 'Description',
    accessor: 'description',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('description'),
    fixedWidth: false
  },
  {
    header: 'Target Data Model Name',
    accessor: 'targetDataModelName',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('targetDataModelName'),
    width: 190,
    fixedWidth: false
  },
  {
    header: 'Target Data Model Version',
    accessor: 'targetDataModelVersion',
    sortFunction: compareNumbers,
    filterFunction: numberSearchFilter('targetDataModelVersion'),
    filterComponent: IntegerFilter,
    width: 190,
    customCell: VersionCell,
    fixedWidth: false
    // align : 'right',
  },
  {
    header: 'Version',
    accessor: 'mappingRuleVersionStatus',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('mappingRuleVersionStatus'),
    width: 160,
    fixedWidth: false
  },
  {
    header: 'Last Updated User',
    accessor: 'userID',
    sortFunction: compareStrings,
    filterComponent: TextFieldFilter,
    filterFunction: createStringSearchFilter('userID'),
    width: 90,
    fixedWidth: false
  },
  {
    header: 'Last Updated Date',
    accessor: 'auditDate',
    sortFunction: compareDates,
    filterFunction: dateFilter('auditDate'),
    filterComponent: DateFilter,
    fixedWidth: false
  },
  {
    accessor: 'userId',
    customCell: ActionCell
  }
];

const EmptyTableTypographyStyleTitle1 = {
  fontSize: '18px',
  fontWeight: 600,
  letterSpacing: 0,
  lineHeight: '22px',
  color: '#595959',
  height: 'auto'
};
const EmptyTableTypographyStyleTitle2 = {
  fontSize: '14px',
  letterSpacing: 0,
  lineHeight: '20px',
  color: '#999999',
  marginTop: '5px',
  height: 'auto'
};

const EmptyTableTypographyStyleTitle3 = {
  fontSize: '14px',
  letterSpacing: 0,
  lineHeight: '20px',
  color: '#999999',
  marginTop: '20px',
  height: 'auto'
};

const Publish = () => {
  const useStyles = makeStyles(styles);
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [dataProducts, setDataProducts] = useState([]);
  const [emptyTableGrid, setEmptyTableGrid] = useState(false);
  const [dataTable, setDataTable] = useState(false);
  const [configTable, setDataConfigTable] = useState(false);
  const [inactiveMessage, setInactiveMessage] = useState(false);
  const [inactiveDataTable, setInactiveDataTable] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);
  const [dataProductButtonDisable, setDataProductButtonDisable] = useState(false);
  const [disabledActions, setDisabledActions] = useState([]);
  const { loading } = useSelector((state) => state.LoaderData);
  const { userLoginID: userId, disableComponentsBasedOnRole } = useSelector(
    (state) => state.LoginFormData
  );
  const { loadDataProducts } = useSelector((state) => state.DataProductStudyLibrary);
  const [displayMappingSpecOverlay, toggleMappingSpecOverlay] = useState(false);
  const [displayOverlay, toggleOverlayDisplay] = useState(false);
  const [refreshDataProduct, setRefreshDataProduct] = useState({});
  const [reloadMappSpec, setMappSpecReload] = useState(false);
  const [handleFetchMappingSpecActivity] = useMappingSpecActivity();
  const [refreshConfirmAlert, setRefreshConfirmAlert] = useState(false);
  const [showAllMappingSpecsFlag, setShowAllMappingSpecsFlag] = useState(false);
  const [selectedEnvForRefresh, setRefreshEnv] = useState();
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const selectedProtocol = useRef();
  const getDisplayName = useDisplayName();
  const { getMessage } = useGlobalMessage();
  const getGlobalKeys = useGlobalKeys();
  const [selectedMappingSpecFilter, setSelectedMappingSpecFilter] = useState(
    (getGlobalKeys(KeyConstants.MAPPING_SPEC_FILTER.TOPIC_NAME)?.length > 0 &&
      getGlobalKeys(KeyConstants.MAPPING_SPEC_FILTER.TOPIC_NAME)[1]) ||
      ''
  );
  const showRefreshOverlay = (productData, isReload = false) => {
    setMappSpecReload(isReload);
    setRefreshDataProduct(productData);
    toggleOverlayDisplay(true);
  };
  const ADD_DATA_PRODUCT = 'add-data-product';

  const onRefreshData = async () => {
    const now = new Date().toISOString();
    const payload = {
      loaderQueue: {
        iqCreateDate: now,
        iqUpdateDate: now,
        iqCreatedBy: Cookies.get('user.id'),
        iqUpdatedBy: Cookies.get('user.id'),
        iqAuditType: 'UPDATE',
        iqAuditDate: now,
        iqActiveFlag: true,
        protocolName: protocol.protocolNumber,
        productName: refreshDataProduct.productMnemonic,
        environment: selectedEnvForRefresh.environmentName,
        mappingRuleVersionID: selectedEnvForRefresh.mappingRuleVersionID,
        isReload: reloadMappSpec,
        loaderStatus: 'queued',
        userName: Cookies.get('user.id')
      }
    };

    setRefreshConfirmAlert(false);
    const saveLoaderQueueResponse = await dispatch(saveLoaderQueue(payload)).then(unwrapResult);
    if (saveLoaderQueueResponse?.data?.success) {
      dispatch(showBanner({ variant: 'success', message: saveLoaderQueueResponse.data.message }));
    } else {
      dispatch(showBanner({ variant: 'error', message: saveLoaderQueueResponse.data.message }));
    }
  };

  const downloadMappingSpecApi = async (
    mappingRuleVersionId,
    productMnemonic,
    targetDataModelVersion,
    targetDataModelName,
    targetDataModelType,
    protocolName,
    mappingSpecVersionName,
    excelName
  ) => {
    if (mappingRuleVersionId) {
      const downloadResult = await dispatch(
        DownloadMappingSpecData({
          mappingRuleVersionID: mappingRuleVersionId
        })
      ).then(unwrapResult);

      if (downloadResult && downloadResult.data && downloadResult.data.success) {
        const params = {
          protocolName: protocolName,
          projectCode: protocol.projectCode,
          sponsor: protocol.sponsor,
          mappingSpecVersionName: mappingSpecVersionName,
          productMnemonic: productMnemonic,
          targetDataModelName: targetDataModelName,
          targetDataModelVersion: targetDataModelVersion,
          targetDataModelType: targetDataModelType,
          getDisplayName
        };
        downloadFile(downloadResult.data, params, excelName, {
          createdBy:
            downloadResult.data.lastUpdatedBy === null
              ? 'Created By would be populated once user begins the Rule Processing'
              : downloadResult.data.lastUpdatedBy,
          createdDate:
            downloadResult.data.lastUpdatedBy === null
              ? 'Created Date would be populated once user begins the Rule Processing'
              : dateFormatByType(downloadResult.data.lastUpdatedDate, 'Table')
        });
      } else {
        dispatch(showBanner({ variant: 'error', message: 'Something Went Wrong!' }));
      }
    }
  };

  useEffect(() => {
    let disabledActionsList = [];
    disableComponentsBasedOnRole &&
      disableComponentsBasedOnRole.map((roleObj) => {
        if (roleObj.module === 'ProductDesigner') {
          roleObj.mappings.map((eachID) => {
            if (eachID.isDisabled) {
              disabledActionsList.push(eachID.feature);
            }
          });
        }
      });

    setDisabledActions(disabledActionsList);
  }, [disableComponentsBasedOnRole]);

  const getStudyLlibraryInfo = async () => {
    setDataProductButtonDisable(false);
    let studyLibData = await dispatch(getStudyLibraryGrid(protocol.protocolNumber)).then(
      unwrapResult
    );
    if (studyLibData && studyLibData?.data && studyLibData?.data?.success) {
      let response = studyLibData.data.studyLibraries;
      let activeArray = [];
      if (response.length > 0) {
        response.map((el) => {
          if (el.isactive) {
            activeArray.push(el);
          }
        });
        if (activeArray.length === 0) {
          setDataProductButtonDisable(true);
        } else {
          setDataProductButtonDisable(false);
        }
      } else {
        setDataProductButtonDisable(true);
      }
    }
  };

  const getStudyPermissions = async (protocol) => {
    const permissionsInfo = await dispatch(getProtocolAuth(protocol)).then(unwrapResult);
    let protocolObj = {};
    if (permissionsInfo.data.success) {
      let permissionJson = permissionsInfo.data.protocolAuth;
      protocolObj[protocol] = permissionJson;
      setProtocolPermissions(protocolObj);
      return true;
    } else {
      return false;
    }
  };

  const getDataProducts = async () => {
    let payload = {
      protocolNumber: protocol.protocolNumber,
      mappingSpecFilterKey: selectedMappingSpecFilter
    }
    try {
      dispatch(showLoader());
      let studyData;
      const permissionsFlag =
        Object.keys(protocol).length > 0 && (await getStudyPermissions(protocol.protocolNumber));
      if (protocol && Object.entries(protocol).length > 0 && permissionsFlag) {
        if (
          (protocol.isStudyLibraryConfigured || protocol.isDataSouceConfigured) &&
          protocol.isActive
        ) {
          studyData = await dispatch(getDataProductWithMappingSpec(payload)).then(
            unwrapResult
          );
          if (studyData?.data?.success) {
            setShowAllMappingSpecsFlag(studyData?.data?.isToggleOn);
            const { publishDataProducts } = studyData.data;
            const dataProductList = [];
            if (publishDataProducts.length > 0) {
              publishDataProducts.map(({ dataProduct, mappingSpecVersions }, index) => {
                dataProduct &&
                  dataProductList.push({
                    auditDate: dateFormatByType(dataProduct.auditDate, 'Table'),
                    description: dataProduct.description,
                    isCurrent: dataProduct.isCurrent === true ? 'Active' : 'Inactive',
                    productMnemonic: dataProduct.productMnemonic,
                    userID: dataProduct.userID,
                    productVersion: dataProduct.productVersion,
                    mappingRuleVersionStatus: dataProduct.mappingRuleVersionStatus,
                    productID: dataProduct.productId,
                    mappingSpecVersions: handleDateSort(mappingSpecVersions),
                    protocolName: dataProduct.protocolNumber,
                    targetDataModelName:
                      dataProduct.targetDataModel !== '' && dataProduct.targetDataModel !== null
                        ? dataProduct.targetDataModel
                        : 'N/A',
                    targetDataModelVersion:
                      dataProduct.targetDataModelVersion !== '' &&
                      dataProduct.targetDataModelVersion !== null
                        ? dataProduct.targetDataModelVersion
                        : 'N/A',
                    targetDataModelType: dataProduct.targetDataModelType,
                    id: dataProduct.productId
                  });
              });

              setDataProducts(dataProductList);
              setDataTable(true);
              setEmptyTableGrid(false);
            } else {
              setEmptyTableGrid(true);
              setDataProducts([]);
              dispatch(getCheckboxFlag(false));
            }
          } else if (studyData && studyData.data && !studyData.data.success) {
            setEmptyTableGrid(false);
            setDataTable(false);
            setDataProducts([]);
            dispatch(showBanner({ variant: 'error', message: studyData.data.message }));
          } else {
            dispatch(showBanner({ variant: 'error', message: studyData.data.message }));
          }
        } else if (!protocol.isStudyLibraryConfigured && !protocol.isActive) {
          setInactiveMessage(true);
          setInactiveDataTable(false);
        } else if (protocol.isStudyLibraryConfigured && !protocol.isActive) {
          studyData = await dispatch(getDataProductWithMappingSpec(protocol.protocolNumber)).then(
            unwrapResult
          );
          if (studyData?.data?.success) {
            setShowAllMappingSpecsFlag(studyData?.data?.isToggleOn);
            let response = studyData?.data?.publishDataProducts;
            let dataProductList = [];
            if (response.length > 0) {
              response.map((el, i) => {
                dataProductList.push({
                  auditDate: dateFormatByType(el.dataProduct.auditDate, 'Table'),
                  description: el.dataProduct.description,
                  isCurrent: el.dataProduct.isCurrent === true ? 'Active' : 'Inactive',
                  productMnemonic: el.dataProduct.productMnemonic,
                  userID: el.dataProduct.userID,
                  productVersion: el.dataProduct.productVersion,
                  mappingRuleVersionStatus: el.dataProduct.mappingRuleVersionStatus,
                  productID: el.dataProduct.productId,
                  mappingSpecVersions: handleDateSort(el.mappingSpecVersions),
                  protocolName: el.dataProduct.protocolNumber,
                  targetDataModelName:
                    el.dataProduct.targetDataModel !== '' && el.dataProduct.targetDataModel !== null
                      ? el.dataProduct.targetDataModel
                      : 'N/A',
                  targetDataModelVersion:
                    el.dataProduct.targetDataModelVersion !== '' &&
                    el.dataProduct.targetDataModelVersion !== null
                      ? el.dataProduct.targetDataModelVersion
                      : 'N/A',
                  id: el.dataProduct.productId
                });
              });
              setDataProducts(dataProductList);
              setDataTable(true);
              setEmptyTableGrid(false);
            } else {
              setDataProducts([]);
              setInactiveDataTable(true);
              setInactiveMessage(false);
            }
          } else if (studyData && studyData.data && !studyData.data.success) {
            setEmptyTableGrid(false);
            setDataTable(false);
            setDataProducts([]);
            dispatch(showBanner({ variant: 'error', message: studyData.data.message }));
          } else {
            dispatch(showBanner({ variant: 'error', message: studyData.data.message }));
          }
        } else {
          setDataTable(false);
          setEmptyTableGrid(false);
          setDataConfigTable(true);
        }
      } else {
        setDataTable(false);
        setEmptyTableGrid(false);
        setDataConfigTable(false);
      }
      dispatch(closeLoader());
    } catch (err) {
      dispatch(closeLoader());
      dispatch(showBanner({ variant: 'error', message: err.message }));
    }
  };

  const handleToggleRow = (rowId) => {
    setExpandedRows((rowsExpanded) =>
      rowsExpanded.includes(rowId)
        ? rowsExpanded.filter((id) => id !== rowId)
        : [...rowsExpanded, rowId]
    );
  };

  useEffect(() => {
    getDataProducts();
  }, [loadDataProducts, selectedMappingSpecFilter]);

  useEffect(() => {
    dispatch(
      breadCrumbData([
        { path: '/dashboard' },
        {
          title: 'Manage Data Pipeline',
          path: '/manage-data-pipeline'
        }
      ])
    );

    setDataTable(false);
    setEmptyTableGrid(false);
    setDataConfigTable(false);
    setDataProducts([]);
    setInactiveMessage(false);
    setInactiveDataTable(false);

    if (
      protocol &&
      Object.keys(protocol).length !== 0 &&
      selectedProtocol.current !== JSON.stringify(protocol)
    ) {
      selectedProtocol.current = JSON.stringify(protocol);
      getStudyLlibraryInfo();
      getDataProducts();
    }
  }, [protocol]);

  const navigateToStudyLibraryPage = () => {
    navigate('/data-standard-library/study-library');
    dispatch(getProtocol(protocol));
  };

  const createMappingSpecData = async (parentRow, row) => {
    const mappSpec = {
      protocolName: protocol.protocolNumber,
      mappingRuleVersionID: row.mappingRuleVersionID,
      createdBy: row.createdBy,
      createdDate: row.createdDate,
      comments: row.comments,
      title: row.title,
      mappingSpecVersionName: row.mappingSpecVersionName,
      productID: row.dataProductID,

      productMnemonic: parentRow.productMnemonic,
      productVersion: parentRow.productVersion,
      targetDataModelName: parentRow.targetDataModelName,
      description: parentRow.description,
      targetDataModelVersion: parentRow.targetDataModelVersion,
      targetDataModelType: parentRow.targetDataModelType
    };

    const mappingSpecActivity = await handleFetchMappingSpecActivity(mappSpec);
    dispatch(
      setMappingSpecDetails({
        ...mappSpec,
        activity: mappingSpecActivity
      })
    );
  };

  const viewMappingSpec = async (parentRow, row) => {
    await createMappingSpecData(parentRow, row);
    toggleMappingSpecOverlay(true);
  };

  const downloadMappingSpec = async (row, version) => {
    const {
      protocolName,
      productMnemonic,
      targetDataModelVersion,
      targetDataModelName,
      userID,
      auditDate,
      targetDataModelType
    } = row;

    //  'publish-download'
    downloadMappingSpecApi(
      version.mappingRuleVersionID,
      productMnemonic,
      targetDataModelVersion,
      targetDataModelName,
      targetDataModelType,
      protocolName,
      version.mappingSpecVersionName,
      `${protocolName}_${productMnemonic}_${version.mappingSpecVersionName}`
    );
  };

  const DetailRow = ({ row }) => {
    return (
      <MappingSpecVersions
        mappingSpecVersions={row.mappingSpecVersions}
        viewMappingSpec={viewMappingSpec}
        downloadMappingSpec={downloadMappingSpec}
        // navigateToPublishPage={navigateToPublishPage}
        createMappingSpecData={createMappingSpecData}
        {...row}
      />
    );
  };

  const renderDataTable = useCallback(() => {
    return (
      <TableWrapper>
        <Table
          title={
            <>
              Data Products
              <Button size="small" onClick={getDataProducts}>
                <Refresh />
              </Button>
            </>
          }
          subtitle={'Choose a Mapping Spec Version to Publish'}
          rows={
            dataProducts &&
            dataProducts.map((row, index) => ({
              ...row,
              expanded: expandedRows.includes(row.id) ? true : false,
              handleToggleRow,
              showRefreshOverlay,
              downloadMappingSpecApi,
              disabledActions,
              key: index
            }))
          }
          ExpandableComponent={DetailRow}
          columns={columns}
          initialSortedColumn="auditDate"
          initialSortOrder="desc"
          headerProps={{
            selectedMappingSpecFilter,
            setSelectedMappingSpecFilter,
            getGlobalKeys,
            KeyConstants,
            getMessage,
            useStyles
          }}
          CustomHeader={CustomButtonHeader}
          rowsPerPageOptions={[10, 20, 50, 100, 'All']}
          tablePaginationProps={{
            labelDisplayedRows: DisplayedRowsLabel,
            truncate: true
          }}
          hasScroll
          maxHeight={650}
        />
      </TableWrapper>
    );
  }, [dataProducts, expandedRows, disabledActions]);

  const renderTable = () => {
    if (loading) {
      return (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      );
    } else if (configTable) {
      return (
        <DataVizCard title="Data Products" subtitle="0 items">
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            <Cog />
          </Typography>
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            No Study Library Added
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
            Add a study library to get started
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle3}>
            <Button variant="primary" size="small" onClick={navigateToStudyLibraryPage}>
              Goto Study Library Page
            </Button>
          </Typography>
        </DataVizCard>
      );
    } else if (inactiveMessage) {
      return (
        <DataVizCard title="Data Products" subtitle="0 items">
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            <Cog />
          </Typography>
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            Study is Inactive
          </Typography>
        </DataVizCard>
      );
    } else if (inactiveDataTable) {
      return (
        <DataVizCard title="Data Products" subtitle="0 items">
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            No data products found
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
            Add data product to get started.
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle3}>
            <Button
              variant="primary"
              size="small"
              disabled={
                disabledActions.includes(ADD_DATA_PRODUCT) ||
                dataProductButtonDisable ||
                inactiveDataTable
              }>
              Add data product
            </Button>
          </Typography>
        </DataVizCard>
      );
    } else if (dataTable) {
      return renderDataTable();
    } else if (emptyTableGrid) {
      return (
        <DataVizCard title="Data Products" subtitle="0 items">
          <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
            No data products found
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
            Add data product to get started.
          </Typography>
          <Typography variant="body2" style={EmptyTableTypographyStyleTitle3}>
            {dataProductButtonDisable || inactiveDataTable ? (
              <Button
                variant="primary"
                size="small"
                disabled={
                  disabledActions.includes(ADD_DATA_PRODUCT) ||
                  dataProductButtonDisable ||
                  inactiveDataTable
                }>
                Add data product
              </Button>
            ) : (
              <Link
                to="/product-designer/add-data-product"
                style={{
                  pointerEvents: disabledActions.includes(ADD_DATA_PRODUCT) && 'none'
                }}>
                <Button
                  variant="primary"
                  size="small"
                  disabled={disabledActions.includes(ADD_DATA_PRODUCT)}>
                  Add data product
                </Button>
              </Link>
            )}
          </Typography>
        </DataVizCard>
      );
    } else {
      return (
        <Grid
          container
          style={{
            display: 'grid',
            placeItems: 'center',
            position: 'relative',
            padding: '13rem'
          }}>
          <img height="23" src={BookLibrary} style={{ marginBottom: '1rem' }} />
          <Grid xs={6}>
            <Typography variant="title2" style={EmptyTableTypographyStyleTitle1}>
              Nothing to See Here
            </Typography>
          </Grid>
          <Grid xs={6}>
            <Typography variant="body2" style={EmptyTableTypographyStyleTitle2}>
              Nothing to see here. Select a protocol to view data
            </Typography>
          </Grid>
        </Grid>
      );
    }
  };

  return (
    <>
      <div>
        <Typography variant="body2">
          <div data-test-id="red" className={classes.page}>
            <Typography variant="body1" gutterBottom>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  {renderTable()}
                </Grid>
              </Grid>
            </Typography>
          </div>
          {displayMappingSpecOverlay ? (
            <MappingSpecDetails
              displayMappingSpecOverlay={displayMappingSpecOverlay}
              toggleMappingSpecOverlay={toggleMappingSpecOverlay}
            />
          ) : null}

          {displayOverlay ? (
            <RefreshMappingSpec
              displayOverlay={displayOverlay}
              toggleOverlayDisplay={toggleOverlayDisplay}
              onRefreshData={(envID) => {
                toggleOverlayDisplay(false);
                setRefreshConfirmAlert(true);
                setRefreshEnv(envID);
              }}
              productID={refreshDataProduct.productID}
              productMnemonic={refreshDataProduct.productMnemonic}
              reload={reloadMappSpec}
            />
          ) : null}

          <CustomModal
            display={refreshConfirmAlert}
            variant={'warning'}
            title={`Are you sure you want to ${reloadMappSpec ? 'Reload' : 'Refresh'} Data?`}
            message={
              'This process can take several minutes/hours to complete. Are you sure you want to continue?'
            }
            buttonPrimaryLabel={`${reloadMappSpec ? 'Reload' : 'Refresh'} Data`}
            handlePrimaryAction={onRefreshData}
            buttonSecondardyLabel={'Cancel'}
            handleClose={() => setRefreshConfirmAlert(false)}
          />
        </Typography>
      </div>
    </>
  );
};

export default Publish;
