import { unwrapResult } from '@reduxjs/toolkit';
import React, { createContext, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { getCodeLists } from 'Redux/Service/ReferenceDataCardsService';
import { setNextEnabled } from 'Redux/Slice/RuleEditorSlice';
import { useStateWithCB } from 'Utils';
import MasteringRules from './MasteringRules';

export const MasteringRulesContext = createContext();

export const MasteringRulesProvider = () => {
  const dispatch = useDispatch();
  const { id: mappingRuleVersionID } = useParams();
  const [treeView, setTreeView] = useState([]);
  const [selected, setSelected] = useState([1]);
  const [selectNodeText, setSelectedNodeText] = useState('');
  const [expanded, setExpanded] = useState([]);
  const [masteringType, setMasteringType] = useState('Partial');
  const [formMetaData, setFormMetaData] = useState({});
  const [parentNodeID, setParentNodeID] = useState('');
  const [parentNodeName, setParentNodeName] = useState('');
  const [confirmAlert, setConfirmAlert] = useState({
    enabled: false,
    type: '',
    variant: '',
    title: '',
    message: '',
    onConfirm: () => null,
    onCancel: () => null
  });
  const [expressions, setExpressions] = useState({});
  const [methodsConstants, setMethodsAndConstants] = useState([]);
  const [expressionTemplates, setExpressionTemplates] = useState([]);
  const [mappingError, setMappingError] = useState('');
  const [codeListDataMapping, setCodeListDataMapping] = useState(null);
  const [changesNotSaved, setUnsavedChanges] = useState([]);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [edcDefaultForms, setEDCDefaultForms] = useStateWithCB({
    'Subject Setting': '',
    'Visit Setting': [],
    saved: true
  });
  const [cdrDefaultForms, setCDRDefaultForms] = useStateWithCB({
    'Visit Setting': {},
    saved: true
  });
  const [searchFieldsEnabled, displaySearchFields] = useState({
    enabled: false,
    onAdd: undefined,
    onCancel: undefined
  });
  const [visitMasteringFilters, setVisitMasteringFilters] = useState({});
  // const navigate = useNavigate();

  const { workFlowModel } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const setChangesNotSaved = useCallback((unsaved, expID) => {
    setUnsavedChanges((changes) => {
      if (!expID) return [];
      const modifiedChanges = [...changes];
      const changeToUpdate = modifiedChanges.findIndex((changeID) => changeID === expID);
      if (unsaved && changeToUpdate === -1) {
        modifiedChanges.push(expID);
      } else if (!unsaved && changeToUpdate !== -1) {
        modifiedChanges.splice(changeToUpdate, 1);
      }
      return modifiedChanges;
    });
  }, []);

  const isOptional = useMemo(() => {
    const moduleName = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    if (workFlowModel && Object.keys(workFlowModel).length > 0) {
      const functionData = workFlowModel.functionData;
      if (functionData && functionData.length > 0) {
        const index = functionData.findIndex((data) => {
          return data.moduleName === moduleName;
        });
        if (!functionData[index]?.isRequired) {
          dispatch(setNextEnabled(true));
          return true;
        } else return false;
      }
    }
  }, [workFlowModel]);

  useEffect(() => {
    let confirmationMessage = 'Changes you made may not be saved.';
    function handler(e) {
      // e.preventDefault();
      if (e || window.event) {
        //Gecko + IE
        (e || window.event).returnValue = confirmationMessage;
      }
      //Webkit, Safari, Chrome
      return confirmationMessage;
    }

    let blockedNavigation;
    if (changesNotSaved.length > 0) {
      window.addEventListener('beforeunload', handler);
      // blockedNavigation = history.block(confirmationMessage);
    }

    return () => {
      window.removeEventListener('beforeunload', handler);
      typeof blockedNavigation === 'function' && blockedNavigation();
    };
  }, [changesNotSaved.length > 0]);

  const handleStepperValidate = (isDisabled) => {
    // Will handle function over here
    setNextDisabled(!isOptional && isDisabled);
    dispatch(setNextEnabled(isOptional || !isDisabled));
  };

  useEffect(() => {
    (async function () {
      if (!codeListDataMapping) {
        try {
          const payload = {
            mappingRuleVersionID
          };
          const codeListsGetAPIData = await dispatch(getCodeLists(payload)).then(unwrapResult);
          if (codeListsGetAPIData && codeListsGetAPIData.data && codeListsGetAPIData.data.success) {
            const mapping = {};
            const codelists = codeListsGetAPIData.data.ruleCodeLists || [];

            for (let { odmForm, odmItem, sdmCodelistLabel } of codelists) {
              const key = `${odmForm.toLowerCase()}#-#${odmItem.toLowerCase()}`;
              if (!(key in mapping)) {
                mapping[key] = [];
              }
              if (sdmCodelistLabel.trim()) mapping[key].push(sdmCodelistLabel.toLowerCase());
            }
            setCodeListDataMapping(mapping);
          }
        } catch (error) {
          setCodeListDataMapping({});
          console.log(error);
        }
      }
    })();
  }, [codeListDataMapping]);

  const setExpressionsForNode = (parentNode, node, library, modifiedExpressions) => {
    setExpressions((exps) => {
      return {
        ...exps,
        [parentNode]: {
          ...(exps[parentNode] || {}),
          [node]: {
            ...(exps[parentNode] ? exps[parentNode][node] || {} : {}),
            [library]: modifiedExpressions
          }
        }
      };
    });
  };

  const contextData = {
    treeView,
    setTreeView,
    selected,
    setSelected,
    expanded,
    setExpanded,
    setSelectedNodeText,
    selectNodeText,
    setMasteringType,
    masteringType,
    formMetaData,
    setFormMetaData,
    setParentNodeID,
    parentNodeID,
    setParentNodeName,
    parentNodeName,
    confirmAlert,
    setConfirmAlert,
    expressions,
    setExpressions,
    setExpressionsForNode,
    setMethodsAndConstants,
    methodsConstants,
    mappingError,
    setMappingError,
    codeListDataMapping,
    changesNotSaved,
    setChangesNotSaved,
    edcDefaultForms,
    setEDCDefaultForms,
    cdrDefaultForms,
    setCDRDefaultForms,
    searchFieldsEnabled,
    displaySearchFields,
    visitMasteringFilters,
    setVisitMasteringFilters,
    expressionTemplates,
    setExpressionTemplates
  };
  return (
    <>
      <MasteringRulesContext.Provider value={contextData}>
        <MasteringRules handleStepperValidate={handleStepperValidate} nextDisabled={nextDisabled} />
      </MasteringRulesContext.Provider>
    </>
  );
};
