import { createTwoWayObject } from 'Utils';

const formsheetColumnNames = createTwoWayObject({
  'Form Name/Data File Mnemonic': {
    key: 'formName',
    type: 'STRING',
    isMandatory: () => true,
    isEditable: () => false
  },
  'Default Domain': {
    key: 'defaultDomain',
    type: 'STRING',
    isMandatory: () => false,
    isEditable: () => true
  },
  'Form Repeat': {
    key: 'formRepeatFlag',
    type: 'STRING',
    isMandatory: () => true,
    isEditable: () => false
  },
  'Repeat Flag': {
    key: 'repeatFlag',
    type: 'STRING',
    isMandatory: () => false,
    isEditable: () => true
  },
  'Other Domains': {
    key: 'otherDomains',
    type: 'STRING',
    isMandatory: () => false,
    isEditable: () => true
  },
  Comments: {
    key: 'comments',
    type: 'STRING',
    isMandatory: () => false,
    isEditable: () => true
  },
  IS_EPRO: {
    key: 'isEpro',
    type: 'STRING',
    isMandatory: () => false,
    isEditable: () => true
  }
});

const getTrimmedValue = (value) => {
  if (value && typeof value === 'string') return value.trim();
  else return value;
};

export { formsheetColumnNames, getTrimmedValue };
