/*eslint-disable */
import React, { useState, useMemo, useEffect } from 'react';
import Select from 'apollo-react/components/Select';
import MenuItem from 'apollo-react/components/MenuItem';
import { GetStudyLibraryType, GetStudyLibrarySource } from 'Redux/Service/AddStudyLibraryService';
import { useSelector, useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { showBanner } from 'Redux/Slice/BannerSlice';
import Grid from 'apollo-react/components/Grid';
import Box from '@mui/material/Box';
import {
  setDataSourceTrailValues,
  setDisableAddSQLButton,
  setSilentProperties
} from 'Redux/Slice/AddStudyLibrarySlice';
import useDisplayName from 'Utils/useDisplayName';
import { DATA_FLOW_TYPES } from 'Constants/DataFlowTypes';
import { LIBRARY_TYPES } from 'Constants/LibraryTypes';

const PRE_SQL_DATA_SOURCES = [
  LIBRARY_TYPES.CDISC_ODM,
  LIBRARY_TYPES.CDR_TABULAR,
  LIBRARY_TYPES.QCODE
];
const CDR_TRIAL_NAMES = [{ trialName: DATA_FLOW_TYPES.TEST }, { trialName: DATA_FLOW_TYPES.PROD }];

const DataSourceTrailName = (props) => {
  const { formik, isAddForm } = props;
  const [dataSourceValue, setDataSourceValue] = useState([]);
  const [trialNameMapping, setTrialNameMapping] = useState([]);
  const { dataSet } = useSelector((state) => state.AddStudyLibrary.studyObj);
  const selectedProtocol = useSelector((state) => state.StudyLibraryData.protocol);
  const dispatch = useDispatch();
  const getDisplayName = useDisplayName();

  const getDataSourceRecord = async () => {
    const response = await dispatch(
      GetStudyLibraryType({
        protocolNumber: selectedProtocol.protocolNumber
      })
    ).then(unwrapResult);

    if (response?.data?.success) {
      const { studyLibraryTypes } = response.data;
      setDataSourceValue(
        Object.keys(studyLibraryTypes).filter(
          (selectedDataSource) =>
            studyLibraryTypes[selectedDataSource] &&
            PRE_SQL_DATA_SOURCES.includes(selectedDataSource)
        )
      );
    } else {
      dispatch(showBanner({ variant: 'error', message: response.data.message }));
    }
  };

  const restrictedTrialName = useMemo(() => {
    const _restrictedTrialName = {};
    dataSet?.forEach((data) => (_restrictedTrialName[data.source] = data.trialName));
    return _restrictedTrialName;
  }, [dataSet]);

  const getTrialNameOrSchema = async () => {
    const response = await dispatch(
      GetStudyLibrarySource({
        protocolNumber: selectedProtocol.protocolNumber,
        type: formik.values.selectedDataSource
      })
    ).then(unwrapResult);
    if (response?.data?.success) {
      let _trialNameMapping = {};
      let cdrSchema, isAzureEnv, dataBaseName;
      if (formik.values.selectedDataSource === LIBRARY_TYPES.CDISC_ODM) {
        response.data.odmMetedataSources.forEach((odm) => {
          _trialNameMapping[odm.edcTrialName] = {
            trialName: odm.edcTrialName,
            studyID: odm.edcStudyID
          };
        });
        isAzureEnv = response?.data?.odmIsAzureEnv;
        dataBaseName = response?.data?.odmDatabaseName;
        setTrialNameMapping(Object.values(_trialNameMapping));
      } else if (formik.values.selectedDataSource === LIBRARY_TYPES.CDR_TABULAR) {
        cdrSchema = response?.data?.cdrSchema;
        isAzureEnv = response?.data?.cdrIsAzureEnv;
        dataBaseName = response?.data?.cdrDatabaseName;
      }
      dispatch(setSilentProperties({ cdrSchema, isAzureEnv, dataBaseName }));
    } else {
      dispatch(showBanner({ variant: 'error', message: response.data.message }));
    }
  };

  useEffect(() => {
    if (PRE_SQL_DATA_SOURCES.includes(formik.values.selectedDataSource)) {
      if (formik.values.selectedDataSource == LIBRARY_TYPES.QCODE) {
        dispatch(setSilentProperties({ cdrSchema: '', isAzureEnv: false, dataBaseName: '' }));
      } else {
        getTrialNameOrSchema();
      }
    }
    if (formik.values.selectedDataSource === '') {
      getDataSourceRecord();
      setTrialNameMapping([]);
    }
    dispatch(
      setDataSourceTrailValues({
        selectedDataSource: formik.values.selectedDataSource,
        selectedTrialName: formik.values.selectedTrialName
      })
    );
    if (
      (formik.values.selectedDataSource !== '' &&
        formik.values.selectedTrialName?.trialName !== '' &&
        formik.values.selectedTrialName?.trialName !== undefined) ||
      formik.values.selectedDataSource === LIBRARY_TYPES.QCODE
    ) {
      dispatch(setDisableAddSQLButton(false));
    } else {
      dispatch(setDisableAddSQLButton(true));
    }
  }, [formik.values.selectedDataSource, formik.values.selectedTrialName]);

  useEffect(() => {
    getDataSourceRecord();
  }, []);

  return (
    <Box width={'100%'}>
      <Grid container spacing={2}>
        {formik.values?.type === LIBRARY_TYPES.CUSTOM_SQL_LIBRARY && (
          <Grid item xs={6}>
            <Select
              id="selectedDataSource"
              name="selectedDataSource"
              label="Data Source"
              placeholder="Select Data Source"
              onChange={(e) => {
                formik.getFieldHelpers('selectedTrialName').setValue({});
                formik.handleChange(e);
              }}
              disabled={!isAddForm}
              SelectProps={{ renderValue: (item) => item }}
              value={getDisplayName(formik.values.selectedDataSource)}
              onBlur={formik.handleBlur}
              error={formik.touched.selectedDataSource && Boolean(formik.errors.selectedDataSource)}
              helperText={formik.touched.selectedDataSource && formik.errors.selectedDataSource}
              fullWidth>
              {dataSourceValue.map((item) => (
                <MenuItem key={item} value={item}>
                  {getDisplayName(item)}
                </MenuItem>
              ))}
            </Select>
          </Grid>
        )}
        {PRE_SQL_DATA_SOURCES.includes(formik.values.selectedDataSource) &&
          formik.values.selectedDataSource !== LIBRARY_TYPES.QCODE && (
            <Grid item xs={6}>
              <Select
                id="selectedTrialName"
                name="selectedTrialName"
                label={
                  formik.values.selectedDataSource === LIBRARY_TYPES.CDISC_ODM
                    ? 'Trial Name'
                    : 'Data Flow Type'
                }
                placeholder={
                  formik.values.selectedDataSource === LIBRARY_TYPES.CDISC_ODM
                    ? 'Select Trial Name'
                    : 'Select Data Flow Type'
                }
                disabled={!isAddForm}
                value={formik.values.selectedTrialName}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                SelectProps={{ renderValue: (item) => item.trialName }}
                onBlur={formik.handleBlur}
                fullWidth
                error={formik.touched.selectedTrialName && Boolean(formik.errors.selectedTrialName)}
                helperText={formik.touched.selectedTrialName && formik.errors.selectedTrialName}>
                {(formik.values.selectedDataSource === LIBRARY_TYPES.CDISC_ODM
                  ? trialNameMapping
                  : CDR_TRIAL_NAMES
                ).map((item) => (
                  <MenuItem
                    value={item}
                    key={item}
                    selected={item.trialName === formik.values.selectedTrialName}
                    disabled={
                      restrictedTrialName[formik.values.selectedDataSource] &&
                      item.trialName !== restrictedTrialName[formik.values.selectedDataSource]
                    }>
                    {item.trialName}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
      </Grid>
    </Box>
  );
};

export default DataSourceTrailName;
