import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const GetMappingSpecVersions = createAsyncThunk(
  'Publish/GetMappingSpecVersions',
  ({ dataProductID, mappingSpecFilterKey }) => {
    return axios.get(
      `${API_URL}/api/Publish/GetMappingSpecVersions?dataProductID=${dataProductID}&MappingSpecFilterKey=${mappingSpecFilterKey}`
    );
  }
);

export const GetMappingSpecViewComments = createAsyncThunk(
  'Publish/GetMappingSpecViewComments',
  ({ mappingRuleVersionID }) => {
    return axios.get(
      `${API_URL}/api/Publish/GetMappingSpecViewComments?mappingRuleVersionID=${mappingRuleVersionID}`
    );
  }
);

export const GetEnvironments = createAsyncThunk('Publish/GetEnvironments', ({ ProductId }) => {
  return axios.get(`${API_URL}/api/Publish/GetEnvironments?ProductId=${ProductId}`);
});

export const GetDataExportLocations = createAsyncThunk('MappingSpec/GetDataExportLocations', () => {
  return axios.get(`${API_URL}/api/Publish/GetDataExportLocations`);
});

export const SavePublishMappingSpecData = createAsyncThunk(
  'MappingSpec/SavePublishMappingSpecData',
  (payload) => {
    return axios.post(`${API_URL}/api/Publish/SavePublishMappingSpecData`, payload);
  }
);

export const CopyDataProduct = createAsyncThunk('MappingSpec/CopyDataProduct', (payload) => {
  return axios.post(`${API_URL}/api/Publish/CopyDataProduct`, payload);
});

export const DownloadMappingSpecData = createAsyncThunk(
  'MappingSpec/DownloadMappingSpecData',
  ({ mappingRuleVersionID }) => {
    return axios.get(
      `${API_URL}/api/RuleEditor/DownloadProdMappingSpecVersionData?mappingRuleVersionID=${mappingRuleVersionID}`
    );
  }
);

export const GetProductDataSourcesByMappingRuleID = createAsyncThunk(
  'MappingSpec/GetProductDataSourcesByMappingRuleID',
  ({ mappingRuleVersionID, isDummySource }) => {
    return axios.get(
      `${API_URL}/api/RuleEditor/GetProductDataSourcesByMappingRuleID?mappingRuleVersionID=${mappingRuleVersionID}&isDummySource=${Boolean(
        isDummySource
      )}`
    );
  }
);

export const GetSchedularTypes = createAsyncThunk(
  'Publish/GetJobSchedulerTypes',
  ({ mappingRuleVersionID }) => {
    return axios.get(
      `${API_URL}/api/Publish/GetJobSchedulerTypes?MappingRuleVersionID=${mappingRuleVersionID}`
    );
  }
);

export const UpdateJobSchedulerType = createAsyncThunk(
  'MappingSpec/UpdateJobSchedulerType',
  (payload) => {
    return axios.post(`${API_URL}/api/Publish/UpdateJobSchedulerTypes`, payload);
  }
);

export const GetConfigurefolders = createAsyncThunk(
  'Publish/GetConfigurefolders',
  ({ destination, location, folderpath }) => {
    return axios.get(`${API_URL}/api/RuleEditor/GetConfigurefolders`, {
      params: {
        destination,
        location,
        folderpath
      }
    });
  }
);
