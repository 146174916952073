import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import Footer from 'Components/Footer';
import { setNextEnabled } from 'Redux/Slice/RuleEditorSlice';
import Grid from 'apollo-react/components/Grid';
import Loader from 'apollo-react/components/Loader';
import Typography from 'apollo-react/components/Typography';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useDeepCompareEffect from 'use-deep-compare-effect';
import CardEmptyLayout from './CardEmptyLayout';
import CodeLists from './CodeLists';
import CtpSvt from './CtpSvt';
import FormSheet from './FormSheet';
import { isModuleRequired } from './RuleEditorUtils';
import TimePoints from './TimePoints';
import TrialArms from './TrialArms';
import TrialElements from './TrialElements';
import TrialIE from './TrialIE';
import TrialVisits from './TrialVisits';
import MedDraThesaurus from './MedDraThesaurus';
import DrugThesaurus from './DrugThesaurus';

const useStyles = makeStyles({
  loader: {
    '&>div': {
      marginTop: 'calc(50vh - 113px)'
    }
  }
});

const ReferenceData = () => {
  const [cards, setCards] = useState([]);
  const [dropdownCards, setDropDownCards] = useState([]);
  const { rowDataForRuleEditor } = useSelector((state) => state.DataProductStudyLibrary);
  const [moduleValidationResult, setModuleValidationResult] = useState({});
  const dispatch = useDispatch();
  const [mappingError, setMappingError] = useState('');
  const [isCompletedResult, setIsCompletedResult] = useState({});
  const [isCompletedCards, setIsCompletedCards] = useState(false);
  const classes = useStyles();

  const setError = (error) => {
    setMappingError(error);
  };

  const location = useLocation();
  const { createMappingObj = {} } = useSelector((state) => {
    return state.RuleEditorData;
  });

  const currentPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  const { workFlowModel } = useSelector((state) => {
    return state.RuleEditorData;
  });

  useDeepCompareEffect(() => {
    if (workFlowModel && Object.keys(workFlowModel).length > 0) {
      const cardItems = workFlowModel.referenceData.map((el) => ({
        name: el.displayName,
        module: el.moduleName,
        required: el.isRequired
      }));
      let modCards = cardItems.map((card) => {
        if (card.required) {
          return { ...card, visible: true, isCompleted: false };
        }
        return { ...card, visible: false, isCompleted: false };
      });

      modCards.sort((a, b) => Number(b.required) - Number(a.required));

      let temp = {};
      cardItems.forEach((card) => {
        temp[card.module] = false;
      });
      let temp2 = {};
      cardItems.forEach((card) => {
        temp2[card.module] = false;
      });

      let hiddenCards = modCards.filter((card) => {
        return !card.visible;
      });

      setIsCompletedResult(temp2);
      setModuleValidationResult(temp);

      setCards(modCards);
      setDropDownCards(hiddenCards);
    }
  }, [workFlowModel]);

  useEffect(() => {
    let allTrue = Object.values(moduleValidationResult).every((item) => item);
    if (workFlowModel && Object.keys(workFlowModel).length > 0) {
      if (!isModuleRequired(workFlowModel.functionData, currentPath)) {
        dispatch(setNextEnabled(true));
        return;
      }
    }
    dispatch(setNextEnabled(allTrue));
  }, [moduleValidationResult]);

  const setModuleValidation = (moduleName, valid) => {
    setModuleValidationResult((prev) => {
      return { ...prev, [moduleName]: valid };
    });
  };

  useEffect(() => {
    let allTrue = Object.values(isCompletedResult).every((item) => item);
    setIsCompletedCards(allTrue);
  }, [isCompletedResult]);

  const setIsCompleted = (moduleName, valid) => {
    let tempCards = [...cards];
    const module = tempCards.find((card) => {
      return card.module === moduleName;
    });
    if (module) {
      setIsCompletedResult((prev) => {
        return { ...prev, [moduleName]: valid };
      });
    }
  };

  const setCardVisibility = (moduleName, value) => {
    let tempCards = [...cards];
    const module = tempCards.find((card) => {
      return card.module === moduleName;
    });
    if (module) {
      module.visible = value;
    }

    let tempDropdownCards = tempCards.filter((card) => {
      return !card.visible;
    });
    setCards(tempCards);
    setDropDownCards(tempDropdownCards);
  };

  const getCards = (cardData, required, cardName, visible) => {
    switch (cardData) {
      case 'trial-visits':
        return (
          <TrialVisits
            createMappingObj={createMappingObj}
            setError={setError}
            required={required}
            title={cardName}
            configuration={{ setModuleValidation, setCardVisibility, setIsCompleted }}
            visible={visible}
          />
        );
      case 'time-points':
        return (
          <TimePoints
            createMappingObj={createMappingObj}
            setMappingError={setError}
            required={required}
            title={cardName}
            configuration={{ setModuleValidation, setCardVisibility, setIsCompleted }}
            visible={visible}
          />
        );
      case 'trial-arms':
        return (
          <TrialArms
            createMappingObj={createMappingObj}
            setMappingError={setError}
            required={required}
            title={cardName}
            configuration={{ setModuleValidation, setCardVisibility, setIsCompleted }}
            visible={visible}
          />
        );
      case 'trial-elements':
        return (
          <TrialElements
            createMappingObj={createMappingObj}
            setMappingError={setError}
            required={required}
            title={cardName}
            configuration={{ setModuleValidation, setCardVisibility, setIsCompleted }}
            visible={visible}
          />
        );
      case 'code-lists':
        return (
          <CodeLists
            createMappingObj={createMappingObj}
            setMappingError={setError}
            required={required}
            title={cardName}
            configuration={{ setModuleValidation, setCardVisibility, setIsCompleted }}
            visible={visible}
          />
        );
      case 'trial-IE':
        return (
          <TrialIE
            createMappingObj={createMappingObj}
            setMappingError={setError}
            required={required}
            title={cardName}
            configuration={{ setModuleValidation, setCardVisibility, setIsCompleted }}
            visible={visible}
          />
        );
      case 'ctp-svt':
        return (
          <CtpSvt
            createMappingObj={createMappingObj}
            setMappingError={setError}
            required={required}
            title={cardName}
            configuration={{ setModuleValidation, setCardVisibility, setIsCompleted }}
            visible={visible}
          />
        );
      case 'forms-sheet':
        return (
          <FormSheet
            createMappingObj={createMappingObj}
            setMappingError={setError}
            required={required}
            title={cardName}
            configuration={{ setModuleValidation, setCardVisibility, setIsCompleted }}
            visible={visible}
          />
        );
              
        case 'drug-thesaurus':
          return (
            <DrugThesaurus
            createMappingObj={createMappingObj}
            setMappingError={setError}
            required={required}
            title={cardName}
            configuration={{ setModuleValidation, setCardVisibility, setIsCompleted }}
            visible={visible}
          />         
          );
      case 'meddra-thesaurus':
        return (
          <MedDraThesaurus
            createMappingObj={createMappingObj}
            setMappingError={setError}
            required={required}
            title={cardName}
            configuration={{ setModuleValidation, setCardVisibility, setIsCompleted }}
            visible={visible}
          />
        );
      default:
        return null;
    }
  };
  const renderCards = (cards) => {
    let uniqueCards = [...new Set(cards)];
    return uniqueCards.map((card) => {
      return getCards(card.module, card.required, card.name, card.visible);
    });
  };
  return (
    <>
      {isCompletedCards ? null : <Loader isInner overlayClassName={classes.loader}></Loader>}
      <Box pt={0} pl={3} pr={3}>
        <Typography variant="h3">{'Upload Reference Data'}</Typography>
        <Box>
          <Grid container spacing={0} style={{ marginTop: '1rem' }}>
            <Grid item xs={3}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Product Mnemonic
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {rowDataForRuleEditor && rowDataForRuleEditor.productNnemonic}
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography variant="body2" style={{ color: '#595959', fontSize: '16px' }}>
                Description
              </Typography>
              <Typography variant="title" style={{ color: '#000000', fontSize: '16px' }}>
                {rowDataForRuleEditor && rowDataForRuleEditor.description}
              </Typography>
            </Grid>
          </Grid>
          <Typography style={{ color: 'red', marginTop: '1rem' }}>
            {mappingError ? mappingError : ''}
          </Typography>
        </Box>
        <>
          <Grid container xs={12} style={{ marginBottom: '1.5rem' }}>
            {renderCards(cards)}
            <Grid item xs={4} style={{}}>
              <CardEmptyLayout
                configuration={{ setCards, setCardVisibility }}
                dropdownCards={dropdownCards}
              />
            </Grid>
          </Grid>
        </>
      </Box>
      <Footer width={'100%'} />
    </>
  );
};

export default ReferenceData;
