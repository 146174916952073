import Box from '@mui/material/Box';
import { makeStyles } from '@mui/styles';
import { unwrapResult } from '@reduxjs/toolkit';
import ChevronLeft from 'apollo-react-icons/ChevronLeft';
import Download from 'apollo-react-icons/Download';
import Filter from 'apollo-react-icons/Filter';
import Button from 'apollo-react/components/Button';
import Card from 'apollo-react/components/Card';
import DataVizCard from 'apollo-react/components/DataVizCard';
import Grid from 'apollo-react/components/Grid';
import Table, {
  compareStrings,
  createStringSearchFilter
} from 'apollo-react/components/Table';
import Typography from 'apollo-react/components/Typography';
import Loader from 'Components/Loader/Loader';
import { TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { GetQCodeReferenceData } from 'Redux/Service/ReferenceDataCardsService';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { jsonToExcel } from 'Utils';
import { MessageConstants } from 'Utils/MessageConstants';
import useGlobalMessage from 'Utils/useGlobalMessage';
import useGetTitle from '../../../hooks/useGetTitle';
import { DATASET_NAME_MT } from 'Utils/Constants';

const useStyles = makeStyles({
  toolbar: {
    height: 'auto'
  }
});

const MedDraThesaurusPreview = () => {
  const { cardTitle } = useGetTitle('meddra-thesaurus');
  const navigate = useNavigate();
  const { id: mappingRuleVersionID } = useParams();
  const [medDraRowData, setMedDraRowData] = useState([]);
  const [medDraColumns, setMedDraColumns] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const classes = useStyles();
  const { getMessage } = useGlobalMessage();

  useEffect(() => {
    (async () => {
      let payload = {
        isImport: false,
        protocolName: protocol?.protocolNumber,
        datasetName: DATASET_NAME_MT,
        mappingRuleVersionID: mappingRuleVersionID
      };
      const medDraGetAPIData = await dispatch(GetQCodeReferenceData(payload)).then(unwrapResult);
      if (medDraGetAPIData?.data?.success) {
        const medDraQueryData = medDraGetAPIData.data.qcodeQueryResultData;
        if (medDraQueryData) {
          setMedDraColumns(medDraQueryData?.columns?.split(',') || []);
          setMedDraRowData(JSON.parse(medDraQueryData?.queryJsonResult || '[]'));
        }
      } else {
        dispatch(showBanner({ variant: 'error', message: medDraGetAPIData?.data?.message }));
      }
      setIsLoading(false);
    })();
  }, []);

  const CustomButtonHeader = useCallback((props) => {
    const { toggleFilters, medDraRowData } = props;

    const onHandleDownload = () => {
      let newFileArr = [];
      medDraRowData.map((obj) => {
        let upperObj = Object.keys(obj).reduce((acc, key) => {
          acc[key.toUpperCase()] = obj[key];
          return acc;
        }, {});
        newFileArr.push(upperObj);
      });
      jsonToExcel(newFileArr, 'MedDraThesaurus.xlsx');
    };

    const returnToReferenceData = () => {
      navigate(`/product-designer/rule-editor/${mappingRuleVersionID}/reference-data`);
    };

    return (
      <div style={{ width: '100%' }}>
        <div>
          <Button
            icon={<ChevronLeft />}
            size="small"
            onClick={returnToReferenceData}
            style={{
              marginRight: 10,
              marginBottom: '10px',
              marginTop: '10px'
            }}>
            {getMessage(MessageConstants.REFERENCE_DATA_PREVIEW_RETURN_BUTTON)}
          </Button>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Grid item xs={6}>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 600,
                    fontSize: '20px',
                    float: 'left',
                    marginRight: '20px'
                  }}>
                  {cardTitle && cardTitle.length > 0 && cardTitle[0].displayName}
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Grid item xs={9} style={{ marginRight: '4px' }}>
                  {/* <TextField placeholder="Placeholder" fullWidth style={{ margin: 0 }} /> */}
                </Grid>

                <Grid item xs={3} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button variant="secondary" size="small" onClick={toggleFilters}>
                    <Filter style={{ height: '18px', width: '18px', marginRight: '5px' }} /> Filter
                  </Button>
                  <Button size="small" onClick={onHandleDownload} style={{ color: 'black' }}>
                    <Download />
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        <hr style={{ margin: '22px 0px' }} />
      </div>
    );
  });

  const columns = useMemo(() => {
    return medDraColumns.map((column) => {
      return {
        header: column,
        accessor: column,
        sortFunction: compareStrings,
        filterComponent: TextFieldFilter,
        filterFunction: createStringSearchFilter(column),
        fixedWidth: false
      };
    });
  }, [medDraColumns]);

  return (
    <>
      {isLoading ? (
        <DataVizCard>
          <Loader />
        </DataVizCard>
      ) : (
        <>
          <Card style={{ marginTop: '1rem' }}>
            <Table
              classes={classes}
              columns={columns}
              rows={medDraRowData}
              rowsPerPageOptions={[10, 20, 50, 100, 'All']}
              hasScroll
              maxHeight={650}
              rowProps={{ hover: false }}
              tablePaginationProps={{
                // labelDisplayedRows: ({ from, to, count }) =>
                //   `${count === 1 ? 'Item' : 'Items'} ${from}-${to} of ${count}`,
                truncate: true
              }}
              CustomHeader={(props) => <CustomButtonHeader {...props} />}
              headerProps={{
                medDraRowData
              }}
            />
          </Card>
        </>
      )}
    </>
  );
};

export default MedDraThesaurusPreview;
