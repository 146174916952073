import { useCallback } from 'react';
import { useSelector } from 'react-redux';

/**
 * Custom hook:
 * If a topic exists in 'globalKeys', it returns the mapped List of Keys; otherwise, it returns empty List.
 * 
 * @returns {function} - Returns a function to get the List of Global Keys for a given topic.
 - Usage: `const getGlobalKeys = useGlobalKeys(); const globalKeys = getGlobalKeys('topicName')`;
 */
export const useGlobalKeys = () => {
  const { globalKeys = {} } = useSelector((state) => state.AppData);
  return useCallback((originalValue) => globalKeys[originalValue] || [], [globalKeys]);
};

export default useGlobalKeys;
