/*eslint-disable */
import React, { useContext, useMemo, useState } from 'react';
import { MasteringRulesContext } from '../../MasteringRulesProvider';
import { TextFieldFilter } from 'Pages/Dashboard/Components/Filters';
import { createStringSearchFilter } from 'apollo-react/components/Table';

import Table from 'apollo-react/components/Table';
import { Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Notification } from 'Components/Common/Notification';
import Tooltip from 'apollo-react/components/Tooltip';
import Button from 'apollo-react/components/Button';

import MenuItem from 'apollo-react/components/MenuItem';
import Select from 'apollo-react/components/Select';

import Typography from 'apollo-react/components/Typography';
import { getSourceName } from 'Utils';
import { showBanner } from 'Redux/Slice/BannerSlice';
import { SaveVariableMappingRules } from 'Redux/Service/MasteringRuleService';
import { unwrapResult } from '@reduxjs/toolkit';
import { MASTERING_TYPE_MAPPING, SUBJECT_SETTING, VISIT_SETTING } from '../../MasteringRules';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import DisplayedRowsLabel from 'Components/Common/DisplayedRowsLabel';
import CustomModal from 'Components/Modal';
import Cookies from 'js-cookie';
import StatusExclamationIcon from 'apollo-react-icons/StatusExclamation';
import Autocomplete from 'apollo-react/components/Autocomplete';
import useDisplayName from 'Utils/useDisplayName';
import Warning from 'Images/Warning.svg';

const useStyles = makeStyles({
  toolbar: {
    height: 'auto',
    '&>div': {
      width: '100%'
    }
  }
});

const CDR_TABULAR = 'CDR Tabular';

const checkIsDisabled = (edcDefaultForms, parentNodeName) => {
  return [
    !edcDefaultForms._edcDefaultForms ||
      edcDefaultForms._edcDefaultForms[parentNodeName] === edcDefaultForms[parentNodeName]
  ];
};

const SaveButton = ({ onSaveDefaultForms, edcDefaultForms, parentNodeName, hidden }) => {
  let [isDisabled, disabledMessage] = checkIsDisabled(edcDefaultForms, parentNodeName);

  if (hidden) return null;

  return (
    <Tooltip title={disabledMessage || 'Save'} disableFocusListener>
      <span>
        <Button
          disabled={isDisabled}
          size="small"
          variant="primary"
          style={{ color: 'white !important' }}
          onClick={onSaveDefaultForms}>
          Save
        </Button>
      </span>
    </Tooltip>
  );
};

const useSelectStyles = makeStyles({
  select: {
    margin: '0',
    '& .MuiInputBase-root': {
      maxWidth: '400px !important',
      minWidth: '200px !important'
    }
  }
});

const DefaultFormSelect = ({ row, column: { accessor } }) => {
  const classes = useSelectStyles();
  const { libraryType, parentNodeName } = row;
  if (parentNodeName === VISIT_SETTING) {
    const selectedForms = (
      row.libraryType === CDR_TABULAR
        ? row[accessor][parentNodeName][row.libraryName]?.map((form) => form) || []
        : Array.isArray(row[accessor][parentNodeName])
        ? row[accessor][parentNodeName]
        : [row[accessor][parentNodeName]]
    )?.filter(Boolean);
    return (
      <Tooltip body={selectedForms?.length ? selectedForms : 'No forms selected'} placement="right">
        <Box
          component={'span'}
          sx={{
            '&:after': selectedForms?.length
              ? {}
              : {
                  verticalAlign: '-10%',
                  margin: '0.5rem',
                  content: `url(${Warning})`
                }
          }}>
          {selectedForms?.length}
        </Box>
      </Tooltip>
    );
  } else if (
    libraryType !== 'CDISC ODM' &&
    (!libraryType.startsWith(CDR_TABULAR) || parentNodeName === SUBJECT_SETTING)
  ) {
    return (
      <Tooltip title={row[accessor][parentNodeName]}>
        <Typography
          variant="title2"
          style={{
            fontSize: '14px',
            color: '#595959'
          }}>
          {row[accessor][parentNodeName]}
        </Typography>
      </Tooltip>
    );
  } else {
    return (
      <Autocomplete
        className={classes.select}
        value={
          row.libraryType === 'CDISC ODM'
            ? Array.isArray(row[accessor][parentNodeName])
              ? row[accessor][parentNodeName]?.map((form) => ({ label: form }))
              : { label: row[accessor][parentNodeName] }
            : row[accessor][parentNodeName][row.libraryName]?.map((form) => ({ label: form }))
        }
        source={row.forms?.map((form) => ({ label: form }))}
        size="small"
        limitChips={2}
        alwaysLimitChips
        filterSelectedOptions={false}
        multiple={parentNodeName === VISIT_SETTING}
        disableClearable={true}
        onChange={(e, value) =>
          Array.isArray(value)
            ? row?.onDefaultFormChange(
                value.map((_form) => _form.label),
                row
              )
            : row?.onDefaultFormChange(value?.label, row)
        }
        showSelectAll={parentNodeName === VISIT_SETTING}
        placeholder={
          parentNodeName === VISIT_SETTING ? 'Select Source Form' : 'Select Default Form'
        }>
        {/* {row.forms?.map((form, index) => (
          <MenuItem key={form + index} value={form}>
            {form}
          </MenuItem>
        ))} */}
      </Autocomplete>
    );
  }
};

const SelectForm = ({ row }) => {
  const { handleSelectForm } = row;
  return <Button onClick={() => handleSelectForm(row)}>Source Form</Button>;
};

const columns = [
  {
    header: 'Source Name',
    accessor: 'sourceName',
    filterFunction: createStringSearchFilter('sourceName'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Library Type',
    accessor: 'libraryTypeDisplayName',
    filterFunction: createStringSearchFilter('libraryTypeDisplayName'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Library Name',
    accessor: 'libraryName',
    filterFunction: createStringSearchFilter('libraryName'),
    filterComponent: TextFieldFilter
  },
  {
    header: 'Default Form',
    accessor: 'selectedForm',
    customCell: DefaultFormSelect,
    maxWidth: '300px'
  }
];

const getModifiedColumns = (parentNodeName) => {
  const _columns = [...columns];
  if (parentNodeName === VISIT_SETTING) {
    _columns[3].header = 'Source Form';
    _columns.splice(0, 1);
    _columns.push({
      header: '',
      accessor: 'selectForm',
      customCell: SelectForm,
      maxWidth: '100px'
    });
  } else _columns[3].header = 'Default Form';
  return _columns;
};

const DefaultFormSettings = (props) => {
  const classes = useStyles();
  const contextData = useContext(MasteringRulesContext);
  const {
    parentNodeName,
    expressions,
    edcDefaultForms,
    setEDCDefaultForms,
    cdrDefaultForms,
    setCDRDefaultForms,
    visitMasteringFilters
  } = contextData;

  const { defaultFormMapping, getVariableMappingRules, handleSelectForm } = props;
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const { id: mappingRuleVersionID } = useParams();
  const [confirmAlertEnabled, showConfirmAlert] = useState(false);
  const dispatch = useDispatch();
  const getDisplayName = useDisplayName();

  const userId = Cookies.get('user.id');

  const onSaveDefaultForms = async () => {
    const payload = { itemMappingRules: [] };
    let newRowSeq;
    defaultFormMapping.forEach(({ libraryType, rowSeq, selectedForm }) => {
      newRowSeq = newRowSeq || Math.max(...Object.values(rowSeq), 0) + 1;
      if (parentNodeName === SUBJECT_SETTING) {
        payload.itemMappingRules.push({
          iqCreateDate: new Date().toISOString(),
          iqUpdateDate: new Date().toISOString(),
          iqCreatedBy: userId,
          iqUpdatedBy: userId,
          iqAuditType: 'INSERT',
          iqAuditDate: new Date().toISOString(),
          iqActiveFlag: true,
          protocolNumber: protocol.protocolNumber,
          mappingRuleVersionId: mappingRuleVersionID,
          variableName: 'Q_ROW_CONDITION',
          rowName:
            libraryType === CDR_TABULAR
              ? `CDR Tabular_${selectedForm[parentNodeName]}`
              : libraryType === 'CDISC ODM'
              ? `CDISC ODM_${selectedForm[parentNodeName]}`
              : libraryType,
          sourceName: getSourceName(libraryType),
          sourceSYSID: 0,
          domainCode: MASTERING_TYPE_MAPPING[parentNodeName],
          ruleSeq: 0,
          expression: '"$NOMAP"',
          concatenate: 'N',
          rowSeq:
            libraryType === 'CDISC ODM'
              ? rowSeq[`CDISC ODM_${selectedForm[parentNodeName]}`] || newRowSeq++
              : rowSeq,
          isSuppqual: 'N',
          defaultForm: selectedForm[parentNodeName],
          isMapRowActive: true
        });
      }
    });
    const saveExpression = await dispatch(SaveVariableMappingRules(payload)).then(unwrapResult);

    if (saveExpression && saveExpression.data.success) {
      dispatch(showBanner({ variant: 'success', message: saveExpression.data.message }));
      setEDCDefaultForms((defForms) => {
        const _defForms = { ...defForms, saved: true, _edcDefaultForms: undefined };
        return _defForms;
      }, getVariableMappingRules);
    } else {
      dispatch(showBanner({ variant: 'error', message: saveExpression.data.message }));
    }
    showConfirmAlert(false);
  };

  const rowData = useMemo(() => {
    const edcRows = [];
    const otherRows = [];

    const cdrData = {};

    defaultFormMapping.forEach((_row) => {
      if (_row.libraryType === CDR_TABULAR && parentNodeName === VISIT_SETTING) {
        if (cdrData[_row.libraryName]) {
          cdrData[_row.libraryName].forms.push(..._row.forms);
        } else {
          cdrData[_row.libraryName] = {
            ..._row,
            forms: [..._row.forms],
            parentNodeName,
            cdrDefaultForms,
            key: _row.libraryType + _row.libraryName,
            onDefaultFormChange: (value, row) => {
              if (
                JSON.stringify(value.sort()) ===
                JSON.stringify(cdrDefaultForms[parentNodeName][row.libraryName]?.sort())
              )
                return;

              setCDRDefaultForms((_cdrDefaultForms) => {
                const modifiedFormSelection = {
                  ..._cdrDefaultForms[parentNodeName],
                  [row.libraryName]: value
                };
                return {
                  ..._cdrDefaultForms,
                  [parentNodeName]: modifiedFormSelection,
                  saved: false,
                  _cdrDefaultForms: _cdrDefaultForms._cdrDefaultForms || _cdrDefaultForms
                };
              });
            }
          };
        }
      } else if (_row.libraryType === 'CDISC ODM') {
        edcRows.push({
          ..._row,
          parentNodeName,
          edcDefaultForms,
          onDefaultFormChange: (value) => {
            if (
              parentNodeName === VISIT_SETTING
                ? JSON.stringify(value.sort()) ===
                  JSON.stringify(edcDefaultForms[parentNodeName].sort())
                : value === edcDefaultForms[parentNodeName]
            )
              return;

            setEDCDefaultForms((_edcDefaultForms) => {
              return {
                ..._edcDefaultForms,
                [parentNodeName]: value,
                saved: false,
                _edcDefaultForms: _edcDefaultForms._edcDefaultForms || _edcDefaultForms
              };
            });
          },
          key: _row.libraryType + _row.libraryName + _row.sourceName
        });
      } else {
        otherRows.push({
          ..._row,
          parentNodeName,
          key: _row.libraryType + _row.libraryName + _row.sourceName
        });
      }
    });
    otherRows.sort((rowA, rowB) => {
      if (rowA.libraryType === CDR_TABULAR) {
        if (rowB.libraryType === CDR_TABULAR) return 0;
        else {
          return -1;
        }
      } else return +1;
    });
    return [...edcRows, ...Object.values(cdrData), ...otherRows].map((_row) => ({
      ..._row,
      libraryTypeDisplayName: _row.cdrTabularLibTypeDisplayName
        ? getDisplayName(_row.cdrTabularLibTypeDisplayName)
        : getDisplayName(_row.libraryType),
      handleSelectForm
    }));
  }, [defaultFormMapping, parentNodeName]);

  const hideNotification = useMemo(() => {
    return !rowData.some((_row) => _row.libraryType === 'CDISC ODM');
  }, [rowData]);

  return (
    <>
      <Box flex flexDirection={'column'} style={{ width: '100%' }}>
        <Table
          classes={classes}
          title={
            <Box
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              alignItems={'center'}
              width={'100%'}
              height={'2rem'}>
              <span>Default Form by Source</span>
              <SaveButton
                hidden={parentNodeName === VISIT_SETTING}
                onSaveDefaultForms={() => showConfirmAlert(true)}
                edcDefaultForms={edcDefaultForms}
                cdrDefaultForms={cdrDefaultForms}
                parentNodeName={parentNodeName}
              />
            </Box>
          }
          subtitle={
            <Notification
              hide={hideNotification}
              variant={'warning'}
              message={
                parentNodeName === VISIT_SETTING
                  ? 'To access visit variables you must select a source form'
                  : 'To access subject variables you must select a default form for CDISC-ODM Source'
              }
            />
          }
          columns={getModifiedColumns(parentNodeName)}
          rows={rowData}
          rowId="value"
          rowsPerPageOptions={[5, 10, 15, 'All']}
          tablePaginationProps={{
            labelDisplayedRows: DisplayedRowsLabel,
            truncate: true
          }}
          maxHeight={650}
          fullWidth
          hasScroll
          showFilters={false}
          CustomHeader={() => <></>}
        />
      </Box>

      <CustomModal
        display={confirmAlertEnabled}
        title={
          <Box display={'flex'} flexDirection={'row'}>
            <StatusExclamationIcon />
            <span style={{ marginLeft: '0.5rem' }}>{'Save source form selection'}</span>
          </Box>
        }
        message={
          'Are you sure you want to update the source form selection? All expressions and configuration associated with the unselected source forms will be lost.'
        }
        variant={'warning'}
        buttonPrimaryLabel={'Save'}
        handlePrimaryAction={onSaveDefaultForms}
        buttonSecondardyLabel={'Cancel'}
        handleClose={() => showConfirmAlert(false)}
      />
    </>
  );
};

export default DefaultFormSettings;
