/*eslint-disable*/
import Box from 'apollo-react/components/Box';
import Grid from 'apollo-react/components/Grid';
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from 'apollo-react/components/Button';
import Tooltip from 'apollo-react/components/Tooltip';
import Typography from 'apollo-react/components/Typography';
import Modal from 'apollo-react/components/Modal';
import Tab from 'apollo-react/components/Tab';
import Tabs from 'apollo-react/components/Tabs';
import FolderPath from './FolderPath';
import OtherSetting from './OtherSetting';
import { MessageConstants } from 'Utils/MessageConstants';
import { useSelector } from 'react-redux';
import useGlobalMessage from 'Utils/useGlobalMessage';
import { KeyConstants } from 'Utils/KeyConstants';
import useGlobalKeys from 'Utils/useGlobalKeys';

const useStyles = makeStyles({
  columnsHeader: {
    '& > div': {
      height: '44px',
      border: '1px solid #E9E9E9',
      display: 'flex',
      alignItems: 'center'
    },
    position: 'sticky',
    top: '0px',
    backgroundColor: '#F6F7FB',
    zIndex: 1
  },
  columnName: {
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  },
  row: {
    cursor: 'pointer !important',
    '&:hover': {
      backgroundColor: '#f6f7fb'
    }
  },
  modal: {
    maxWidth: '100%',
    height: '90vh'
  },
  container: {
    maxWidth: '100%',
    '&>div:nth-child(1)': {
      borderBottom: '1px solid #E9E9E9',
      boxShadow: '0px 0px 15px #0002'
    },
    '&>div:nth-child(2)>div:nth-child(1)': {
      borderBottom: '1px solid #E9E9E9'
    },
    '&>div:nth-child(2)': {
      padding: '0px'
    }
  }
});

let specialCharMessageError = 'Special characters are not allowed.';
let duplicateFolderNameMessageError = 'Folder name already exist.';
let folderNameLengthExceedMessageError = 'Maximum 50 characters are allowed.';
let emptyFolderNameError = 'Folder name should have value.';

const FolderManager = (props) => {
  const classes = useStyles();
  const {
    configureFolderModal,
    setConfigureFolderModal,
    handleSaveExportPath,
    handleCancel,
    configureFolderStructures,
    setConfigureFolderStructures,
    getFolderStructure
  } = props;

  const { getMessage } = useGlobalMessage();
  const getGlobalKeys = useGlobalKeys();

  const [currentFolder, setCurrentFolder] = useState(configureFolderStructures[0]?.subFolders);
  const [selectedPath, setSelectedPath] = useState([configureFolderStructures[0]?.name]);
  const [newFolderName, setNewFolderName] = useState('');
  const [isCreatingFolder, setIsCreatingFolder] = useState(false);
  const [isValid, setIsValid] = useState({
    isSpecialCharExist: false,
    isEmptyFolderName: false,
    isFolderNameLengthExceeds: false,
    isFolderNameExist: false
  });
  const [selectedFolderLocation, setSelectedFolderLocation] = useState({});
  const [isRowSelected, setIsRowSelected] = useState(false);
  const [sortConfig, setSortConfig] = useState({
    key: 'name',
    direction: 'ascending'
  });
  const [isLoadingLazy, setLoadingLazy] = useState(false);
  const [folderConfiguration, setFolderConfiguration] = useState('folderPath');

  // Start of Other Setting
  const { protocol } = useSelector((state) => state.StudyLibraryData);
  const { mappingSpecDetails } = useSelector((state) => state.PublishMappingSpecData);
  const [automatedFolderSetupUrl, setAutomatedFolderSetupUrl] = useState();
  const [otherSettingsFD, setOtherSettingsFD] = useState({
    exportPackageFormat:
      (getGlobalKeys(KeyConstants.EXPORT_PACKAGE_FORMAT.TOPIC_NAME)?.length > 0 &&
        getGlobalKeys(KeyConstants.EXPORT_PACKAGE_FORMAT.TOPIC_NAME)[0]) ||
      '',
    exportFileFormat:
      (getGlobalKeys(KeyConstants.EXPORT_File_FORMAT.TOPIC_NAME)?.length > 0 &&
        getGlobalKeys(KeyConstants.EXPORT_File_FORMAT.TOPIC_NAME)[0]) ||
      '',
    folderName:
      (getGlobalKeys(KeyConstants.EXPORT_PATH_FOLDER_NAME.TOPIC_NAME)?.length > 0 &&
        getGlobalKeys(KeyConstants.EXPORT_PATH_FOLDER_NAME.TOPIC_NAME)[0]) ||
      '',
    folderNameValue:
      (getGlobalKeys(KeyConstants.EXPORT_PATH_FOLDER_NAME.TOPIC_NAME)?.length > 0 &&
        getGlobalKeys(KeyConstants.EXPORT_PATH_FOLDER_NAME.TOPIC_NAME)[0]) ||
      '',
    subFolder:
      (getGlobalKeys(KeyConstants.EXPORT_PATH_SUB_FOLDER.TOPIC_NAME)?.length > 0 &&
        getGlobalKeys(KeyConstants.EXPORT_PATH_SUB_FOLDER.TOPIC_NAME)[0]) ||
      '',
    subFolderValue:
      (getGlobalKeys(KeyConstants.EXPORT_PATH_SUB_FOLDER.TOPIC_NAME)?.length > 0 &&
        getGlobalKeys(KeyConstants.EXPORT_PATH_SUB_FOLDER.TOPIC_NAME)[0]) ||
      '',
    recordCountFile: false
  });

  const updateAutematedPath = (folderKey = '', subFolderKey = '') => {
    let pathSegements = [];
    let folderNameValue = getMessage(KeyConstants.EXPORT_PATH_FOLDER_NAME.VALUES[folderKey]?.LABEL);
    if (folderKey && folderKey !== KeyConstants.EXPORT_PATH_FOLDER_NAME.VALUES.NONE.KEY) {
      let updatedFolderPath = folderNameValue;
      if (
        folderKey ===
        KeyConstants.EXPORT_PATH_FOLDER_NAME.VALUES.PROTOCOL_MNEMONIC_PRODUCT_MNEMONIC.KEY
      ) {
        updatedFolderPath = `${protocol.protocolNumber}/${mappingSpecDetails.productMnemonic}`;
        folderNameValue = updatedFolderPath;
      }
      pathSegements.push(updatedFolderPath);
    }

    const subFolderValue = getMessage(
      KeyConstants.EXPORT_PATH_SUB_FOLDER.VALUES[subFolderKey]?.LABEL
    );
    if (subFolderKey && subFolderKey !== KeyConstants.EXPORT_PATH_SUB_FOLDER.VALUES.NONE.KEY) {
      pathSegements.push(`<${subFolderValue}>`);
    }
    return {
      url: pathSegements.length > 0 ? pathSegements.join('/') : '',
      folderNameValue,
      subFolderValue
    };
  };

  const onChangeOtherSettingHandler = (event) => {
    const { name, value } = event.target;

    if (name === 'folderName') {
      const { url, folderNameValue } = updateAutematedPath(value, otherSettingsFD.subFolder);
      setAutomatedFolderSetupUrl(url);
      setOtherSettingsFD((prevState) => {
        return {
          ...prevState,
          folderName: value,
          folderNameValue
        };
      });
    } else if (name === 'subFolder') {
      const { url, subFolderValue } = updateAutematedPath(otherSettingsFD.folderName, value);
      setAutomatedFolderSetupUrl(url);
      setOtherSettingsFD((prevState) => {
        return {
          ...prevState,
          subFolder: value,
          subFolderValue
        };
      });
    } else {
      setOtherSettingsFD((prevState) => {
        return {
          ...prevState,
          [name]: value
        };
      });
    }
  };
  // End of Other Setting

  const toggleSortOrder = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  const handleSort = () => {
    let sortedFolders = [...currentFolder];
    if (sortConfig.key === 'name') {
      sortedFolders?.sort((a, b) => {
        return sortConfig.direction === 'ascending'
          ? a.name.localeCompare(b.name)
          : b.name.localeCompare(a.name);
      });
    } else if (sortConfig.key === 'LastUpdated') {
      sortedFolders?.sort((a, b) => {
        const dateA = new Date(a.lastModifiedUTC);
        const dateB = new Date(b.lastModifiedUTC);
        return sortConfig.direction === 'ascending' ? dateA - dateB : dateB - dateA;
      });
    }
    setCurrentFolder(sortedFolders);
  };
  useEffect(() => {
    handleSort();
  }, [sortConfig, currentFolder?.length]);

  const updateSubfoldersByPath = (targetPath, subFolders) => {
    setConfigureFolderStructures((_folderStructure) => {
      const modifiedFolderStructure = JSON.parse(JSON.stringify(_folderStructure));
      let current = modifiedFolderStructure[0];
      for (let i = 1; i < targetPath.length; i++) {
        const folderName = targetPath[i];
        const foundFolder = current.subFolders?.find((folder) => folder.name === folderName);
        if (foundFolder) {
          current = foundFolder;
        } else {
          break;
        }
      }
      current.subFolders = subFolders;
      return modifiedFolderStructure;
    });
  };

  const handleRowClick = async (event, folder) => {
    event.stopPropagation();
    if (folder && folder.name) {
      setCurrentFolder(folder.subFolders);
      isRowSelected
        ? setSelectedPath([...selectedPath.slice(0, selectedPath.length - 1), folder.name])
        : setSelectedPath([...selectedPath, folder.name]);
      setIsRowSelected(false);
      let remoteFolder;
      if (!folder.subFolders?.length && folder.hasSubFolders && getFolderStructure) {
        setLoadingLazy(true);
        const targetPath = isRowSelected
          ? [...selectedPath.slice(0, selectedPath.length - 1), folder.name]
          : [...selectedPath, folder.name];
        remoteFolder = await getFolderStructure(targetPath?.join('/'));
        updateSubfoldersByPath(targetPath, remoteFolder?.[0]?.subFolders);
        setCurrentFolder(remoteFolder?.[0]?.subFolders);
        setLoadingLazy(false);
      }
      if (folder?.subFolders?.length === 0 && !remoteFolder) {
        setIsRowSelected(true);
      } else setIsRowSelected(false);
    }

    setIsCreatingFolder(false);
    setIsValid({
      isSpecialCharExist: false,
      isEmptyFolderName: false,
      isFolderNameLengthExceeds: false,
      isFolderNameExist: false
    });
  };

  const handleToggleChange = (e, checked) => {
    setOtherSettingsFD((prev) => {
      return {
        ...prev,
        recordCountFile: checked
      };
    });
  };

  const handleBack = () => {
    if (selectedPath.length > 1) {
      const newPath =
        isRowSelected && currentFolder.length > 0
          ? selectedPath.slice(0, selectedPath.length - 2)
          : selectedPath.slice(0, selectedPath.length - 1);

      const parentFolder = findFolderByPath(newPath);
      setCurrentFolder(parentFolder.subFolders);
      setSelectedPath(newPath);
    }
    setIsRowSelected(false);
    setSelectedFolderLocation({});
    setSortConfig({ key: '', direction: '' });
    setIsCreatingFolder(false);
    setIsValid({
      isSpecialCharExist: false,
      isEmptyFolderName: false,
      isFolderNameLengthExceeds: false,
      isFolderNameExist: false
    });
  };

  const findFolderByPath = (targetPath) => {
    let current = configureFolderStructures[0];
    for (let i = 1; i < targetPath.length; i++) {
      const folderName = targetPath[i];
      const foundFolder = current.subFolders.find((folder) => folder.name === folderName);
      if (foundFolder) {
        current = foundFolder;
      } else {
        break;
      }
    }
    return current;
  };

  const isNewFolderInPath = (selectedPath) => {
    const targetPath = selectedPath.slice();
    let current = configureFolderStructures[0];
    for (let i = 1; i < targetPath.length; i++) {
      const folderName = targetPath[i];
      const foundFolder = current.subFolders.find((folder) => folder.name === folderName);
      if (foundFolder) {
        current = foundFolder;
      } else {
        return {
          hasNewFolder: true,
          existingFolderPath: targetPath.slice(0, i),
          newFolderPath: targetPath.slice(i)
        };
      }
    }
    return false;
  };

  const handleSave = () => {
    const isNewFolderCreated = isNewFolderInPath(selectedPath);
    handleSaveExportPath(selectedPath, isNewFolderCreated, otherSettingsFD);
    setConfigureFolderStructures([]);
    setIsValid({
      isSpecialCharExist: false,
      isEmptyFolderName: false,
      isFolderNameLengthExceeds: false,
      isFolderNameExist: false
    });
  };

  const handleClose = () => {
    setConfigureFolderModal(false);
    setConfigureFolderStructures([]);
    setIsValid({
      isSpecialCharExist: false,
      isEmptyFolderName: false,
      isFolderNameLengthExceeds: false,
      isFolderNameExist: false
    });
  };

  const handleKeyPress = () => {
    const regex = /[~`'+=;/!@#$%^&*(),-.?":{}|<>[\]\\]/;
    const checkSpecialChar = regex.test(newFolderName);
    const isFolderNamePresent = currentFolder?.some((folder) => folder.name === newFolderName);
    if (checkSpecialChar) {
      setIsValid({
        isSpecialCharExist: true,
        isEmptyFolderName: false,
        isFolderNameLengthExceeds: false,
        isFolderNameExist: false
      });
    } else if (isFolderNamePresent) {
      setIsValid({
        isSpecialCharExist: false,
        isEmptyFolderName: false,
        isFolderNameLengthExceeds: false,
        isFolderNameExist: true
      });
    } else if (newFolderName === '' || newFolderName === undefined) {
      setIsValid({
        isSpecialCharExist: false,
        isEmptyFolderName: true,
        isFolderNameLengthExceeds: false,
        isFolderNameExist: false
      });
    } else if (newFolderName.length > 50) {
      setIsValid({
        isSpecialCharExist: false,
        isEmptyFolderName: false,
        isFolderNameLengthExceeds: true,
        isFolderNameExist: false
      });
    } else {
      const newFolder = {
        name: newFolderName,
        lastModifiedUTC: new Date().toISOString(),
        subFolders: []
      };
      setCurrentFolder([...currentFolder, newFolder]);
      setNewFolderName('');
      setIsCreatingFolder(false);
      setIsValid({
        isSpecialCharExist: false,
        isEmptyFolderName: false,
        isFolderNameLengthExceeds: false,
        isFolderNameExist: false
      });
      setIsRowSelected(true);
      setSelectedPath([...selectedPath, newFolderName]);
      setSelectedFolderLocation(newFolder);
    }
  };

  const handleNewFolderClose = () => {
    setIsCreatingFolder(false);
    setIsValid({
      isSpecialCharExist: false,
      isEmptyFolderName: false,
      isFolderNameLengthExceeds: false,
      isFolderNameExist: false
    });
  };

  const handleNewFolderNameChange = (event) => {
    setNewFolderName(event.target.value);
  };

  const handleCreateFolder = () => {
    setNewFolderName('');
    setIsCreatingFolder(true);
  };

  const handleRowSelect = (folder) => {
    isRowSelected
      ? setSelectedPath([...selectedPath.slice(0, selectedPath.length - 1), folder.name])
      : setSelectedPath([...selectedPath, folder.name]);
    setSelectedFolderLocation(folder);
    setIsRowSelected(true);
  };

  const changeFolderConfHandler = (_event, value) => {
    setFolderConfiguration(value);
  };

  return (
    <Modal
      open={configureFolderModal}
      onClose={() => handleClose()}
      title={'Configure Folder'}
      subtitle={
        'Set up automated data exports to maintain timely and accurate information effortlessly.'
      }
      hideButtons
      id="customButtons"
      width={classes.modal}
      className={classes.container}>
      <div style={{ height: '100%', width: '1050px' }}>
        <Box style={{ width: '100%', padding: '10px 20px 0 20px' }}>
          <Grid xs={12}>
            <Tabs value={folderConfiguration} onChange={changeFolderConfHandler} truncate>
              <Tab value="folderPath" label={getMessage(MessageConstants.FOLDER_PATH)} />
              <Tab value="otherSettings" label={getMessage(MessageConstants.OTHER_SETTINGS)} />
            </Tabs>
            {folderConfiguration === 'folderPath' && (
              <Typography variant="body2">
                <FolderPath
                  classes={classes}
                  handleRowSelect={handleRowSelect}
                  isCreatingFolder={isCreatingFolder}
                  handleCreateFolder={handleCreateFolder}
                  selectedPath={selectedPath}
                  isRowSelected={isRowSelected}
                  currentFolder={currentFolder}
                  handleBack={handleBack}
                  toggleSortOrder={toggleSortOrder}
                  sortConfig={sortConfig}
                  newFolderName={newFolderName}
                  handleNewFolderNameChange={handleNewFolderNameChange}
                  handleKeyPress={handleKeyPress}
                  isValid={isValid}
                  duplicateFolderNameMessageError={duplicateFolderNameMessageError}
                  specialCharMessageError={specialCharMessageError}
                  folderNameLengthExceedMessageError={folderNameLengthExceedMessageError}
                  emptyFolderNameError={emptyFolderNameError}
                  handleNewFolderClose={handleNewFolderClose}
                  isLoadingLazy={isLoadingLazy}
                  handleRowClick={handleRowClick}
                  selectedFolderLocation={selectedFolderLocation}
                />
              </Typography>
            )}
            {folderConfiguration === 'otherSettings' && (
              <Typography variant="body2">
                <OtherSetting
                  otherSettingsFD={otherSettingsFD}
                  selectedPath={selectedPath}
                  automatedFolderSetupUrl={automatedFolderSetupUrl}
                  onChangeOtherSettingHandler={onChangeOtherSettingHandler}
                  isMetadataFile={otherSettingsFD.recordCountFile}
                  handleToggleChange={handleToggleChange}
                />
              </Typography>
            )}
          </Grid>
        </Box>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '15px 20px',
          padding: '0px'
        }}>
        <Button
          className={classes.customButton}
          onClick={handleCancel}
          style={{ margin: '0px 10px' }}
          variant="secondary">
          Cancel
        </Button>
        <Tooltip title={isCreatingFolder && 'Create folder in progress'} placement="top">
          <span style={{ display: 'inline-block' }}>
            <Button variant="primary" disabled={isCreatingFolder} onClick={handleSave}>
              Save
            </Button>
          </span>
        </Tooltip>
      </div>
    </Modal>
  );
};

export default FolderManager;
