import Cookies from 'js-cookie';

export const getHeadersFromCookies = () => {
  const headers = {
    'X-sys-name': 'CDM'
  };

  if (Cookies.get('tenant')) {
    headers['X-tenant'] = Cookies.get('tenant');
  }

  if (Cookies.get('access-token')) {
    headers['X-access-token'] = Cookies.get('access-token');
  }

  if (Cookies.get('api-key')) {
    headers['X-api-key'] = Cookies.get('api-key');
  }

  if (Cookies.get('user.server_url')) {
    headers['X-server_app_url'] = Cookies.get('user.server_url');
  }

  if (Cookies.get('user.id')) {
    headers['X-user-id'] = Cookies.get('user.id');
  }

  return headers;
};

export const getHeadersForAIAAS = () => {
  const headers = {
    'Content-Type': 'application/json',
    'api-key': process.env.REACT_APP_AIAAS_API_KEY,
    'sys-name': 'AIAAS',
    'token-type': 'SCHEDULER'
  };
  return headers;
};

export const getHeadersForCore = () => {
  const headers = {
    tenant: Cookies.get('tenant'),
    'access-token': Cookies.get('access-token'),
    'token-type': 'user',
    'sys-name': 'CDM',
    'api-key': Cookies.get('api-key'),
    Authorization: Cookies.get('user.token')
  };
  return headers;
};
